// ** React Imports
import { useState, Fragment, useEffect } from 'react'
import toast from 'react-hot-toast'
// ** Reactstrap Imports
import {
  Row,
  Col,
  Input,
  Dropdown,
  Card,
  CardBody,
  Button,
  Label,
  InputGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
  Form
} from 'reactstrap'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { File, Trash2, ChevronDown, Calendar, ArrowLeft } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
//custom imports
import { instance, getImageCloudFlare, handleApiError, formatDateTimeFigma } from '../../utility/Utils'
import { ReactSelectStyle } from '../../utility/ReactSelectStyle/SelectStyle'

const EditAds = () => {
  // ** State
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const [uploading, setUploading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [updating, setUpdating] = useState(false)
  const [cafeOption, setCafeOptions] = useState([])
  const animatedComponents = makeAnimated()

  const uploadImage = async (s) => {
    const newInstance = instance()
    try {
      setUploading(true)
      const j = await newInstance.post('/api/user/admin/uploadImage', { file: s }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      setData({ ...data, banner_image_id: j.data.id })
      toast.success('uploaded')
    } catch (err) {
      handleApiError(err)
    } finally {
      setUploading(false)
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles[0] && (acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/png")) {
        uploadImage(acceptedFiles[0])
      } else {
        toast.error('please select jpg or png format only')
      }
    }
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const categoryOption = [
    { label: 'App Promotion', value: 'App Promotion' },
    { label: 'Restaurant Food Promotion', value: 'Restaurant Food Promotion' },
    { label: 'Restaurant Event Promotion', value: 'Restaurant Event Promotion' },
    { label: 'App Offers', value: 'App Offers' },
    { label: 'Test', value: 'Test' }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newInstance = instance()
    try {
      setUpdating(true)
      await newInstance.put(`/api/user/admin/edit-bannerads/${id}`, {
        cafe_list_id: data.cafe_list_id,
        start_date: new Date(startDate).toISOString().replace('T', ' ').slice(0, 19),
        end_date: new Date(endDate).toISOString().replace('T', ' ').slice(0, 19),
        banner_link: data.banner_link,
        position: data.position,
        title: data.title,
        category: data.category,
        banner_image_id: data.banner_image_id,
        status: data.status
      })
      toast.success("Ads updated")
    } catch (err) {
      handleApiError(err)
    } finally {
      setUpdating(false)
    }
  }

  useEffect(() => {
    const loadCafe = async () => {
      const newInstance = instance()
      try {
        const k = await newInstance.get(`/api/user/admin/get-bannerads-Byid/${id}`)
        setData(k.data.data[0])
        setStartDate(new Date(k.data.data[0].start_date))
        setEndDate(new Date(k.data.data[0].end_date))
        const j = await newInstance.get("api/user/admin/cafe-list/get/all")
        const sortedData = j.data.data
        sortedData.sort((a, b) => {
          const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
          const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        setCafeOptions(sortedData.map((e) => {
          return {
            label: e.cafe_name,
            value: e.id
          }
        }))
      } catch (error) {
        handleApiError(error)
      }
    }
    loadCafe()
  }, [])
  if (formatDateTimeFigma) {

  }

  return (
    <Form onSubmit={handleSubmit} >
      <Row className='mb-5'>

        <h1 className='mb-2 datatable-title-head'>  <ArrowLeft className='icon-hover' onClick={() => navigate('/list-banner')} />   Edit Ads </h1>
        <Col md='10'>
          <Row>
            <Col md='8'>
              <Card style={{ background: '#E7ECF0', border: 'none', boxShadow: 'none', aspectRatio: ' 16 / 9 ' }}>
                <CardBody >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div style={{ height: '257px', margin: 'auto 0' }} className='d-flex align-items-center justify-content-center flex-column'>
                      <File size={64} />
                      <h2 className='text-secondary mt-2'>
                        Upload Photo
                      </h2>
                      <p className='text-secondary '>
                        Image Ratio - 16:9
                      </p>
                      {uploading && <Spinner />}
                    </div>
                  </div>

                </CardBody>
              </Card>
              {data.banner_image_id ? (
                <Fragment>
                  <div className='file-preview me-1 rounded' style={{ height: '100px', textAlign: 'center', position: 'relative', aspectRatio: ' 16 / 9 ', background: 'gray' }}>
                    <img className='rounded' alt={data} src={getImageCloudFlare(data.banner_image_id, process.env.REACT_APP_CLOUD_IMAGE_Medium_Extention)}
                      style={{ height: '100%' }} />
                    <Button color='white' outline style={{ position: 'absolute', top: '0', right: '0' }} size='sm' className='btn-icon' onClick={() => setData({ ...data, banner_image_id: '' })}>
                      <Trash2 color='white' size='14' />
                    </Button>
                  </div>
                </Fragment>
              ) : null}
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md='6' sm='12' className='mb-1'>
              <Label className='form-label' for='title'>
              Banner UID
              </Label>
              <Input readOnly type='name' name='title' value={data.uid || ''} id='title' onChange={handleChange} placeholder='Diwali offer' />
            </Col>
            <Col md='6' sm='12' className='mb-1'>
              <Label className='form-label' for='title'>
              Banner Title <span style={{ color: 'red' }}>*</span>
              </Label>
              <Input required type='name' name='title' value={data.title || ''} id='title' onChange={handleChange} placeholder='Diwali offer' />
            </Col>
          </Row>
          <Row>
            <Col md='6' sm='12' className='mb-1'>
              <Label className='form-label' for='banner_link'>
              Banner Link (Optional)
              </Label>
              <Input type='url' name='banner_link' id='banner_link' value={data.banner_link || ''} onChange={handleChange} placeholder='https://linkhere' />
            </Col>

            <Col md='6' sm='12' className='mb-1'>
              <Label className='form-label'>Link with Cafe(Optional)</Label>
              <Select
              styles={ReactSelectStyle}
                closeMenuOnSelect
                // placeholder={(cafeOption.find((e) => e.value ===  data.cafe_list_id)).label}
                value={cafeOption[cafeOption.indexOf(cafeOption.find((e) => e.value === data.cafe_list_id))]}
                components={animatedComponents}
                options={cafeOption}
                onChange={(e) => setData({ ...data, cafe_list_id: e.value })}
                className='react-select'
              />
            </Col>
            <Col md='6'  sm='12' className='mb-1'>
              <Label className='form-label' for='date-time-picker'>
                Start Date <span style={{ color: 'red' }}>*</span>
              </Label>
              <InputGroup className='input-group-merge'>
                <Flatpickr
                  data-enable-time
                  id='date-time-picker'
                  // defaultDate={formatDateTimeFigma(startDate)}
                  value={startDate}
                  options={{
                    enableTime: true,
                    time_24hr: false,
                    time_12hr: true,
                    minDate: new Date(),
                    dateFormat: `d/m/y h:i K`
                  }}
                  className='form-control'
                  placeholder='Select '
                  onChange={date => {

                    setStartDate(date)

                  }}
                />
                <InputGroupText>
                  <Calendar size={14} />
                </InputGroupText>
              </InputGroup>
            </Col>
            <Col md='6'>
              <Label className='form-label' for='date-time-picker'>
                End Date <span style={{ color: 'red' }}>*</span>
              </Label>
              <InputGroup className='input-group-merge'>
                <Flatpickr
                  data-enable-time
                  id='date-time-picker'
                  // defaultValue={`${formatDateTimeFigma(endDate)}`}
                  value={endDate}
                  options={{
                    enableTime: true,
                    time_24hr: false,
                    time_12hr: true,
                    minDate: new Date(startDate),
                    dateFormat: `d/m/y h:i K`
                  }}
                  className='form-control'
                  placeholder='Select Date'
                  onChange={date => {

                    setEndDate(date)
                  }}
                />
                <InputGroupText>
                  <Calendar size={14} />
                </InputGroupText>
              </InputGroup>
            </Col>
            <Col md='6' sm='12' className='mb-1'>
              <Label className='form-label'>Banner Category <span style={{ color: 'red' }}>*</span></Label>
              <Select
              styles={ReactSelectStyle}
                closeMenuOnSelect
                // defaultValue = {cafeOption.find((e) => e.value ===  1)}
                value={categoryOption[categoryOption.indexOf(categoryOption.find((e) => e.value === data.category))]}
                components={animatedComponents}
                options={categoryOption}
                onChange={(e) => setData({ ...data, category: e.value })}
                className='react-select'
              />
            </Col>
            <Col md='6' sm='12' className='mb-1'>
              <Label className='form-label' for='position'>
              Banner Position
              </Label>
              <Input type='number' name='position' value={data.position || ''} onChange={handleChange} id='position' placeholder='' />
            </Col>
            <Col md='12' className="d-flex p-1 align-items-center">
              <div className='eat-status'>Status</div>
              <div className='form-check form-switch ms-1'>
                <Input type='switch' name='status' id='statu'
                  onChange={(e) => setData({ ...data, status: 0 + e.target.checked })}
                  checked={data.status || 0} />
              </div>
            </Col>

            <Col className='mt-1 mb-2' sm='12'>

              <Button outline className='me-1' onClick={() => navigate('/list-banner')} color='secondary' type='cancel'>
                Cancel
              </Button>
              <Button className='me-1 eat-btn' type='submit' disabled={updating || !startDate || !endDate || !data.category} >
                Save {updating && <Spinner size='sm' />}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default EditAds
