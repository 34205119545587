import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, MoreVertical, Search } from 'react-feather'

// import { handleApiError, instanceV1 } from "../../utility/Utils"

// import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Input
} from 'reactstrap'
import { UnCheck, YCheck } from '../../@core/assets/fonts/feather/icons'
import ExportImportButton from '../../UI/ExportImport'
import SearchInput from '../../UI/SearchInput'
import { CustomPagination, customStyles } from '../../utility/datatable-style/dataTableStyle'
import { ViewQrModal } from '../../UI/Models/ViewQrModal'
import { useDispatch, useSelector } from "react-redux"
import { GetTableList, changeTableSearchString } from '../../redux/pagination/tableManagement'


const QRManagement = () => {
  const navigate = useNavigate()
  const [searchDebounceId, setSearchDebounceId] = useState(null)
  const dispatch = useDispatch()
  const state = useSelector((state) => state.tableManagementList)
  const [modalData, setModalData] = useState({
    modal: '',
    payload: {

    }
  })

  const columns = [
    {
      name: 'SN',
      selector: (row, rowIndex) => ((((state.pageData?.currentPage - 1) * state.pageData.rowsPerPage) + rowIndex) + 1) || '',
      maxWidth: '6.5rem',
      minWidth: '4rem'
    },
    {
      name: 'TABLE TITLE',
      selector: row => row.table_title || '',
      maxWidth: '15rem',
      minWidth: '8rem'
    },
    {
      name: 'TABLE UID',
      selector: (row) => {

        return (
          <div className='d-flex'  >
            <UncontrolledTooltip placement='top' target={`qr-img-${row.id}`} style={{ boxShadow: '0px 4px 4px rgba(181, 181, 181, 0.25)' }} >
              <span style={{ color: '#1A264E' }}>Cafe ID : {row.cafe_list_id}</span>
            </UncontrolledTooltip>
            <div id={`qr-img-${row.id}`} style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{row.table_uid ? row.table_uid : 'Creating...'}
            </div>
          </div>)
      },
      minWidth: '9rem',
      maxWidth: '13rem',
      left: true
    },
    {
      name: 'LINKED QR',
      selector: (row) => {

        return (
          <div className='d-flex' style={{ gap: '3px', display: 'flex', flexWrap: 'wrap' }} >
            {
              row.qr_with_frame.map((e, i) => <div key={i}  >{e.qr_uid}{(i === row.qr_with_frame.length - 1) ? "" : ","}</div>)
            }
          </div>)
      },
      minWidth: '14rem',
      maxWidth: '18rem',
      left: true
    },
    {
      name: 'RESTAURANT NAME',
      selector: (row) => row.cafe_name || '',
      minWidth: '18rem'
    },
    {
      name: 'Total Scan',
      selector: (row) => row.total_scan || 0,
      maxWidth: '10rem',
      minWidth: '8rem'
    },
    {
      name: 'OCCUPIED',
      selector: (row) => {
        if (row.is_occupied === 1) {
          return <span id={`qr-occupied-${row.id}`}>
            <YCheck />
            <UncontrolledTooltip placement='top' target={`qr-occupied-${row.id}`}>
              <span style={{ color: '#1A264E' }}>#{row.order_list_uid}</span>
            </UncontrolledTooltip>
          </span>
        } else {
          return (
            <UnCheck />
          )
        }
      },
      maxWidth: '10rem',
      minWidth: '8rem'
    },
    {
      name: 'Merged',
      selector: (row) => {
        if (row.is_merged === 1) {
          return <span id={`qr-merge-${row.id}`}>
            <YCheck />
            <UncontrolledTooltip placement='top' target={`qr-merge-${row.id}`}>
              <span style={{ color: '#1A264E' }}>not in api yet</span>
            </UncontrolledTooltip>
          </span>
        } else {
          return (
            <UnCheck />
          )
        }
      },
      maxWidth: '10rem',
      minWidth: '8rem'
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.is_active === 1) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Active
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Inactive
          </div>
        }
      },
      maxWidth:'8rem',
      minWidth:'8rem',
      allowOverflow:true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        const [basicModal, setBasicModal] = useState()
        const navigate = useNavigate()
        return (
          <div className='d-flex'>
            <Modal className='modal-dialog-centered ' style={{ width: 'min-conatent' }} isOpen={basicModal} toggle={() => setBasicModal(!basicModal)}>
              <ModalHeader toggle={() => setBasicModal(!basicModal)}></ModalHeader>
              <ModalBody>
                <h2 className='m-1 datatable-title-head' style={{ textAlign: 'center' }} >QR Code</h2>
                <Row style={{ margin: 'auto' }} >

                  <Col style={{ textAlign: 'center' }} md='12'>
                    <img style={{ width: '200px' }} src={row.qr_with_frame} />
                  </Col>
                  <p style={{ textAlign: 'center', margin: "20px 0" }}>{row.table_uid}</p>
                  <Col style={{ textAlign: 'center', paddingBottom: '40px' }}>
                    <Button outline className='me-1' color='secondary' type='cancel' onClick={() => setBasicModal(false)} >
                      Cancel
                    </Button>
                    {!(row.is_default === 1) && <Button className='me-1 eat-btn' onClick={() => navigate(`/restaurant-qr/edit/${row.id}`)}>
                      Edit
                    </Button>}
                  </Col>

                </Row>
              </ModalBody>
            </Modal>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1' tag='span'>
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag='a' onClick={() => setBasicModal(true)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'> View QR</span>
                </DropdownItem>
                {!(row.is_default === 1) && <DropdownItem tag='a' onClick={() => navigate(`/restaurant-qr/edit/${row.id}`)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'> Edit QR</span>
                </DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      },
      maxWidth: '2.5rem',
      minWidth: '100px',
      // fixed: "left",
      // style: {
      //   position: 'sticky',
      //   right: '0',
      //   background: 'white'
      // },
      allowOverflow: true
    }

  ]

  const getQrList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    dispatch(GetTableList({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr
    }))
  }

  useEffect(() => {
    getQrList()
  }, [])

  // ** Table
  return (
    <>
      <ViewQrModal isOpen={modalData?.modal === "viewQr"} payload={modalData?.payload}
        toggle={() => setModalData({
          modal: "",
          payload: {}
        })} />
      <div className='custum-table-fix'  style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
        <DataTable
          fixedHeader={true}

          onRowClicked={(row) => {
            setModalData({
              modal: 'viewQr',
              payload: {
                id: row.id,
                is_default: row.is_default,
                uid: row.table_uid,
                src: row.qr_with_frame
              }
            })
          }
          }
          subHeader={true}
          subHeaderComponent={
            <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
              <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
                <h1 className='datatable-title-head'>Table Management</h1>
              </div>
              {/* <SearchInput onChange={searchHandler} /> */}
              <div className='search-input' style={{ width: 'auto' }}>
                <Input value={state.searchStr} onChange={(e) => {
                  clearTimeout(searchDebounceId)
                  const timer = setTimeout(() => {
                    dispatch(GetTableList({
                      isLoading: true,
                      isRefreshing: true,
                      pageNo: 1,
                      rows: state.pageData.rowsPerPage,
                      str: e.target.value //state.searchStr
                    }))
                  }, 500)
                  setSearchDebounceId(timer)
                  dispatch(changeTableSearchString({ str: e.target.value }))
                }
                } />
                <span className='search-icon'><Search /></span>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px', alignItems: 'center' }} >
                <ExportImportButton isImport={true} data={state.list} fileName='QR_code_list' sampleUrl="/download-excel-qrmanagement" url='/api/admin/import-qrmanagement-files' />
                <Button className='eat-btn' onClick={() => navigate("/restaurant-qr/add")} >Add Table</Button>
              </div>
            </div>
          }

          customStyles={customStyles({
            headCells: {
              justifyContent: "left",
              textAlign: 'left'
            },
            cells: {
              justifyContent: "left",
              textAlign: 'left'
            },
            rows: {
              cursor: "pointer"
            }
          })}

          conditionalRowStyles={
            [
              {
                when: row => row.is_default === 1,
                style: {
                  backgroundColor: '#FFF0EB'
                }
              }
            ]
          }

          pagination
          paginationDefaultPage={1}
          paginationPerPage={10}
          paginationServer
          progressComponent={<Spinner color="primary"
            style={{
              height: '3rem',
              width: '3rem'
            }} />}
          progressPending={state.isLoading}
          paginationComponent={() => <CustomPagination
            pageData={state.pageData}
            numberOfData={state.list.length}
            handlePerPage={(e) => getQrList(parseInt(e.target.value), 1, state.searchStr)}
            handlePagination={(page) => getQrList(state.pageData.rowsPerPage, page.selected + 1, state.searchStr)} />}

          responsive={true}
          columns={columns}     // filter data for column
          data={state.list}       //takes data
          highlightOnHover
        />
      </div>
    </>
  )

}

export default QRManagement
