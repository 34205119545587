import { useState } from 'react'
import toast from 'react-hot-toast'
import { Button, Input, Label, Spinner } from 'reactstrap'
import { UploadFileLogo } from '../@core/assets/fonts/feather/icons'
import { getImageCloudFlare, handleApiError, instance } from '../utility/Utils'


const IconInput = (props) => {
// const id = useId()
const [loading, setloading] = useState(false)
const {data} = props

const uploadImage = async(file) => {
    const newInstance = instance()
    try {
      setloading(true)
      const j = await newInstance.post('/api/user/admin/uploadImage', {file}, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        toast.success('Uploaded')
        props.onChange(j.data.id)
    } catch (err) {
      handleApiError(err)
          console.warn(err)
    } finally {
        setloading(false) 
        }
    
  }


  return (
    <div>
    <div className='d-flex' style={{gap:'5px', alignItems:'center'}}>
                <Button tag={Label} color='none' style={{borderRadius:'50%',  background:'transparent'}}     size='sm' >
                          <div style={{width:'100px', height:'100px', background: '#E7ECF0', borderRadius: '50%', overflow:'hidden', display:'flex', justifyContent: 'center'}}>
                              { loading ? <Spinner style={{margin:'Auto'}} color='primary' /> : data.imgId ? <img style={{width:'100px', height:'100px'}} src={getImageCloudFlare(data.imgId, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention)}/>  :  <div 
                                                                style={{
                                                                        display:'flex', 
                                                                        flexDirection:'column',  
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '100%',
                                                                        gap: '10%'
                                                                      }}><UploadFileLogo/><div>Upload Photo</div></div>}
                                                                </div>
                    <Input type='file' onChange={(e) => { 
                        if (!loading && e.target.files[0]) { 
                            uploadImage(e.target.files[0]) 
                         } 
                        }
                     }
                          hidden accept='image/*' />
                </Button>
                </div>
                </div>
  )
}

export default IconInput