
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
  Input,
  Dropdown,
  Card,
  CardBody,
  Form,
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  Label,
  InputGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from 'reactstrap'

import Select from 'react-select'
import { handleApiError, instanceV1 } from '../../utility/Utils'
import toast from 'react-hot-toast'

const ViewQrModal = ({ isOpen, payload, toggle }) => {

  const navigate = useNavigate()
  const [selectedQr, setSelectedQr] = useState({})

  const handleToggel = () => {
    toggle()
  }


  useEffect(() => {
    setSelectedQr(payload.src ? { label: payload?.src[0]?.qr_uid, value: payload?.src[0]?.qr_with_frame } : {})
  }, [isOpen])

  return <Modal className='modal-dialog-centered ' style={{ width: 'min-conatent' }} isOpen={isOpen} toggle={handleToggel}>
    <ModalHeader toggle={handleToggel}></ModalHeader>
    <ModalBody>
      <h2 className='m-1' style={{ textAlign: 'center' }} >QR Code</h2>
      <Row style={{ margin: 'auto' }} >
        <Col sm="10" md='6' style={{ margin: '0.5rem auto' }}>
          <Select
            // isClearable={true}
            // theme={selectThemeColors}
            closeMenuOnSelect
            value={selectedQr}
            options={payload.src ? payload.src.map((e) => {
              return { label: e.qr_uid, value: e.qr_with_frame }
            }) : []}
            onChange={(e) => {
              setSelectedQr(e)
            }}
            styles={{
              option: (base, {isFocused, isSelected}) => {
                  return {
                    ...base,
                    background: isFocused ? "linear-gradient(166.45deg, #f2473f85 -14.38%, #ff6b34a1 105%)" : isSelected ? "linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)" : "white",
                    color:isFocused || isSelected ? "white"  : "black"
                  }
              },
              multiValue: (base) => {
                  return { ...base, maxWidth: 'max-content', borderRadius: '10px', background:'linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)', "& div":{ color:'white'}}
              },
              multiValueRemove: (base) => {
                  return { ...base, maxWidth: 'max-content', borderRadius: '50%', color:'green ' }
              },
              multiValueContainer: (base) => {
                  return { ...base, maxWidth: 'max-content', color:'white'  }
              },
              valueContainer: (base) => {
                  return {
                      ...base,

                      '& div': {
                          maxWidth: 'fit-content'
                      }
                  }
              }
          }
          }
            className='react-select'
            required={true}
          />
        </Col>
        <Col style={{ textAlign: 'center' }} md='12'>
          <img style={{ width: '200px' }} src={selectedQr.value || ''} />
        </Col>
        <p style={{ textAlign: 'center', fontWeight: '500', fontSize: '0.875', lineHeight: '1.313', margin: "20px 0" }}>Table UID:- {payload.uid || "Creating..."}</p>
        <Col style={{ textAlign: 'center', paddingBottom: '40px' }}>
          <Button outline className='me-1' color='secondary' type='cancel' onClick={handleToggel} >
            Cancel
          </Button>
          {!(payload?.is_default === 1) && payload.id && <Button className='me-1 eat-btn' onClick={() => navigate(`/restaurant-qr/edit/${payload.id}`)}>
            Edit
          </Button>}
        </Col>

      </Row>
    </ModalBody>
  </Modal>
}

const ViewQrModal2 = ({ isOpen, payload, toggle, variantSwitchSucess }) => {

  // const navigate = useNavigate()
  const [variantSwitch, setVariantSwitch] = useState(false)
  const switchVariant = async () => {
    try {
      setVariantSwitch(true)
      const newInstance = instanceV1()
      
      await newInstance.post("/variant", {
        variant: !payload.is_web,
        qr_uid: payload.uid
      })
      toast.success("Variant switched successfully")
      toggle()
      variantSwitchSucess()
    } catch (error) {
      handleApiError(error)
    } finally {
      setVariantSwitch(false)
    }
  }
  const handleToggel = () => {
    toggle()
  }

  return <Modal size='sm' className='modal-dialog-centered ' style={{ width: 'min-conatent' }} isOpen={isOpen} toggle={handleToggel}>
    <ModalHeader toggle={handleToggel}></ModalHeader>
    <ModalBody>
      <h2 className='m-1' style={{ textAlign: 'center' }} >QR Sticker</h2>
      <Row style={{ margin: 'auto' }} >
        <Col md={12} className="d-flex pb-2 align-items-center text-align-center justify-content-center">
          <div style={{ fontWeight: 'bold' }}>Force Web redirect</div>
          <div className='form-check form-switch ms-1'>
            <Input type='switch' name='status' id='status'
              onChange={switchVariant}
              checked={payload.is_web || 0}
              disabled={variantSwitch}
            />
          </div>
        </Col>

        <Col style={{ textAlign: 'center' }} md='12'>
          <img style={{ width: '200px' }} src={payload.src || ''} />
        </Col>
        <p style={{ textAlign: 'center', fontWeight: '500', fontSize: '0.875', lineHeight: '1.313', margin: "20px 0" }}>Table UID:- {payload.uid || "Creating..."}</p>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <table >
            <tbody>
              <tr>
                <td className='links-title' >Firebase Shortlink </td>
                <td>
                  <span className='links' > {payload?.is_web ? payload?.firebase_shortlink_web : payload?.firebase_shortlink_app}</span>
                </td>
              </tr>
              <tr>
                <td className='links-title'>QR shortlink </td>
                <td className='links' > {payload?.qr_shortlink}</td>
              </tr>
              <tr>
                <td className='links-title'>Long URL </td>
                <td className='links' > {payload?.qr_long_url}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Row>
    </ModalBody>
  </Modal>
}

export {
  ViewQrModal,
  ViewQrModal2
}