import { useState, useEffect, forwardRef } from 'react'
import DataTable from 'react-data-table-component'

import { ArrowDownCircle, ChevronDown, Circle, MoreVertical, Search } from 'react-feather'

import { formatDateTimeFigma, handleApiError, instanceV1 } from "../../utility/Utils"

import {
  Row,
  Col,
  Input,
  Dropdown,
  Card,
  CardBody,
  Form,
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  Label,
  InputGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from 'reactstrap'
import { Featured, MostVisited, NewOpen, Refresh, UnCheck, YCheck } from '../../@core/assets/fonts/feather/icons'
import ExportImportButton from '../../UI/ExportImport'
// import SearchInput from '../../UI/SearchInput'
import toast from 'react-hot-toast'
import AssignQrModal from '../../UI/Models/AssignQrModal'
import { CustomPagination, customStyles } from '../../utility/datatable-style/dataTableStyle'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
// import axios from 'axios'
import { ViewQrModal2 } from '../../UI/Models/ViewQrModal'
import { GetGeneratedQR, changeSearchString, clearAssignQrListData } from '../../redux/pagination/genarateQr'
import { useDispatch, useSelector } from "react-redux"


const QRCodes = () => {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.generateQr)
  const [genrateQrModal, setgenrateQrModal] = useState(false)
  const [timeOutId, setTimeOutId] = useState(null)
  const [assignQrModal, setAssignQrModal] = useState(false)
  const [assignQrbut, setAssignQrBut] = useState(false)
  const [selectedQrIds, setSelectedQrIds] = useState([])
  const [qrToDownload, seQrToDownload] = useState([])
  const [generatingQR, setGeneratingQR] = useState(false)
  const [modalData, setModalData] = useState({
    modal: '',
    payload: {

    }
  })

  const columns = [
    {
      name: 'S. No.',
      selector: (row, rowIndex) => ((((state.pageData?.currentPage - 1) * state.pageData.rowsPerPage) + rowIndex) + 1),
      maxWidth: '8.5rem',
      minWidth: '5rem',
      left: 'true'
    },
    {
      name: 'UID',
      selector: row => {

        return (
          <div className='d-flex'  >
            {row.assigned_cafe_list_id && <UncontrolledTooltip placement='top' target={`qr-img-${row.id}`} style={{ boxShadow: '0px 4px 4px rgba(181, 181, 181, 0.25)' }} >
              <span style={{ color: '#1A264E' }}>Cafe ID : {row.assigned_cafe_list_id}</span>
            </UncontrolledTooltip>}
            <div id={`qr-img-${row.id}`} style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{row.qr_uid ? row.qr_uid : 'Creating...'}
            </div>
          </div>)
      },
      minWidth: '12rem',
      maxWidth: '15rem'
    },
    {
      name: 'ASSINGED',
      selector: (row) => {
        if (row.assigned_cafe_list_id) {
          return <span id={`qr-merge-${row.id}`}>
            <YCheck />
            <UncontrolledTooltip placement='top' target={`qr-merge-${row.id}`}>
              <span style={{ color: '#1A264E' }}>{row.cafe_name} </span>
            </UncontrolledTooltip>
          </span>
        } else {
          return (
            <UnCheck />
          )
        }
      },
      maxWidth: '10rem',
      minWidth: '8rem'
    },
    {
      name: 'QR URL/Shortlink',
      selector: (row) => <div>
        <div>{row.qr_short_url}</div>
        <div>{row.qr_long_url}</div>
      </div>,
      minWidth: '19rem',
      maxWidth: '100rem'
    },
    {
      name: 'Total Scan',
      selector: (row) => row.total_scans || 0,
      maxWidth: '10rem',
      minWidth: '8rem'
    },
    {
      name: 'Created at',
      selector: (row) => formatDateTimeFigma(row.created_at),
      minWidth: '13rem',
      maxWidth: '15rem'
    },
    // {
    //   name: 'Download',
    //   selector: (row) => <div><a href={row.qr_with_frame}  download={`qr-frame-${row.qr_uid}.png`} > <ArrowDownCircle color='green' /> </a></div>,
    //     minWidth:'8rem',
    //     maxWidth:'10rem'
    // },
    {
      name: 'Download',
      selector: (row) => <div className='icon-btn' >
        <a
          // href={"https://eats-docs-stag.s3.eu-central-1.amazonaws.com/qr_wf/10000307"}
          href={row.qr_with_frame}
          download={`qr-frame-${row.qr_uid}.png`} > <ArrowDownCircle color={row.qr_with_frame ? '#01A879' : "#FF3030"} />
        </a>
      </div>,
      minWidth: '8rem',
      maxWidth: '10rem',
      center: true
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.is_active === 1) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Active
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Inactive
          </div>
        }
      },
      maxWidth: '2.5rem',
      minWidth: '100px',
      // right: true,
      allowOverflow:true
    }

  ]


  const downloadZipFile = async () => {

    const imageUrls = ["https://eats-docs-stag.s3.eu-central-1.amazonaws.com/qr_wf/10000307"]
    console.log(qrToDownload)
    // saveAs(imageUrls[0], "image.png")
    const zip = new JSZip()
    const folder = zip.folder("QRs")

    for (let i = 0; i < imageUrls.length; i++) {
      const response = await fetch(imageUrls[i], {
        method: 'HEAD',
        mode: "no-cors"
      })
      console.log(response)
      const blob = await response.blob()
      const filename = `image${i}.png`
      folder.file(filename, blob)
    }

    const content = await zip.generateAsync({ type: "blob" })
    saveAs(content, "images.zip")
  }

  const getQrList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    clearTimeout(timeOutId)
    dispatch(GetGeneratedQR({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr
    }))
  }
  const refreshQrList = async () => {
    clearTimeout(timeOutId)
    dispatch(GetGeneratedQR({
      isLoading: false,
      isRefreshing: true,
      pageNo: state.pageData.currentPage,
      rows: state.pageData.rowsPerPage,
      str: state.searchStr
    }))
  }


  const genrateQR = async (e) => {
    e.preventDefault()
    try {
      setGeneratingQR(true)
      const instance = instanceV1()
      await instance.post("/qr-from-lamda", {
        amount: parseInt(e.target[0].value)
      })
      setgenrateQrModal(false)
      toast.success("QR Generated Sucessfully")
      getQrList()
    } catch (error) {
      handleApiError(error)
    } finally {
      setGeneratingQR(false)
    }
  }

  const Checkbox = forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <input
          type="checkbox"
          className="form-check-input qr-check"
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </>
    )
  }
  )


  useEffect(() => {
    getQrList()
    return clearTimeout(timeOutId)
  }, [])


  return (
    <>
      <ViewQrModal2 isOpen={modalData?.modal === "viewQr"} variantSwitchSucess={() => {
        clearTimeout(timeOutId)
        const timer = setTimeout(refreshQrList, 5000)
        setTimeOutId(timer)
      }} payload={modalData?.payload} toggle={() => setModalData({
        modal: "",
        payload: {}
      })} />
      <Modal className='modal-dialog-centered ' size='sm' isOpen={genrateQrModal} toggle={() => setgenrateQrModal(!genrateQrModal)}>
        <ModalHeader toggle={() => setgenrateQrModal(!genrateQrModal)}></ModalHeader>
        <ModalBody>
          <h2 className='m-1' style={{ textAlign: 'center' }} >Generate QR</h2>
          <Form onSubmit={genrateQR}>
            <Row style={{ margin: 'auto', alignItems: 'center', justifyContent: 'center' }} >

              <Row className='mb-3'>
                <Label style={{ paddingLeft: '0' }} for='amount'>How many QR code you want to generate? <span style={{ color: 'red' }} >*</span></Label>
                <Input name='amount' type='number' min={1} placeholder='Enter' required={true} autoFocus={true} />
              </Row>
              <Row>
                <Col type='button' style={{ textAlign: 'center', paddingBottom: '40px' }}>
                  <Button outline className='me-1' color='secondary' type='cancel' onClick={() => setgenrateQrModal(false)} >
                    Cancel
                  </Button>
                  <Button className='me-1 eat-btn' type='submit'>
                    Generate {generatingQR && <Spinner size='sm' />}
                  </Button>
                </Col>
              </Row>

            </Row>
          </Form>
        </ModalBody>
      </Modal>


      <AssignQrModal
        isOpen={assignQrModal}
        payload={{
          is_active: 1,
          qr_ids: selectedQrIds
        }}
        toggle={() => setAssignQrModal(false)}
        onSucess={() => {
          setSelectedQrIds(() => [])
          clearAssignQrListData()
          getQrList()
        }}
      />

      <div className='custum-table-fix'   style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
        <DataTable
          // title='Live Order'
          pagination
          paginationServer
          progressComponent={<Spinner color="primary"
            style={{
              height: '3rem',
              width: '3rem'
            }} />}
          progressPending={state.isLoading}
          onRowClicked={(row) => setModalData({
            modal: 'viewQr',
            payload: {
              uid: row.qr_uid,
              src: row.qr_with_frame,
              is_web: row.is_web,
              qr_shortlink: row.qr_shortlink,
              firebase_shortlink_app: row.firebase_shortlink_app,
              firebase_shortlink_web: row.firebase_shortlink_web,
              qr_long_url: row.qr_long_url
            }
          })}
          onSelectedRowsChange={(e) => {
            setAssignQrBut(e.selectedRows.every((e) => e.assigned_cafe_list_id !== null))
            setSelectedQrIds(e.selectedRows.map((qrList) => {
              return { qr_codes_id: qrList.id }
            }))
            seQrToDownload(e.selectedRows.map((qrList) => qrList.qr_image_id))
          }}
          selectableRows={true}
          selectableRowsComponent={Checkbox}
          sortIcon={<ChevronDown />}
          subHeader={true}
          customStyles={customStyles({
            headCells: {
              justifyContent: "left",
              textAlign: 'left'
            },
            cells: {
              justifyContent: "left",
              textAlign: 'left'
            },
            rows: {
              cursor: "pointer"
            }
          })}
          fixedHeader={true}
          subHeaderComponent={
            <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
              <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
                <h1 className='datatable-title-head'>Generate QR</h1>
              </div>
              <div className='search-input' style={{ width: 'auto' }}>
                <Input value={state.searchStr} onChange={(e) => {
                  clearTimeout(timeOutId)
                  const timer = setTimeout(() => {
                    dispatch(GetGeneratedQR({
                      isLoading: true,
                      isRefreshing: true,
                      pageNo: 1,
                      rows: state.pageData.rowsPerPage,
                      str: e.target.value //state.searchStr
                    }))
                  }, 500)
                  setTimeOutId(timer)
                  dispatch(changeSearchString({ str: e.target.value }))
                }
                } />
                <span className='search-icon'><Search /></span>
              </div>
              {!selectedQrIds.length ? <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px', alignItems: 'center', padding: '0' }} >
                <ExportImportButton isImport={true} data={state.list} fileName='QR_code_list' sampleUrl="/download-excel-qrmanagement" url='/api/admin/import-qrmanagement-files' />
                <Button className='eat-btn' onClick={() => setgenrateQrModal(true)} >Create QR</Button>
              </div> : <><div className="d-flex align-items-center">
                <Button className='eat-btn-2 me-1' onClick={() => setAssignQrModal(true)} disabled={assignQrbut} >Assign To</Button>
                <Button className='eat-btn-success me-1' onClick={downloadZipFile} style={{ display: 'none' }}  >Download</Button>
              </div>
              </>}
            </div>
          }

          selectableRowDisabled={(row) => row.assigned_cafe_list_id}
          paginationDefaultPage={state.pageData.currentPage}
          paginationPerPage={state.pageData.rowsPerPage}

          paginationComponent={() => <CustomPagination
            pageData={state.pageData}
            numberOfData={state.list.length}
            handlePerPage={(e) => getQrList(parseInt(e.target.value), 1, state.searchStr)}
            handlePagination={(page) => getQrList(state.pageData.rowsPerPage, page.selected + 1, state.searchStr)} />}

          responsive={true}
          columns={columns}     // filter data for column
          data={state.list}       //takes data
          clearSelectedRows={true}
          highlightOnHover
        />
      </div>
    </>
  )

}

export default QRCodes
