// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import authentication from "./authentication"
import listResturant from "./pagination/listResturant"
import bannerAds from "./pagination/bannerAds"
import liveOrder from "./pagination/liveOrders"
import orderHistory from "./pagination/orderHistory"
import generateQr from "./pagination/genarateQr"
import tableManagementList from "./pagination/tableManagement"
import employeeTable from "./pagination/employeeTable"
import customer from "./pagination/customer"
import review from "./pagination/review"
import universalCategory from "./pagination/menu/universalCategory"
import universalItem from "./pagination/menu/universalItem"
import restaurantMenu from "./pagination/menu/restaurantMenu"
import menuCombo from "./pagination/menu/menuCombo"

const rootReducer = {
    navbar,
    layout,
    authentication, 
    listResturant,
    bannerAds,
    liveOrder,
    orderHistory,
    generateQr,
    tableManagementList,
    employeeTable,
    customer,
    review,
    universalCategory,
    universalItem,
    restaurantMenu,
    menuCombo
}

export default rootReducer
