import { useNavigate } from "react-router-dom"
import { useState, useEffect } from 'react'

// import Repeater from '@components/repeater'

import { X, Plus, Hash, ArrowLeft } from 'react-feather'
import Select from 'react-select'

// ** Reactstrap Imports
// import { selectThemeColors } from '@utils'
import { Row, Col, Card, Form, Input, Label, Button, CardBody, CardText, InputGroup, InputGroupText, Spinner } from 'reactstrap'

import { handleApiError, instance, instanceV1 } from "../../../utility/Utils"
// ** Styles
import 'react-slidedown/lib/slidedown.css'
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/base/pages/app-invoice.scss'
import toast from "react-hot-toast"


const AddTable = () => {

    const navigate = useNavigate()
    const [cafeOption, setCafeOptions] = useState([])

    // const [count, setCount] = useState(1)
    const [uidOptions, setUidOptions] = useState([])
    const [selectedUids, setSelectedUids] = useState([])
    // const [close, setClose] = useState(false)
    const [data, setData] = useState({
        cafe_list_id: "",
        table_title: "",
        is_active: 0
    })
    const [saving, setsaving] = useState(false)
    // const animatedComponents = makeAnimated()
    
    const handleSubmit = async () => {
        const newInstance = instanceV1()
        setsaving(true)
        try {
            const j = await newInstance.post("/cafe-tables", {
                cafe_list_id: data.cafe_list_id,
                is_active: data.is_active,
                table_title: data.table_title,
                items: selectedUids.map((uid) => {
                    return {
                        qr_codes_id: uid.value
                    }
                })
            })
            navigate('/restaurant-qr')
            toast.success(j.data.msg)
        } catch (err) {
            handleApiError(err)
        } finally {
            setsaving(false)
        }
    }
    const loadCafe = async () => {
        const newInstance = instance()
        try {
            const j = await newInstance.get("api/user/admin/cafe-list/get/all")
            const sortedData = j.data.data
            sortedData.sort((a, b) => {
                const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
                const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
            setCafeOptions(sortedData.map((e) => {
                return {
                    label: e.cafe_name,
                    value: e.id
                }
            }))
        } catch (error) {
            handleApiError(error)
        }
    }

    const getUids = async (cafe_list_id) => {
        try {
            const newInstance = instanceV1()
            const j = await newInstance.get(`/search-qr?cafe_list_id=${cafe_list_id || data.cafe_list_id}`)
            setUidOptions(j.data.data.map((e) => {
                return {
                    label: e.qr_uid,
                    value: e.qr_codes_id
                }
            }))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loadCafe()
    }, [])


    return (
        <>
            <div className="mb-2">
                <h1 className='datatable-title-head'>  <ArrowLeft className="icon-hover" onClick={() => navigate('/restaurant-qr')} /> Add Table</h1>
            </div>
            <Row>
                        <Col md='6' sm='12' className="mb-1" >
                            <Label >Restaturant Name</Label>
                            <Select
                                // isClearable={true}
                                // theme={selectThemeColors}
                                closeMenuOnSelect
                                // value={cafeOption.find((e) => e.value === assingedRestro)}
                                options={cafeOption}
                                onChange={(e) => {
                                    setData({ ...data, cafe_list_id: e.value })
                                    getUids(e.value)
                                }}

                                className='react-select'
                                required={true}
                            />
                        </Col>
            </Row>
            <Row>
                        <Col md='6' sm='12' className='mb-3'>
                            <Label className='form-label' for='table_title'>
                                Table Title <span style={{ color: 'red' }} >*</span>
                            </Label>
                            <Input type='text' value={data.table_title || ""} name='table_title' id='table_title'
                                onChange={(e) => setData({ ...data, table_title: e.target.value })}
                                placeholder='Table No.'
                            />
                        </Col>
            </Row>
            {/* <Row>
                <Repeater count={count}>
                    {i => {
                        // const Tag = i === 0 ? 'div' : SlideDown
                        return (
                            <Col md="10" key={i} className='repeater-wrapper'>
                                <Row>
                                    <Col md='6' sm='12'>
                                        <Label >Select QR UID *</Label>

                                        <Select
                                            // isClearable={true}
                                            // theme={selectThemeColors}
                                            closeMenuOnSelect
                                            // value={cafeOption.find((e) => e.value === assingedRestro)}
                                            options={uidOptions.map((e) => {
                                                return {
                                                    label: e.qr_uid,
                                                    value: e.id,
                                                    isDisabled: !!selectedUids.find((d) => d === e.qr_uid)
                                                }
                                            })}
                                            // onChange={(e) => console.log(e.value)}
                                            onChange={(e) => setSelectedUids((prev) => {
                                                const val = prev
                                                val[i] = e.value
                                                return val
                                            })}
                                            onMenuClose={() => setClose(!close)}
                                            className='react-select'
                                            required={true}
                                        // isDisabled={count - 1 > i}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )
                    }}
                </Repeater>
            </Row> */}
            <Row>
                <Col sm="12" md="6">
                    <Select
                        isMulti={true}
                        // value={data.menu_addon_price_ids && data.menu_addon_price_ids.map(e => addonOption.find(d => d.value === e))}
                        closeMenuOnSelect
                        options={uidOptions}
                        onChange={(e) => {
                            console.log(e)
                            setSelectedUids(e)
                        }}
                        styles={{
                            option: (base, {isFocused, isSelected}) => {
                                return {
                                  ...base,
                                  background: isFocused ? "linear-gradient(166.45deg, #f2473f85 -14.38%, #ff6b34a1 105%)" : isSelected ? "linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)" : "white",
                                  color:isFocused || isSelected ? "white"  : "black"
                                }
                            },
                            multiValue: (base) => {
                                return { ...base, maxWidth: 'max-content', borderRadius: '10px', background:'linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)', "& div":{ color:'white'}}
                            },
                            multiValueRemove: (base) => {
                                return { ...base, maxWidth: 'max-content', borderRadius: '50%', color:'green ' }
                            },
                            multiValueContainer: (base) => {
                                return { ...base, maxWidth: 'max-content', color:'white'  }
                            },
                            valueContainer: (base) => {
                                return {
                                    ...base,

                                    '& div': {
                                        maxWidth: 'fit-content'
                                    }
                                }
                            }
                        }
                        }
                    />
                </Col>
            </Row>
            {/* <Row className='mt-1'>
                <Col md="10" >
                    <Row>
                        <Col md='6' sm='12' style={{ display: 'flex', justifyContent: 'center' }} >
                            <Col md='10' sm='10'>
                                <Col style={{ border: "dashed 2px #E0E3E7", padding: '0.5rem' }}>
                                    <Button color='#0057FF' size='sm'
                                        style={{ background: '#F3F3F3', marginRight: '0.5rem' }}
                                        disabled={!selectedUids[count - 1]}
                                        onClick={() => setCount((prev) => prev + 1)}>
                                        <Plus size={24} color='blue' fill="#0057FF"
                                            style={{ fontWeight: '700' }}

                                            className='me-25'></Plus>
                                    </Button>
                                    <span className='align-middle'>Add New QR UID</span>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row> */}

            <Row>

                <Col className="d-flex p-1">
                    <div style={{ fontWeight: 'bold' }}>Active</div>
                    <div className='form-check form-switch ms-1'>
                        <Input type='switch' name='is_active' id='is_active'
                            onChange={(e) => setData({ ...data, is_active: 0 + e.target.checked })}
                            checked={data.is_active || 0}
                        />
                    </div>
                </Col>
            </Row>
            <Row>

                <Col sm='12'>
                    <div className='d-flex mt-2 mb-3'>
                        <Button outline className='me-1' color='secondary' type='cancel' onClick={() => navigate('/restaurant-qr')}>
                            Cancel
                        </Button>
                        <Button className='me-1 eat-btn' onClick={handleSubmit} disabled={!(data.table_title) || saving || !selectedUids.length}>
                            Save {saving && <Spinner size='sm' />}
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AddTable