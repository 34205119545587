import { useId, useState } from 'react'
import { Modal, Input, ModalHeader, InputGroup, InputGroupText, Form, Spinner } from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { getAmozonAWSName, handleApiError, instance } from '../utility/Utils'
import toast from 'react-hot-toast'


const PdfInput = (props) => {
const id = useId()
  const {data} = props
  const [pdfModal, setPdfModal] = useState({
    isOpen: false,
    payload:{
      url:''
    }
  })
  const [uploading, setUploading] = useState(false)

  const uploadTest = async (file) => {
    const newInstance = instance()
    try {
      setUploading(true)
      const j = await newInstance.post('/api/user/admin/uploadFile', { file }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      // data.onChange(j.data)
      // console.log(j.data)
      toast.success('Uploaded')
      props.onChange(j.data)
    } catch (err) {
      handleApiError(err)
    } finally {
      setUploading(false)
    }
  }
  return (
    <>

      <Modal className='modal-dialog-centered  modal-lg' isOpen={pdfModal.isOpen} toggle={() => setPdfModal({...pdfModal, isOpen:!pdfModal.isOpen})}>
        <ModalHeader toggle={() => setPdfModal({...pdfModal, isOpen:!pdfModal.isOpen})}></ModalHeader>
        <iframe style={{ width: '100%', height: '100vh' }} src={`https://docs.google.com/gview?url=${data.url}&embedded=true`} />
      </Modal>

      <InputGroup className='input-pdf' style={{cursor:'pointer', borderRadius:'7px', overflow:'hidden'}}>
        <div className='input-group form-control' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}  onClick={() => document.getElementById(id).click()}>{data.url ? getAmozonAWSName(data.url) : 'Please upload a file here'}</div>
        <Input id={id} style={{ display: 'none'}} type='file' accept='.pdf'
          onChange={(e) => {
            // console.log(e.target.files[0])
            if (!uploading && e.target.files[0] && (e.target.files[0].type === "application/pdf")) { 
             
              uploadTest(e.target.files[0]) 
            } else {
              toast.error('please select PDF Only')
            }
          }}
        />
        {(data.url || uploading) ? <InputGroupText style={{ color: 'green' }} onClick={() => {
          if (!uploading && data.url) {
            setPdfModal({
              isOpen: true,
              payload: {
                url: data.url
              }
            })
          }
        }}>
          {uploading ? <Spinner size='sm' /> : 'View'}
        </InputGroupText> : <></>}
      </InputGroup>

    </>
  )
}

export default PdfInput