// ** React Imports
import { Link } from "react-router-dom"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/Admin.png"


// ** Third Party Components
import {
  Settings,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"
import { useContext, useEffect} from "react"
import { AuthContext } from "../../../../utility/context/Can"
import { dataGet, getCookieByName, getImageCloudFlare, logout } from "../../../../utility/Utils"
import { runOneSignal } from "../../../../utility/one-signal/OneSignal"

// ** Default Avatar Image
// import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg"

const UserDropdown = () => {
  const [userData, userDataSetter] = useContext(AuthContext)
  // const [isLoading, setisLoading] = useState(true)
  // // const token = document.cookie.split("=")[1]
// console.log(userData)
  

// const logoutHandler = () => {
//      localStorage.clear()
//      document.cookie = `token=`
//      userDataSetter({
//       getProfile: [{ first_name: "", last_name: "", profile_pic_image_id: "", cafe_name: "" }]
//    })
//   }


  const token = getCookieByName('token')
  const getProfile = async () => {
    
    if (token) {
      const j = await dataGet()
      if (j) {
         userDataSetter(j) 
        }
    }
    
  }
 
  useEffect(() => {
    
    if (token) {
   runOneSignal()   
    }
     getProfile()
     return () => {}
  }, [])

return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
        <span className="user-name fw-bold">
                  {`${userData.getProfile[0].first_name }`} &nbsp;
                  {`${userData.getProfile[0].last_name }`}
               </span>
                <span className="user-status">Admin</span>
        </div>
        <Avatar
           img={userData.getProfile[0].profile_pic_image_id ? getImageCloudFlare(userData.getProfile[0].profile_pic_image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention) : defaultAvatar}       
         imgHeight="40"
          imgWidth="40"
          // status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/dashboard/setting" >
          <Settings ize={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
       
        <DropdownItem  onClick={logout}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
