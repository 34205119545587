// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import toast from 'react-hot-toast'

const instance = axios.create({
    baseURL:"https://app-admin.a2deatsdev.in"
  })
  
// ** UseJWT import to get config
// import useJwt from '@src/auth/jwt/useJwt'

// const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('user')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: async(state, action) => {
      try {
        const j = await instance.post('/api/user/admin/login',
        {
          email:`${action.payload.email}`,
          password:`${action.payload.password}`
        }, 
        {mode:'no-cors'}
        )
        localStorage.setItem('user', JSON.stringify({
          user:action.payload
          })
        )
        toast.success(j.message)
        document.cookie = `token = ${j.data.token}`
        state.userData = action.payload
        console.log(j.data)
      } catch (err) {
        console.warn(err)
        err.response.data === undefined ? toast.error(err.message) : toast.error(err.response.data.msg)
    }
      // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      // localStorage.setItem('userData', JSON.stringify(action.payload))
      // localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.ref reshToken))
    },
    handleLogout: state => {
      state.userData = {}
      // state[config.storageTokenKeyName] = null
      // state[config.storageRefreshTokenKeyName] = null
      // // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('user')
      document.cookie = 'token = ""'
      // localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
