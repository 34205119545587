import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, MoreVertical, Search } from 'react-feather'

import { formatDateNormal, formatDateTimeFigma, getImageCloudFlare} from "../../utility/Utils"
import { useNavigate } from 'react-router-dom'
import { Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Spinner } from 'reactstrap'
import { CustomPagination, customStyles } from '../../utility/datatable-style/dataTableStyle'
import { useDispatch, useSelector } from 'react-redux'
import { GetBannerAds, changeTableSearchString } from '../../redux/pagination/bannerAds'
const BannerAds = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.bannerAds)
  const [searchDebounceId, setSearchDebounceId] = useState(null)

  const columns = [
    {
      name: 'S.NO.',
      selector: row => (((state.pageData.currentPage - 1) * state.pageData.rowsPerPage) + state.list.indexOf(row)) + 1,
      maxWidth: '6.5rem',
      minWidth: '63px'
    },
    {
      name: 'Banner UID',
      selector: row => row.uid || "Creating...",
      minWidth: '12rem',
      maxWidth: '15rem'
    },
    {
      name: 'POSITION',
      selector: row => row.position,
      maxWidth: '8.5rem',
      minWidth: '6.6rem'
    },
    {
      name: 'Title',
      selector: row => <div className='d-flex' >
          <img style={{aspectRatio: "129 / 51", minWidth: '129px', borderRadius:'8px', objectFit:'cover', padding:'.3rem'}} alt="ad" src={getImageCloudFlare(row.banner_image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention)} />
        <div style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{row.title}</div>
      </div>,
      minWidth: '18rem',
      textAlign:true,
      style:{
        textAlign:'left',
        width:'100%',
        justifyContent:'start'
      }
    },
    {
      name: 'CREATED DATE',
      selector: (row) => formatDateNormal(row.created_at),
      minWidth:"10rem",
      maxWidth:'12rem'
    },
    {
      name: 'START DATE',
      selector: (row) => formatDateTimeFigma(row.start_date),
      minWidth:"13rem",
      maxWidth:"15rem"
    },
    {
      name: 'END DATE',
      selector: (row) => formatDateTimeFigma(row.end_date),
      minWidth:"13rem",
      maxWidth:"15rem"
    },
    {
      name: 'CATEGORY',
      selector: (row) => <div style={{ whiteSpace: 'break-spaces', textAlign: "center", width: '150px' }}>{row.category}</div>,
      minWidth: '12rem',
      maxWidth:'17rem'
    },
    {
      name: 'Restaurant Name',
      selector: (row) => <div style={{ whiteSpace: 'break-spaces', textAlign: "center" }}>{row.cafe_name}</div>,
      minWidth: '13rem',
      maxWidth:'17rem'
    },
    {
      name: 'CLICK COUNT',
      selector: (row) => row.clicks_count || 0,
      maxWidth: '12.5rem',
      minWidth: '9rem'
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.banner_ad_status === 1) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Active
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Inactive
          </div>
        }
      },
      maxWidth:'8rem',
      minWidth:'8rem',
      allowOverflow:true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        return (
          <div className='d-flex'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1' tag='span'>
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag='a' onClick={() => navigate(`/list-banner/edit/${row.banner_ad_id}`)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'> Edit</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      },
      maxWidth:'2.5rem',
      minWidth:'100px',
      // style:{
      //   position:'sticky',
      //   right:'0',
      //   background:'white'
      // },
      allowOverflow:true
    }

  ]


  const getBannerList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    dispatch(GetBannerAds({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr
    }))
  }
  useEffect(() => {
    getBannerList()
  }, [])

  // ** Table
  return (
    <div className='custum-table-fix data-table-custum-container' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>

      <DataTable
        pagination
        paginationServer
        progressComponent={<Spinner color="primary"
          style={{
            height: '3rem',
            width: '3rem'
          }} />}
        progressPending={state.isLoading}
        sortIcon={<ChevronDown />}
        subHeader={true}
        customStyles={customStyles()}
        subHeaderComponent={
          <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
            <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
              <h1 className='datatable-title-head'>Banner Ads</h1>
            </div>
            <div className='search-input' style={{ width: 'auto' }}>
                <Input value={state.searchStr} onChange={(e) => {
                  clearTimeout(searchDebounceId)
                  const timer = setTimeout(() => {
                    dispatch(GetBannerAds({
                      isLoading: true,
                      isRefreshing: true,
                      pageNo: 1,
                      rows: state.pageData.rowsPerPage,
                      str: e.target.value //state.searchStr
                    }))
                  }, 500)
                  setSearchDebounceId(timer)
                  dispatch(changeTableSearchString({ str: e.target.value }))
                }
                } />
                <span className='search-icon'><Search /></span>
              </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px', alignItems: 'center' }} >
              <Button className='eat-btn' onClick={() => navigate('/list-banner/upload')} >Upload Ads</Button>
            </div>
          </div>
        }
        paginationDefaultPage={state.pageData.currentPage}
        paginationComponent={() => <CustomPagination
          pageData={state.pageData}
          numberOfData={state.list.length}
          handlePerPage={(e) => getBannerList(parseInt(e.target.value), 1, state.searchStr)}
          handlePagination={(page) => getBannerList(state.pageData.rowsPerPage, page.selected + 1, state.searchStr)} />}
        fixedHeader={true}
        responsive={true}
        paginationPerPage={state.pageData.rowsPerPage}
        columns={columns}     // filter data for column
        data={state.list}       //takes data
        highlightOnHover
      />
    </div>
  )

}

export default BannerAds
