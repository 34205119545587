import { Button, Row, Col, Input, Form, Label, Spinner } from "reactstrap"
import { UploadFileLogo } from "../../@core/assets/fonts/feather/icons"
import { useState } from "react"
import { handleApiError, instance } from "../../utility/Utils"
import toast from "react-hot-toast"

export const AvtarCustum = ({
  src,
  handleChange,
  hoverText,
  style
}) => {

  const [uploading, setUploading] = useState(false)

  const uploadImage = async (file = {size:0}) => {
    if (file.size / 1000000 > 2) {
      toast.error("Max allowed size 2MB")
    } else {
      const newInstance = instance()
      try {

        setUploading(true)
        const j = await newInstance.post('/api/user/admin/uploadImage', { file }, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        handleChange(j.data.id)
        //   setUserProfileData({ ...userProfileData, profile_pic_image_id: j.data.id })
      } catch (error) {
        handleApiError(error)
      } finally {
        setUploading(false)
      }
    }
  }


  return (
    <Row>
      <Col>
        <Button tag={Label} color='none'
          className="custom-avtar"
          style={{
            position: 'relative',
            borderRadius: '50%',
            height: '100px',
            width: '100px',
            padding: '0',
            margin: '10px 0',
            background: 'gray',
            overflow: 'hidden',
            backgroundImage: `url(${src})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: "contain",
            backgroundColor: '#E7ECF0',
            justifyContent: "center",
            display: 'flex',
            alignItems: 'center',
            ...style
          }} size='sm' >
          {!uploading && <div className="upload-image"  >
              <UploadFileLogo />
              <p>{hoverText || "upload Image"}</p>
            </div>}
          {uploading && <Spinner color='primary' />}
          <Input
            type='file'
            onChange={(e) => uploadImage(e.target.files[0])}
            hidden
            accept="image/png, image/jpeg, image/jpg"
          />
        </Button>

      </Col>
    </Row>
  )
}