import { Mail, Home, Circle, Disc, User } from "react-feather"
import { QR, Menu, DashBoard, Resturent, Employee, Orders, Reviews, Ads } from "../../@core/assets/fonts/feather/icons"

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <DashBoard size={20} />,
    navLink: "/dashboard"
  },
  {
    id: "list-restaurants",
    title: "List Restaurants",
    icon: <Resturent size={20} />,
    navLink: "/list-restaurants"
      },
  {
    id: "list-banner",
    title: "List Banners",
    icon: <Ads size={20} />,
    navLink: "/list-banner"
  },
  {
    id: "list-orders",
    title: "List Orders",
    icon: <Orders size={20} />,
    children:[
      {
        id: "live-orders",
        title: "Live orders",
        icon: <Disc size={20} />,
        navLink: "/live-orders"
      },
      {
        id: "orders-history",
        title: "Order History",
        icon: <Disc size={20} />,
        navLink: "/order-history"
      }
    ]
  },
  {
    id: 'list-empolyees',
    title: 'List Employees',
    icon: <Employee size={20} />,
    navLink: '/list-empolyees'
  },
  {
    id: 'list-customers',
    title: 'List Customers',
    icon: <User size={20} />,
    navLink: '/list-customers'
  },
  {
    id: "food-menu",
    title: "Food Menu",
    icon: <Menu size={20} />,
    children:[
      {
        id: "universal-category",
        title: "Universal Category",
        icon: <Disc size={20} />,
        navLink: "/universal-category"
      },
      {
        id: "universal-item",
        title: "Universal Item",
        icon: <Disc size={20} />,
        navLink: "/universal-item"
      },
      // {
      //   id: "universal-addons",
      //   title: "Universal Addons",
      //   icon: <Disc size={20} />,
      //   navLink: "/universal-addons"
      // },
      {
        id: "restaurant-menu",
        title: "Restaurant Menu",
        icon: <Disc size={20} />,
        navLink: "/restaurant-menu"
      },
      {
        id: "restaurant-combos",
        title: "Restaurant Combos",
        icon: <Disc size={20} />,
        navLink: "/restaurant-combos"
      }
    ]
  },
  {
    id: "list-reviews",
    title: "List Reviews",
    icon: <Reviews/>,
    navLink: "list-reviews"
  },
  {
    id: "qr-management",
    title: "QR Management",
    icon: <QR/>,
    children:[
      {
        id: "generate-qr",
        title: "Generate QR",
        icon: <Disc size={20} />,
        navLink: "/generate-qr"
      },
      {
        id: "restaurant-qr",
        title: "Table Management",
        icon: <Disc size={20} />,
        navLink: "/restaurant-qr"
      }
    ]
  }
]