
// ** React Imports
import { useState, useEffect } from 'react'

import { ArrowDown, ArrowLeft, Calendar, ChevronDown, File } from "react-feather"
// ** Reactstrap Imports
import {
    Row,
    Col,
    Input,
    Dropdown,
    Card,
    CardBody,
    Form,
    Button,
    Label,
    InputGroup,
    InputGroupText,
    Spinner
  } from 'reactstrap'   

  import {useParams } from 'react-router-dom'
// ** Third Party Components
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'

import { formatTime, handleApiError, instance } from '../../../utility/Utils'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import toast from 'react-hot-toast'

const TimeAndPrice = () => {
  const [saving, setSaving] = useState(false)
    const [data, setData] = useState({
        address_1: "",
        address_2:   "",
        average_rating :   0,
        average_ticket_size:   0,
        average_waiting_time:   "00:00:00",
        cafe_about:   "",
        cafe_list_id:   0,
        cafe_long_url:   "",
        cafe_name:   "",
        cafe_price_range:   '',
        cafe_short_url:   "",
        cafe_slogan:   "",
        city:   "",
        closing_time:   "00:00:00",
        country:   "India",
        created_at:   "2022-11-05T14:14:55.000Z",
        currency:   "",
        email:   "",
        fssai_certificate_url :   "",
        is_featured:   0,
        is_most_visited:   0,
        is_new_opening:   0,
        is_non_veg:   0,
        is_veg:   0,
        latitude:   14.8801218,
        logo_image_id:   "",
        longitude:   -91.4586872,
        mobile_number:   null,
        most_used_payment_mode:   "Other",
        opening_time:   "00:00:00",
        pin_code:   null,
        social_facebook_url:   "",
        social_instagram_url:   null,
        social_linkedin_url:   "",
        social_twitter_url:   "",
        social_whatsapp_no:   0,
        starting_price:   0,
        state:   "Rajasthan",
        status:   1,
        total_item:   0,
        total_order:   0,
        total_review:   0,
        uid:   "",
        updated_at:   "2022-11-05T14:14:55.000Z",
        user_admin_id:   428,
        website_url: ""
    })
    const {id} = useParams()

    const animatedComponents = makeAnimated()
    const priceOptions = [
      { value: '1', label: 'Most Expensive', isFixed: true },
      { value: '2', label: 'Expensive', isFixed: true },
      { value: '3', label: 'Normal', isFixed: true },
      { value: '4', label: 'Economy', isFixed: false },
      { value: '5', label: 'Most Economy', isFixed: false }
    ]
    
  const getCafeById = async() => {
      const newInstance = instance()
      try {
        const j = await newInstance.get(`/api/user/admin/restaurant-all-info/${id}`)
        if (j.data.message[0]) {
          setData(j.data.message[0])
      }
      } catch (err) {
        handleApiError(err)
       }
  }

  const handleSubmit = async() => {
   setSaving(true)
    const newInstance = instance()
    try {
      // await newInstance.post(`/api/user/admin/restaurant-edit-time&price-information/${id}`,
      // {
      //   opening_time: data.opening_time,
      //   closing_time: data.closing_time,
      //   cafe_price_range: data.cafe_price_range
      // })

      const j = await newInstance.post(`/api/user/admin/restaurant-edit-time&price-information/${id}`,
      {
        opening_time: data.opening_time,
        closing_time: data.closing_time,
        cafe_price_range: data.cafe_price_range
      })
      toast.success(j.data.msg)
    } catch (err) {
      handleApiError(err)
     } finally {
      setSaving(false)
     }
}
    useEffect(() => {
      getCafeById() 
    }, [])

    return (
      <Row className='mt-2'>
        <Col md='10'>
      <Row>
          <Col md='6' xs="12" className='mb-1'>
              <Label id='timepicker'>
                  Opening Time
               </Label>
              <InputGroup className='input-group-merge'>
                <Flatpickr
                  style={{background:'#FFFFFF'}}
                 className='form-control'
                 name='opening_time'
                 icon=''
                 value={data.opening_time}
                 id='timepicker'
                 options={{
                   enableTime: true,
                   noCalendar: true,
                   dateFormat: "h:i K",
                   time_24hr: false,
                   time_12hr: true
                 }}
                //  onChange={date => setData({...data, opening_time:formatTime(date)})}
               onChange={d => {
                  setData((prev) => {
                    return {...prev, opening_time:formatTime(d[0] || new Date())}
                  })
                }}
               /> 
               
               <InputGroupText>
                <Calendar  size={14} />
              </InputGroupText>
              </InputGroup>
           </Col>
           <Col md='6' xs="12" className='mb-1'>
              <Label id='timepicker'>
                  Closing Time
               </Label>
               <InputGroup className='input-group-merge'>
               <Flatpickr
               style={{background:'#FFFFFF'}}
                 className='form-control'
                 name='closing_time'
                 value={data.closing_time}
                 id='timepicker'
                 options={{
                   enableTime: true,
                   noCalendar: true,
                   dateFormat: "h:i K",
                   time_24hr: false,
                   time_12hr: true,
                    minTime:data.opening_time
                 }}
                 onChange={d => {
                  setData((prev) => {
                    return {...prev, closing_time:formatTime(d[0] || new Date())}
                  })
                  }}
                  />
               <InputGroupText>
                <Calendar  size={14} />
              </InputGroupText>
              </InputGroup>
           </Col>

           <Col md='6' xs="12" className='mb-1'>
                  <Label className='form-label' for='restaurant-name'>
                  Price Range
                  </Label>
                    <Select
                        placeholder='Select'
                        components={animatedComponents}
                        value={priceOptions[data.cafe_price_range - 1]}
                        options={priceOptions}
                        onChange={(e) => setData({...data, cafe_price_range:e.value})}
                        className='react-select'
                      />
              </Col>
              
              <Col md='12' className='mt-3  mb-3'>
                      <Button className='me-1 eat-btn'  type='input' 
                          onClick={handleSubmit} disabled={saving}>
                        Save {saving && <Spinner size='sm'/>}
                    </Button>
                  </Col>
      </Row>
      </Col>
      </Row>
        
    )
}

export default TimeAndPrice