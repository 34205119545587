// ** Reactstrap Imports
import { useEffect, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { Link, NavLink, useParams } from 'react-router-dom'
import { Card, CardBody, CardText, Row, Col, Table, Button, CardFooter, CardHeader } from 'reactstrap'
import { CancilledTag, EatsSimpleLogo } from '../../../@core/assets/fonts/feather/icons'
import { formatDateTimeFigma, handleApiError, instanceV1 } from '../../../utility/Utils'

const PreviewCard = ({ data }) => {

  if (data) {
    return (
      <>
        <div className='mb-2'>
          <h1 style={{color:"#292D32"}} >  <NavLink to='/order-history'  ><ArrowLeft color='#292D32' /></NavLink> Receipt </h1>
        </div>
        <div >
          <Card id="invoice" style={{ padding: "1.2rem", boxShadow: "none", background: "transparent" }} className='odrinv-wrap cancel'>
            <div style={{ position: 'absolute', top: '0', left: '0' }} >
              {data.order_status === 0 ? <CancilledTag /> : <></>}
            </div>

            <div className='odrinv-head'>
              <h4>{data.cafe_name}</h4>
              {data?.address_1 && <p  >{data.address_1}</p>}
              {data?.address_2 && <p  >{data.address_2}</p>}

              <hr style={{ textAlign: 'center', borderTop: '1px dashed #000000', width: "100%", margin: "0.1rem 0 0.3rem 0" }} />
            </div>
            {/* <p><strong style={{ fontSize: "14px" }}>Mobile No:</strong> <span >{data.cafe_mobile_number || ''}</span></p> */}
            {data.gst_number && <p><strong style={{ fontSize: "14px" }}>GSTIN : </strong><span >{data.gst_number || ''}</span></p>}
            {data.gst_number && <hr style={{ textAlign: 'center', borderTop: '1px dashed #000000', width: "100%", margin: "0.1rem 0 0.3rem 0" }} />}
            <table>
              <tbody>
                <tr>
                  <td >Date:</td>
                  <td>
                    <span >{formatDateTimeFigma(data.updated_at)}</span>
                  </td>
                </tr>
                <tr>
                  <td >Order Id:</td>
                  <td >{data.id}</td>
                </tr>
                <tr>
                  <td >Customer Name:</td>
                  <td>
                    <span >{`${data.first_name} ${data.last_name}`}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>Phone Number:</td>
                  <td >{data.user_customer_mobile_number}</td>
                </tr>
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>E-mail :</td>
                  <td >{data.user_customer_email}</td>
                </tr>
              </tbody>
            </table>
            <hr style={{ textAlign: 'center', borderTop: '1px dashed #000000', width: "100%", margin: "0.1rem 0 0.3rem 0" }} />

            <table>
              <tbody>
                <tr>
                  <td >Payment Mode :</td>
                  <td style={{ textAlign: 'end' }}>
                    <span  >{data.payment_mode === 1 ? 'Cash' : data.payment_mode === 2 ? 'UPI' : data.payment_mode === 3 ? 'Credit/Debit Card' : data.payment_mode === 4 ? 'Wallet' : 'Others'}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ textAlign: 'center', borderTop: '1px dashed #000000', width: "100%", margin: "0.1rem 0 0.3rem 0" }} />

            <Table style={{ boxShadow: 'none', borderCollapse: "separate", borderSpacing: "7px 0", margin: "0" }} size='sm' borderless >
              <thead style={{ marginBottom: "5px" }}>
                <tr style={{ textTransform: 'uppercase' }}>
                  <th style={{ textTransform: 'uppercase', fontWeight: '500' }} >Food Item</th>
                  <th style={{ textTransform: 'uppercase', textAlign: 'end', fontWeight: '500' }} >QTY</th>
                  <th style={{ textTransform: 'uppercase', textAlign: 'end', fontWeight: '500' }} >Rate</th>
                  <th style={{ textTransform: 'uppercase', textAlign: 'end', fontWeight: '500' }} >Total</th>
                </tr>
              </thead>

              {(data.cafe_order_item || []).map((e) => <tbody key={e.id} style={{ lineHeight: '21px' }}>
                <tr  >
                  <td >{e.order_item_name || e.order_item_combo_name || ''}</td>
                  <td style={{ textAlign: 'end' }} >{e.count || 0}</td>
                  <td style={{ textAlign: 'end' }} >₹{e.only_item_price || 0}</td>
                  <td style={{ textAlign: 'end' }} >₹{e.only_item_price * e.count || 0}</td>
                </tr>
                {e.addon_list.map((j, k) => <tr key={k} >
                  <td >{j.order_addon_name || ''}</td>
                  <td style={{ textAlign: 'end' }}>{e.count || 0}</td>
                  <td style={{ textAlign: 'end' }} >₹{j.order_addon_price || 0}</td>
                  <td style={{ textAlign: 'end' }}  >₹{j.order_addon_price * e.count || 0}</td>
                </tr>)}
                {/* <tr >
                  <td ></td>
                  <td ></td>
                  <td style={{ textAlign: 'end' }} ></td>
                  <td style={{ textAlign: 'end' }}  ></td>
                </tr> */}
              </tbody>
              )}

            </Table>
            <hr style={{ textAlign: 'center', borderTop: '1px dashed #000000', width: "100%", margin: "5px 0" }} />
            <div className='odrinv-total'>
              <div><span>Subtotal:</span>  <span className='midfont'>₹{data.sub_total || 0}</span></div>
              {data.is_gst ? <>
                <div><span>GST:</span>  <span className='midfont'>₹{data.total_gst_amount || 0}</span></div>
              </> : <></>}
              <div><span>Discount:</span>  <span className='midfont'>₹{data.discount_amount || 0}</span></div>
              <hr style={{ textAlign: 'center', borderTop: '1px dashed #000000', margin: "0.3rem" }} />

              <div>
                <span style={{ fontWeight: '500' }}>Grandtotal:</span>
                <span style={{ fontWeight: '500', textAlign: 'right' }}>₹{data.total_amount || 0}</span>
              </div>

            </div>
            <div className='odrinv-foot mt-1'>
              {data?.fssai_licence_number && <p  >FSSAI Lic. No{data.fssai_licence_number}</p>}
              <p >Thanks & visit again</p>
            </div>
            <CardFooter style={{ borderTop: 'none', display: "flex", alignItems: "baseline", justifyContent: "center" }}>Powered By &nbsp; <EatsSimpleLogo size={20} />
              {/* <span className='eatslogo'>eats  </span> */}
            </CardFooter>
          </Card>
        </div>
      </>
    )
  }

}


const PreviewActions = () => {
  return (
    <Card className='invoice-action-wrapper'>
      <CardBody>
        <Button color='primary' block className='mb-75'>
          Send Receipt
        </Button>
        <Button color='secondary'
          block outline className='mb-75'>
          Download
        </Button>
        <Button color='secondary' block outline
          onClick={() => {
            window.print()
          }}
          className='mb-75'>
          Print
        </Button>
      </CardBody>
    </Card>
  )
}

const OrderInvoice = () => {
  const { id } = useParams()
  const [data, setdata] = useState({})

  useEffect(() => {
    const loadHistoryById = async () => {
      const newInstance = instanceV1()
      try {
        const j = await newInstance.get(`/order-history/${id}`)


        const uniqueKeyMap = new Map()
        let total_gst_amount = 0
        let sub_total = 0
        // let 

        for (const item of (j?.data?.order_list[0]?.cafe_order_item || [])) {
          if (item.status !== 0) {
            const key = `${item.cafe_menu_item_id}-${item.cafe_menu_variant_id}-${item.status}-${item.cafe_menu_combo_id}-${item.cooking_instruction}-${JSON.stringify(item.addon_list.length)}`
            if (uniqueKeyMap.has(key)) {
              const exixtingItem = uniqueKeyMap.get(key)
              exixtingItem.count++
              exixtingItem.order_detail_ids.push(item.cafe_order_item_id)
              total_gst_amount = total_gst_amount + item.order_item_tax
              sub_total = sub_total + item.order_item_price
            } else {
              item.count = 1
              item.order_detail_ids = [item.cafe_order_item_id]
              total_gst_amount = total_gst_amount + item.order_item_tax
              sub_total = sub_total + item.order_item_price
              uniqueKeyMap.set(key, item)
            }
          }
        }
        setdata({ ...j?.data?.order_list[0], cafe_order_item: Array.from(uniqueKeyMap.values()), total_gst_amount, sub_total })
      } catch (error) {
        handleApiError(error)
      }
    }
    loadHistoryById()
  }, [])


  return (
    <div className='invoice-preview-wrapper'>
      <Row className='invoice-preview'>
        <Col xl={8} md={6} sm={12} className='recipt' >
          <PreviewCard data={data} />
        </Col>
        <Col className='ms-auto' xl={3} md={4} sm={12}>
          <PreviewActions />
        </Col>
      </Row>
    </div>
  )
}


export default OrderInvoice