import { Col, Form, Label, Modal, ModalBody, ModalHeader, Row, Input, Button, Spinner } from "reactstrap"

import Select from 'react-select'
import { handleApiError, instance, instanceV1 } from "../../utility/Utils"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"

const AssignQrModal = ({isOpen, toggle, payload, onSucess}) => {

    const [cafeOption, setCafeOptions] = useState([])
    const [assingedRestro, setAssignedRestro] = useState(0)
    const [assigning, setAssigning] = useState(false)

    const loadCafe = async() => {
        const newInstance = instance()
        try {
          const j = await newInstance.get("api/user/admin/cafe-list/get/all")
          const sortedData = j.data.data
          sortedData.sort((a, b) => {
            const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
            const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            // names must be equal
            return 0
          })
          setCafeOptions(sortedData.map((e) => {
            return {
              label : e.cafe_name,
              value : e.id
            }
          }))
        } catch (error) {
          handleApiError(error)
        }
    }

    const assignQR = async(e) => {
        e.preventDefault()
        try {
            setAssigning(true)
            const newInstance = instanceV1()
            await newInstance.put('/qr-assign', {
              cafe_list_id: assingedRestro,
              is_active: payload.is_active,
              items: payload.qr_ids
            })
            toast.success("Assigned Sucessfully")
            toggle()
            onSucess()
        } catch (error) {
            handleApiError(error)
        } finally {
            setAssigning(false)
        }
    }

    useEffect(() => {
        loadCafe()
    }, [])

    return <Modal className='modal-dialog-centered ' size='sm' isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}></ModalHeader>
    <ModalBody>
      <h2 className='m-1' style={{ textAlign: 'center' }} >Assign to</h2>
      <Form onSubmit={assignQR}>
        <Row style={{ margin: 'auto', alignItems:'center', justifyContent:'center' }} >

        <Row className="mb-3" >                
                  <Label >Restaturant Name</Label>
                  <Select
                    // isClearable={true}
                    // theme={selectThemeColors}
                    closeMenuOnSelect
                    value={cafeOption.find((e) => e.value === assingedRestro)}
                    options={cafeOption}
                    onChange={(e) => setAssignedRestro(e.value)}
                    className='react-select'
                    required={true}
                  />
        </Row>
          <Row>
            <Col type='button' style={{ textAlign: 'center', paddingBottom: '40px' }}>
              <Button outline className='me-1' color='secondary' type='cancel' onClick={toggle} >
                Cancel
              </Button>
              <Button className='me-1 eat-btn' type='submit' disabled={assigning} >
              Assign {assigning && <Spinner size="sm" />}
              </Button>
            </Col>
          </Row>

        </Row>
      </Form>
    </ModalBody>
  </Modal>
}

export default AssignQrModal