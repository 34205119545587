import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, MoreVertical, Search } from 'react-feather'
// import defaultAvatar from "@src/assets/images/portrait/small/Admin.png"

// import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Spinner, Input } from 'reactstrap'
import ExportImportButton from '../../UI/ExportImport'
import { customStyles, CustomPagination } from '../../utility/datatable-style/dataTableStyle'
import { useDispatch, useSelector } from 'react-redux'
import { GetCustomerData, changeSearchString } from '../../redux/pagination/customer'

const CustomerTable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.customer)
  const [timeOutId, setTimeoutId] = useState(null)

  const columns = [
    {
      name: 'SN',
      selector: row => (((state.pageData.currentPage - 1) * state.pageData.rowsPerPage) + state.list.indexOf(row)) + 1,
      maxWidth: '6.5rem',
      minWidth: '4rem'
    },
    {
      name: 'Customer UID',
      selector: row => row.user_customer_id || "",
      minWidth: '8rem',
      maxWidth: '10rem'
    },
    {
      name: 'FULL NAME',
      selector: row => `${row.first_name || ""} ${row.last_name || ""}`,
      minWidth: "13rem"
    },
    {
      name: 'Customer City',
      selector: row => row.city || "",
      minWidth: "10rem",
      maxWidth: '12rem'
    },
    {
      name: 'Customer Email',
      selector: row => row.email || ' ',
      minWidth: "16rem",
      maxWidth: '19rem'
    },
    {
      name: "Customer Phone",
      selector: row => `${row.mobile_number ? "+91-" : ''}${row.mobile_number || ""}`,
      minWidth: "11rem",
      maxWidth: "13rem"
    },
    {
      name: "NO. OF ORDERS",
      selector: row => row.order_count || 0,
      minWidth: '8rem',
      maxWidth: "9rem"
    },
    {
      name: <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }} >NO. OF REVIEWS</div>,
      selector: row => row.review_count || 0,
      width: '100px'
    },
    {
      name: 'LAST ACTIVE',
      selector: row => row.last_active && row.last_active.split('T')[0].split('-').reverse().join('/'),
      width: '150px'
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.status === 1) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Active
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Inactive
          </div>
        }
      },
      maxWidth: '8rem',
      minWidth: '8rem',
      allowOverflow: true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        const navigate = useNavigate()
        return (
          <div className='d-flex'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1' tag='span'>
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag='a' onClick={() => navigate(`/order-history?id=${row.user_customer_id}`)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'> Order History</span>
                </DropdownItem>
                <DropdownItem tag='a' onClick={() => navigate(`list-reviews?id=${row.user_customer_id}`)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'>Review History</span>
                </DropdownItem>
                <DropdownItem tag='a' onClick={() => navigate(`/list-customers/edit-profile/${row.user_customer_id}`)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'>Edit Profile</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      },
      maxWidth: '2.5rem',
      minWidth: '100px',
      fixed: "left",
      // style: {
      //   position: 'sticky',
      //   right: '0',
      //   background: 'white'
      // },
      allowOverflow: true
    }

  ]

  const getCustomerList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    clearTimeout(timeOutId)
    dispatch(GetCustomerData({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr
    }))
  }
  useEffect(() => {
    getCustomerList()
  }, [])

  // ** Table
  return (
    <div className='custum-table-fix' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
      <DataTable
        // title='Live Order'
        pagination
        paginationServer
        sortIcon={<ChevronDown />}
        subHeader={true}
        customStyles={customStyles()}

        progressComponent={<Spinner color="primary"
          style={{
            height: '3rem',
            width: '3rem'
          }} />}
        progressPending={state.isLoading}
        paginationComponent={() => <CustomPagination
          pageData={state.pageData}
          numberOfData={state.list.length}
          handlePerPage={(e) => getCustomerList(e.target.value, 1)}

          handlePagination={(page) => getCustomerList(state.pageData.rowsPerPage, page.selected + 1)}  />}
        // paginationComponent={CustomPagination}
        fixedHeader={true}
        subHeaderComponent={
          <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
            <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
              <h1 className='datatable-title-head'>List Customers</h1>
            </div>
            <div className='search-input'>
              <Input type='text' value={state.searchStr} onChange={(e) => {
                dispatch(changeSearchString({ str: e.target.value }))
                clearTimeout(timeOutId)
                const timeoutIdLocal = setTimeout(() => {
                  dispatch(GetCustomerData({
                    isLoading: true,
                    isRefreshing: true,
                    pageNo: 1,
                    rows: state.pageData.rowsPerPage,
                    str: e.target.value
                  }))
                }, 500)
                setTimeoutId(timeoutIdLocal)
              }} placeholder='Search' />
              <span className='search-icon'><Search /></span>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px', alignItems: 'center' }} >
              <ExportImportButton isImport={true} data={state.list} fileName='Mobile_users' sampleUrl="/download-excel-mobileuser" url='/api/admin/import-mobileuser-files' />
              <Button className='eat-btn' onClick={() => navigate('/list-customers/add')} >Add Mobile User</Button>
            </div>
          </div>
        }
        paginationDefaultPage={state.pageData.currentPage}
        responsive={true}
        paginationPerPage={state.pageData.rowsPerPage}
        columns={columns}     // filter data for column
        data={state.list}       //takes data
        highlightOnHover
      />
    </div>
  )

}

export default CustomerTable
