// ** React Imports
import { useState, Fragment, useEffect } from 'react'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody, Button, ListGroup, ListGroupItem, Row, Col } from 'reactstrap'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { File, Trash2 } from 'react-feather'

import { getImageCloudFlare, handleApiError, instance } from '../../../utility/Utils'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

const FeaturedImage = () => { 
  // ** State
  const {id} = useParams()
  const [files, setFiles] = useState()
  const [uploading, setUploading] = useState(false)
const [feturedId, setfeturedId] = useState()
  

const uploadImage = async(s) => {
    const newInstance = instance()
    try {
      setUploading(true)
      const j = await newInstance.post('/api/user/admin/uploadImage', {file : s}, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      await newInstance.post(`/api/user/admin/restaurant/featured-image/${id}`, {
        cafe_image_name: s.name,
        image_position: 1,
        server_image_id: j.data.id,
        is_featured: 1
      })
        setFiles(j.data.id)
        toast.success('Image uploaded successfully')
    } catch (err) {
          handleApiError(err)
    } finally {
      setUploading(false)
    }
}

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
      accept: {
        'image/*': ['.png', '.jpg']
      },
    onDrop: acceptedFiles => {
      if (acceptedFiles[0] && (acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/png")) {
        uploadImage(acceptedFiles[0])
      } else {
        toast.error('please select jpg or png format only')
      }
     }
    // ,
    // disabled: !!files
  })

  const handleRemoveFile = async() => {
    const newInstance = instance()
    try {
      await newInstance.delete(`/api/user/admin/restaurant/featured-image/${feturedId}`)
      // const uploadedFiles = files
      // const filtered = uploadedFiles.filter(i => i.id !== file.id)
       setFiles()
       setfeturedId()
    } catch (error) {
      handleApiError(error)
    }
  }

  useEffect(() => {
    const getCafeById = async() => {
      const newInstance = instance()
      try {
        const j = await newInstance.get(`/api/user/admin/restaurant-all-info/${id}`)
        
          const isFetured = j.data.gallery.filter((e) => e.is_featured === 1)
          // setFiles((j.data.gallery.find((e) => e.is_featured  === 1)).server_image_id)
          if (isFetured.length >= 1) {  
            setFiles(isFetured[0].server_image_id)
        setfeturedId(isFetured[0].id)
          }
      }  catch (err) {
        handleApiError(err)
       }
  }
  getCafeById()
  }, [])
  
  return (
    <>
    <Row className='mt-2 mb-3'>
            <Col md='8'>
                    <Card style={{background:'#E7ECF0', border:'none', boxShadow:'none', maxHeight:'300px', aspectRatio: ' 16 / 9 '}}>
                      <CardBody style={{cursor:'pointer'}}>
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()}   />
                          <div  style={{height:'257px', margin:'auto 0'}} className='d-flex align-items-center justify-content-center flex-column'>
                            <File className={uploading ? "refreshing" : ''} size={34} />
                            <h2 className='text-secondary mt-2'>
                              Upload Photo
                            </h2>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                    {files ? (
                          <Fragment>
                            <div className='file-preview me-1' style={{height:'100px', width:'fit-content', position:'relative'}}>
                            <img className='rounded' alt={files.name} src={getImageCloudFlare(files, process.env.REACT_APP_CLOUD_IMAGE_Medium_Extention)}  height='100' />
                                  <Button color='white' outline style={{ position:'absolute', top:'0', right:'0'}} size='sm' className='btn-icon' onClick={() => handleRemoveFile()}>
                                      <Trash2  color='white' size='14' />
                                  </Button>
                            </div>
                          </Fragment>
                        ) : null}
            </Col>
          </Row>
    </>
  )
}

export default FeaturedImage
