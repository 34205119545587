
import { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Input,
  Dropdown,
  Button,
  Label,
  InputGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
  Form
} from 'reactstrap'

import Select from 'react-select'
import { ArrowLeft, ChevronDown, Calendar } from 'react-feather'
import { NavLink, useNavigate } from 'react-router-dom'
import { getImageCloudFlare, handleApiError, instance } from '../../utility/Utils'
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'
// import toast from 'react-hot-toast'
import { City, State } from 'country-state-city'
import Swal from 'sweetalert2'
import { UploadFileLogo } from '../../@core/assets/fonts/feather/icons'
import toast from 'react-hot-toast'


const AddUserProfile = () => {
  const navigate = useNavigate()
  const [birthDay, setBirthDay] = useState(new Date().toLocaleDateString())
  const [saving, setsaving] = useState(false)
  const [data, setData] = useState({
    mobile_number: "",
    email: "",
    status: 1,
    gender: "",
    first_name: "",
    last_name: "",
    profile_pic_image_id: '',
    country:'India'
  })
  const [avtar, setAvtar] = useState('')
  const [loading, setLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [state, setState] = useState({})

  const toggleDropDown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const uploadImage = async (file) => {
    const newInstance = instance()
    try {
      setLoading(true)
      const j = await newInstance.post('/api/user/admin/uploadImage', { file }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      setAvtar(j.data.id)
      setData({ ...data, profile_pic_image_id: j.data.id })
    } catch (err) {
      handleApiError(err)
    }
    setLoading(false)
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    const newInstance = instance()
    try {
      setsaving(true)
      const j = await newInstance.post('/api/admin/create-mobile-user', { ...data, date_of_birth: new Date(birthDay) })
      Swal.fire({
        title: 'Great !',
        text: 'User added successfully',
        icon: 'success',
        color: '#01A879',
        confirmButtonColor: 'orange',
        confirmButtonText: "Close",
        buttonsStyling: false,
        iconColor: '#01A879',
        customClass: {
          title: 'title-sweetalert',
          text: 'title-sweetalert',
          confirmButton: 'eat-btn-sweetalert'
        }
      }
      )
      toast.success(j.data.msg)
      navigate('/list-customers')
    } catch (err) {
      handleApiError(err)
    } finally {
      setsaving(false)
    }
  }

  useEffect(() => {
    setState(State.getStatesOfCountry('IN').map((e) => {

      return {
        label: e.name,
        value: e.name,
        state_code: e.isoCode,
        country_code: e.countryCode
      }
    }))
  }, [])
  return (
    <>
      <div className='mb-2'>
        <h1 className='datatable-title-head'>  <NavLink to='/list-customers'  ><ArrowLeft color='#292D32' /></NavLink> Add Mobile User </h1>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col className="mt-1" style={{ textAlign: "center" }} md="2" sm="12">
              {/* <Avatar    color='info' content='LD'  size='xl' /> */}
              <Button tag={Label} color='none' style={{ borderRadius: '50%', background: 'transparent' }} size='sm' >
                <div style={{ width: '100px', height: '100px', background: '#E7ECF0', borderRadius: '50%', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
                  {loading ? <Spinner style={{ margin: 'Auto 0' }} color='primary' /> : avtar ? <img style={{ height: 'inherit' }} src={getImageCloudFlare(avtar, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention)} /> : <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      gap: '10%'
                    }}><UploadFileLogo /> <div>Upload Photo</div>
                  </div>
                  }

                </div>
                <Input type='file' onChange={(e) => uploadImage(e.target.files[0])} hidden accept='image/*' />
              </Button>
            </Col>
            <Col md="10" sm="12">
              <Row>
                <Col md='6' sm='12' className='mb-1'>
                  <Label className='form-label' for='first_name'>
                    First Name
                  </Label>
                  <Input type='text' name='first_name' value={data.first_name} id='first_name' onChange={handleInputChange} placeholder='First Name' />
                </Col>
                <Col md='6' sm='12' className='mb-1'>
                  <Label className='form-label' for='last_name'>
                    Last Name
                  </Label>
                  <Input type='text' name='last_name' value={data.last_name} id='last_name' onChange={handleInputChange} placeholder='Last Name' />
                </Col>
                <Col md='6' sm='12' className='mb-1'>
                  <Label className='form-label' for='mobile_number'>
                    Customer Phone <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input required type='tel' pattern="[0-9]{10}" title="Invalid Mobile Number." value={data.mobile_number} name='mobile_number' id='mobile_number' onChange={handleInputChange} placeholder='Phone no.' />
                </Col>
                <Col md='6' sm='12' className='mb-1'>
                  <Label className='form-label' for='email'>
                    Customer Email
                  </Label>
                  <Input type='email' value={data.email} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title='invalid email input' name='email' id='email' onChange={handleInputChange} placeholder='Email' />
                </Col>

                <Col md='6' sm='12' className='mb-1'>
                  <Label className='form-label' for='gender'>
                    Gender
                  </Label>
                  <Dropdown style={{ width: '100%' }} isOpen={dropdownOpen} toggle={toggleDropDown}>
                    <InputGroup  >
                      <DropdownToggle style={{ color: 'black', border: 'black', padding: '0', width: '100%' }} color='' caret outline>
                        <InputGroup className='input-group-merge'>
                          <Input style={{ background: 'white', cursor: 'pointer' }} type='gender' name='gender' value={data.gender} id='gender' placeholder='Select' disabled />
                          <InputGroupText >
                            <ChevronDown size={14} />
                          </InputGroupText>
                        </InputGroup>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => setData({ ...data, gender: 'Male' })}>Male</DropdownItem>
                        <DropdownItem onClick={() => setData({ ...data, gender: 'Female' })} >Female</DropdownItem>
                        <DropdownItem onClick={() => setData({ ...data, gender: 'Other' })}>Other</DropdownItem>
                      </DropdownMenu>
                    </InputGroup>
                  </Dropdown>
                </Col>

                <Col md='6'>
                  <Label id='datepicker'>
                    Date Of Birth
                  </Label>
                  <InputGroup className='input-group-merge'>
                    <Flatpickr
                      style={{ background: '#FFFFFF' }}
                      className='form-control'
                      placeholder={birthDay}
                      options={{ maxDate: new Date(), dateFormat: 'Y-m-d' }}
                      id='datepicker'
                      onChange={date => {
                        setBirthDay(date)
                      }}
                    />
                    <InputGroupText>
                      <Calendar size={14} />
                    </InputGroupText>
                  </InputGroup>
                </Col>
                <Col className='mb-1' md='6' sm='12'>
                  <Label className='form-label'>Country</Label>
                  <Select
                    placeholder='India'
                    // value={data.country}
                    value='IN'
                    isDisabled
                  />
                </Col>
                <Col className='mb-1' md='6' sm='12'>
                  <Label className='form-label'>State</Label>
                  <Select
                    isSearchable
                    closeMenuOnSelect
                    placeholder={data.state || 'Select'}
                    // defaultValue = {cafeOption.find((e) => e.value ===  1)}
                    // components={animatedComponents} 
                    options={state}
                    onChange={(e) => {
                      setData({ ...data, state: e.value, city: '' })
                    }}
                    className='react-select'
                    // isDisabled={!data.country}
                  // isDisabled={true}
                  />
                </Col>

                <Col className='mb-1' md='6' sm='12'>
                  <Label className='form-label'>City<span style={{ color: 'red' }}>*</span></Label>
                  <Select
                    value={!!data.city && { value: data.city, label: data.city }}
                    isSearchable
                    closeMenuOnSelect
                    placeholder={data.city || 'Select'}
                    // components={animatedComponents} 
                    required
                    options={
                      data.state ? State.getAllStates().find((e) => e.name === data.state) ? City.getCitiesOfState('IN',
                        State.getAllStates().find((e) => e.name === data.state).isoCode).map((c) => {
                          return {
                            label: c.name,
                            value: c.name
                          }
                        }) : [] : []
                    }
                    onChange={(e) => {
                      setData({ ...data, city: e.value })
                    }}
                    className='react-select'
                    isDisabled={!data.state}
                  // isDisabled={true}
                  />
                </Col>

                <Col md='12' className="d-flex p-1 eat-status mb-3">
                  <div >Status</div>
                  <div className='form-check form-switch ms-1'>
                    <Input type='switch' name='status' id='statu'
                      onChange={(e) => setData({ ...data, status: 0 + e.target.checked })}
                      checked={data.status} />
                  </div>
                </Col>
                <Col sm='12'>
                  <div className='d-flex'>
                    <Button className='me-1' outline onClick={() => navigate('/list-customers')} color="primary">
                      Cancel
                    </Button>
                    <Button className="eat-btn" type='submit'
                      disabled={saving}
                    >
                      Save {saving && <Spinner size='sm' />}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>

      </div>
    </>
  )
}

export default AddUserProfile
