// import { Col, Row } from "reactstrap"
import { useState, useEffect } from 'react'

import {
    Row,
    Col,
    Input,
    CardBody,
    Form,
    Button,
    Label,
    Spinner
  } from 'reactstrap'
  import { handleApiError, instance } from '../../../utility/Utils'
  import {useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

const EditSocialInfo = () => {
    const [saving, setsaving] = useState(false)
    
    const [data, setData] = useState({
        address_1: "",
        address_2:   "",
        average_rating :   0,
        average_ticket_size:   0,
        average_waiting_time:   "00:00:00",
        cafe_about:   "",
        cafe_list_id:   null,
        cafe_long_url:   "",
        cafe_name:   "",
        cafe_price_range:   0,
        cafe_short_url:   "",
        cafe_slogan:   "",
        city:   "",
        closing_time:   "00:00:00",
        country:   "",
        created_at:   "2022-11-05T14:14:55.000Z",
        currency:   "",
        email:   "",
        fssai_certificate_url :   "",
        id:   null,
        is_featured:   0,
        is_most_visited:   0,
        is_new_opening:   0,
        is_non_veg:   0,
        is_veg:   0,
        latitude:   0,
        logo_image_id:   "",
        longitude:   0,
        mobile_number:   null,
        most_ordered_menu_item_price_id:   null,
        most_used_payment_mode:   "",
        opening_time:   "00:00:00",
        pin_code:   null,
        social_facebook_url:   "",
        social_instagram_url:   "",
        social_linkedin_url:   "",
        social_twitter_url:   "",
        social_whatsapp_no:   null,
        starting_price:   null,
        state:   "",
        status:   0,
        total_item:   0,
        total_order:   0,
        total_review:   0,
        uid:   "",
        updated_at:   "2022-11-05T14:14:55.000Z",
        user_admin_id:   null,
        website_url: ""
    })
    const {id} = useParams()
    const getCafeById = async() => {
        const newInstance = instance()
        try {
          const j = await newInstance.get(`/api/user/admin/restaurant-all-info/${id}`
          )
          if (j.data.message[0]) {
            setData(j.data.message[0])
        }
        } catch (err) {
          handleApiError(err)
         }
    }
    const handleChange = (e) => {
        setData({...data, [e.target.name]:e.target.value})
    }
    const handleSubmit = async(e) => {
        e.preventDefault()
        const newInstance = instance()
        setsaving(true)
        try {
         const j = await newInstance.post(`/api/user/admin/restaurant-edit-socialinfo/${id}`,
          {
            website_url: data.website_url,
            social_facebook_url: data.social_facebook_url,
            social_instagram_url: data.social_instagram_url,
            social_twitter_url:data.social_twitter_url,
            social_linkedin_url:data.social_linkedin_url
          })

      toast.success(j.data.msg)
    } catch (err) {
    //   toast.error(err.response.data.msg)
      handleApiError(err)
     } finally {
      setsaving(false)
     }
    }
    useEffect(() => { 
        getCafeById() 
      }, [])
      
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Col md='10' className='mt-2'>     
                                <Row>
                                    <Col md='6' xs='12' className='mb-1'>
                                        <Label className='form-label' for='website_url'>
                                        Website URL
                                        </Label>
                                        <Input type='url' value={data.website_url || ""} name='website_url' id='website_url' onChange={handleChange} placeholder='https://theoracafe.com' />
                                    </Col>
                                </Row>
                                <Row>  
                                    <Col md='6' xs='12' className='mb-1'>
                                        <Label className='form-label' for='social_facebook_url'>
                                        Facebook URL
                                        </Label>
                                        <Input type='url' 
                                        // pattern={"http://www\.facebook\.com\/(.+)|https://www\.facebook\.com\/(.+)"}  
                                        value={data.social_facebook_url || ""}  name='social_facebook_url' id='social_facebook_url' onChange={handleChange} placeholder='https://www.facebook.com/TheOraCafe/' />
                                    </Col>
                                    <Col md='6' xs='12' className='mb-1'>
                                        <Label className='form-label' for='social_instagram_url'>
                                        Instagram URL
                                        </Label>
                                        <Input type='url' 
                                        // pattern={"http://www\.instagram\.com\/(.+)|https://www\.instagram\.com\/(.+)"}  
                                        value={data.social_instagram_url || ""}  name='social_instagram_url' id='social_instagram_url' onChange={handleChange} placeholder='https://www.instagram.com/TheOraCafe/' />
                                    </Col>
                                    <Col md='6' xs='12' className='mb-1'>
                                        <Label className='form-label' for='social_twitter_url'>
                                        Twitter URL
                                        </Label>
                                        <Input type='url' 
                                        // pattern={"http://www\.twitter\.com\/(.+)|https://www\.twitter\.com\/(.+)"}
                                        value={data.social_twitter_url || ""}  name='social_twitter_url' id='social_twitter_url' onChange={handleChange} placeholder='https://www.twitter.com/TheOraCafe/' />
                                    </Col>
                                    <Col md='6' xs='12' className='mb-1'>
                                        <Label className='form-label' for='social_linkedin_url'>
                                        Linkedin URL
                                        </Label>
                                        <Input type='url' 
                                        // pattern={"http://www\.linkedin\.com\/(.+)|https://www\.linkedin\.com\/(.+)"}  
                                        value={data.social_linkedin_url || ""}  name='social_linkedin_url' id='social_linkedin_url' onChange={handleChange} placeholder='https://www.linkedin.com/TheOraCafe/' />
                                    </Col>
                    </Row>
                    <Row>
                            <Col md='12' className='mt-3  mb-3'>
                                <Button className='me-1 eat-btn'  type='submit' disabled={saving}>
                                Save {saving && <Spinner size='sm'/>}
                              </Button>
                            </Col>
                    </Row>
                </Col>
            </Form>
        </>
    )
}

export default EditSocialInfo