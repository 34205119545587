import { useNavigate } from "react-router-dom"
// import Avatar from '@components/avatar'
import { ArrowLeft } from "react-feather"
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  Spinner
} from 'reactstrap'

import { useState, useEffect } from "react"
import {handleApiError, instance} from "../../utility/Utils"

// new 

import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Swal from "sweetalert2"
import toast from "react-hot-toast"

const CreateQR = () => {
const [saving, setsaving] = useState(false)
    const navigate = useNavigate()
    const [cafeOption, setCafeOptions] = useState([])
    const [data, setData] = useState({
      cafe_list_id: "",
      table_title: "",
      status: 0
    })
    
const animatedComponents = makeAnimated()
const handleSubmit = async() => {
  setsaving(true)
  const newInstance = instance()
  try {
    const j = await newInstance.post("/api/user/admin/create-qrcode", data)
    Swal.fire({
      title:'Great !',
      text:'QR Created Successfully',
      icon:'success',
      color: '#01A879',
      // confirmButtonText:'<button class="eats-btn"> ok </button>',
      confirmButtonColor:'orange',
      buttonsStyling:false,
      iconColor:'#01A879',
      customClass:{
        title:'title-sweetalert',
        text:'title-sweetalert',
        confirmButton: 'eat-btn-sweetalert'
      }
    }
    )
    navigate('/QR-management')
    toast.success(j.data.msg)
  } catch (err) {
    handleApiError(err)
   } finally {
    setsaving(false)
   }
}

useEffect(() => {
  const loadCafe = async() => {
      const newInstance = instance()
      try {
        const j = await newInstance.get("api/user/admin/cafe-list/get/all")
        const sortedData = j.data.data
        sortedData.sort((a, b) => {
          const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
          const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        setCafeOptions(sortedData.map((e) => {
          return {
            label : e.cafe_name,
            value : e.id
          }
        }))
      } catch (error) {
        handleApiError(error)
      }
}
loadCafe()
}, [])

    return (
        <>
        <div className="mb-2">
          <h1  className='datatable-title-head'>  <ArrowLeft className="icon-hover" onClick={() => navigate('/QR-management')}/> Create QR</h1>
        </div>
        <Row>                
            <Col className='mb-1' md='6' sm='12'>
                  <Label className='form-label'>Restaturant Name</Label>
                  <Select
                    // isClearable={true}
                    // theme={selectThemeColors}
                    closeMenuOnSelect
                    // defaultValue = {cafeOption.find((e) => e.value ===  1)}
                    components={animatedComponents}
                    options={cafeOption}
                    onChange={(e) => setData({...data, cafe_list_id:e.value})}
                    className='react-select'
                    // onFocus={loadCafe} 
                  />
              </Col>
              <Col md='6' sm='12' className='mb-1'>
                    <Label className='form-label' for='table_title'>
                    Table No.
                    </Label>
                    <Input type='text'  value={data.table_title} name='table_title' id='table_title' 
                          onChange={(e) => setData({...data, table_title:e.target.value})}
                           placeholder='Table No.'
                     />
                </Col>
        </Row>
        <Row>
                    
             <Col className="d-flex p-1">
               <div style={{fontWeight:'bold'}}>Active</div>
               <div className='form-check form-switch ms-1'>
                  <Input type='switch' name='status' id='status' 
                    onChange={(e) => setData({...data, status:0 + e.target.checked})} 
                    checked={data.status}
                  />
                </div>
            </Col>
             
            <Col sm='12'>
                <div className='d-flex m-2'>
                  <Button outline  className='me-1' color='secondary' type='cancel' onClick={() => navigate('/QR-management')}>
                    Cancel
                  </Button>
                  <Button className='me-1 eat-btn' onClick={handleSubmit} disabled={!(data.cafe_list_id &&  data.table_title) || saving}>
                      Save {saving && <Spinner size='sm'/>}
                  </Button>
                </div>
            </Col>
        </Row>
    </>
    )
}

export default CreateQR