// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { instanceV1, handleApiError } from "../../../utility/Utils"

export const GetUniversalItem = createAsyncThunk("GetUniversalItem", async ({
    pageNo = 1,
    rows = 10,
    str = ""
  }, thunkAPI) => {
    const newInstance = instanceV1()
    let response = null
    try {
        response = await newInstance.get(`/universal-item?s=${str}&pageno=${pageNo}&limits=${rows}`)
        return response.data.data
    } catch (err) {
        handleApiError(err)
        return thunkAPI.rejectWithValue(err)
    }
})

export const universalItem = createSlice({
    name: "universalItem",
    initialState: {
        list: [],
        isLoading: false,
        isRefreshing:false,
        isError: false,
        searchStr:"",
        pageData: {
            totalNumberOfPage: 0,
            count: 1,
            currentPage: 1,
            rowsPerPage: 10
        }
    },
    reducers: {
        changeSearchString: (state, {payload}) => {
            state.searchStr = payload.str
        }
    },
    extraReducers: (builder) => {
        builder.addCase(GetUniversalItem.fulfilled, (state, action) => {
            if (action.payload) {
                state.list = action.payload?.data
                state.isLoading = false
                state.isRefreshing = false
                state.isError = false
                state.pageData = {
                    currentPage:  action.payload.currentPage,
                    rowsPerPage: parseInt(action.payload.perPage),
                    totalNumberOfPage:  action.payload.total,
                    count:  action.payload.lastPage
                  }
            }
        })
        builder.addCase(GetUniversalItem.rejected, (state, err) => {
            state.isLoading = false
            state.isRefreshing =  false
            state.isError = true
            console.warn(err)
        })
        builder.addCase(GetUniversalItem.pending, (state, action) => {
            state.isLoading = action.meta.arg.isLoading
            state.isRefreshing =  action.meta.arg.isRefreshing
        })
    }
})

export const { changeSearchString } = universalItem.actions
export default universalItem.reducer
