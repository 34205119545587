import { useNavigate, useParams } from "react-router-dom"
import { ArrowLeft, X, Plus, Delete, Trash2 } from "react-feather"
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  Spinner
} from 'reactstrap'
import { useState, useEffect } from "react"
// import toast from "react-hot-toast"
import Swal from "sweetalert2"

import { getImageCloudFlare, handleApiError, instance, instanceV1 } from '../../../utility/Utils'
import CreatableSelect from 'react-select/creatable'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'
// import { getCafeSpecificMenuAddon } from "../../../utility/menu-functions/MenuFunctions"
import { Egg, NonVeg, NonVeg2, Veg, Veg2 } from "../../../@core/assets/fonts/feather/icons"
import { AvtarCustum } from "../../../UI/Models/CustumAvatar"
import toast from "react-hot-toast"
import { ReactSelectStyle } from "../../../utility/ReactSelectStyle/SelectStyle"
// import { selectThemeColors } from '@utils'

const EditMenu = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [saving, setsaving] = useState(false)
  const [data, setData] = useState({ status: 1, gst_rate: 5, spice_level: 0, food_type: 1 })
  const [cafeOption, setCafeOptions] = useState([])
  const [categoryOption, setCategoryOption] = useState([])
  const [itemOption, setItemOption] = useState([])
  const [addonOption, setAddonOption] = useState([])
  // const [count, setCount] = useState(1)
  const [addonData, setAddonData] = useState([{ addon_id: "", addon_price: "", addon_status: false }])
  const [variantData, setVariantData] = useState([{ variant_name: "", variant_price: "", variant_status: false }])

  const animatedComponents = makeAnimated()


  const handleAddonPriceChange = (e, index) => {
    const val = [...addonData]
    val[index] = { ...val[index], addon_price: e.target.value }
    setAddonData(val)

  }


  const handleSubmit = async () => {
    setsaving(true)
    if (!data.cafe_list_id || !data.universal_menu_category_id || !data.universal_menu_item_id || !data.base_price) {
      toast.error("Please Fill All required fields")
      setsaving(false)
      return
    }
    if (data.is_addon_compulsory && (addonData.length && !addonData[0]?.addon_name)) {
      toast.error("Addon is compulsory")
      setsaving(false)
      return
    }
    if (data.is_variation_compulsory && (variantData.length && !variantData[0]?.variant_name)) {
      toast.error("Variant is compulsory")
      setsaving(false)
      return
    }
    const newInstance = instanceV1()
    try {
      await newInstance.put(`/cafe-menu-item/${id}`, {
        image_id: data.image_id,
        uni_item_name: data.item_name,
        status: data.status,
        uni_cat_name: data.category_name,
        food_type: data.food_type,
        description: data.description,
        base_price: data.base_price,
        gst_rate: data.gst_rate,
        measuring_unit: data.measuring_unit,
        spice_level: data.spice_level,
        is_exclusive: data.is_exclusive,
        is_recommended: data.is_recommended,
        is_chef_special: data.is_chef_special,
        is_new: data.is_new,
        is_seasonal: data.is_seasonal,
        is_signature: data.is_signature,
        is_addon_compulsory: data.is_addon_compulsory,
        is_variation_compulsory: data.is_variation_compulsory,
        is_takeaway: data.is_takeaway,
        cafe_list_id: data.cafe_list_id,
        items: addonData.filter(e => !!e.addon_name && !!e.addon_price),
        itemss: variantData.filter(e => !!e.variant_name && !!e.variant_price)
      }
      )

      Swal.fire({
        title: 'Great !',
        text: 'Menu Price modified successfully',
        icon: 'success',
        color: '#01A879',
        confirmButtonColor: 'orange',
        buttonsStyling: false,
        iconColor: '#01A879',
        customClass: {
          title: 'title-sweetalert',
          text: 'title-sweetalert',
          confirmButton: 'eat-btn-sweetalert'
        }
      }
      )
      navigate('/restaurant-menu')
    } catch (err) {
      handleApiError(err)
    } finally {
      setsaving(false)
    }
  }
  
  const cafeChangeHandler = async (e) => {
    setData({ ...data, cafe_list_id: e.value })
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleCheckChange = (e) => {
    setData({ ...data, [e.target.name]: parseInt(e.target.value) })
  }

  const getMenuData = async() => {
    try {
      const newInstance = instanceV1()
      const j = await newInstance.get(`/cafe-menu-item/${id}`)
      setData(j.data.allTables[0])
      setAddonData(j.data.allTables[0].addon_list.map((e) => {
        return { addon_name: e.addon_name, addon_price: e.addon_price, addon_status: e.status }
      }))
      setVariantData(j.data.allTables[0].menu_variant.map((e) => {
        return { variant_name: e.variant_name, variant_price: e.cafe_menu_variant_price, variant_status: e.status
         }
      }))
    } catch (error) {
      
    }
  }

  const loadCafe = async () => {
    const newInstance = instance()
    try {
      const j = await newInstance.get("api/user/admin/cafe-list/get/all")
      const sortedData = j.data.data
      sortedData.sort((a, b) => {
        const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
        const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })
      setCafeOptions(sortedData.map((e) => {
        return {
          label: e.cafe_name,
          value: e.id
        }
      }))
    } catch (error) {
      handleApiError(error)
    }
  }

  const loadCategory = async () => {
    const newInstance = instanceV1()
    try {
      const j = await newInstance.get("/search-cat")
      const sortedData = j.data.data
      sortedData.sort((a, b) => {
        const nameA = a.category_name.toUpperCase() // ignore upper and lowercase
        const nameB = b.category_name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })
      setCategoryOption(sortedData.map((e) => {
        return {
          label: e.category_name,
          value: e.category_name
        }
      }))
    } catch (error) {
      handleApiError(error)
    }
  }

  const loadItem = async () => {
    const newInstance = instanceV1()
    try {
      const j = await newInstance.get("/search-item")
      const sortedData = j.data.data
      sortedData.sort((a, b) => {
        const nameA = a.item_name.toUpperCase() // ignore upper and lowercase
        const nameB = b.item_name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })
      setItemOption(sortedData.map((e) => {
        return {
          label: e.item_name,
          value: e.item_name
        }
      }))
    } catch (error) {
      handleApiError(error)
    }
  }

  const loadAddon = async () => {
    const newInstance = instanceV1()
    try {
      const j = await newInstance.get("/search-addon")
      const sortedData = j.data.data
      sortedData.sort((a, b) => {
        const nameA = a.addon_name.toUpperCase() // ignore upper and lowercase
        const nameB = b.addon_name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })
      setAddonOption(sortedData.map((e) => {
        return {
          label: e.addon_name,
          value: e.addon_name
        }
      }))
    } catch (error) {
      handleApiError(error)
    }
  }

  useEffect(() => {
    getMenuData()
    loadCafe()
    loadCategory()
    loadItem()
    loadAddon()
  }, [])

  if (false) {
    cafeChangeHandler()
  }

  return (
    <>
      <div>
        <h1 className="mb-2 datatable-title-head">  <ArrowLeft className="icon-hover" onClick={() => navigate(-1)} /> Menu</h1>
      </div>
      <Row>
        <AvtarCustum src={data.image_id ? getImageCloudFlare(data.image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention) : ""}
          handleChange={(imageId) => setData({ ...data, image_id: imageId })}
        />

        <Row>
          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='restaurant-name'>
              Restaurant Name <span style={{ color: 'red' }}>*</span>
            </Label>
            <Select
              styles={ReactSelectStyle}
              // closeMenuOnSelect
              value={cafeOption.find((e) => e.value === data.cafe_list_id)}
              components={animatedComponents}
              options={cafeOption}
              onChange={cafeChangeHandler}
              className='react-select'
            />
          </Col>

          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='type'>
              Menu  Category <span style={{ color: 'red' }}>*</span>
            </Label>
            <CreatableSelect
              // isClearable 
              // isLoading={true}
              styles={ReactSelectStyle}
              value={categoryOption.find((e) => e.value === data.category_name)}
              options={categoryOption}
              onChange={(e) => setData({ ...data, category_name: e.value })}

            />
          </Col>

          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='type'>
              Menu Item <span style={{ color: 'red' }}>*</span>
            </Label>
            <CreatableSelect
              // closeMenuOnSelect
              components={animatedComponents}
              styles={ReactSelectStyle}
              value={itemOption.find((e) => e.value === data.item_name)}
              options={itemOption}
              onChange={(e) => setData({ ...data, item_name: e.value })}
              className='react-select'
            />
          </Col>

        </Row>

        <Row>
          <Col md='12' xs='12' className='mb-1'>
            <Label className='form-label' for='nameMulti'>
              Description
            </Label>
            <Input type='textarea'
              name='description' id='description'
              style={{ minHeight: '100px' }}
              value={data.description || ""} onChange={handleChange}
              placeholder='
              "Amazing place to hangout with your friends and family , loved the dishes served"
              ' />
          </Col>
        </Row>
        <Row>
          <Col md='12' className='mt-2 mb-2'>
            Food Type  <span style={{ color: 'red' }}>*</span>
          </Col>
          <Col md='12' className="d-flex align-items-center gap-1" >

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='radio' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={1} name='food_type' id='is_veg'
                onChange={handleCheckChange}
                checked={data.food_type === 1} />
              <Label style={{ fontWeight: 'bolder' }}
                for='is_veg'
                className='form-check-label'>
                <Veg2 /> veg
              </Label>
            </div>

            <div className='form-check form-check-inline d-flex align-items-center'>
              <Input type='radio' name="food_type"
                style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                id="is_egg"
                value={2}
                onChange={handleCheckChange} checked={data.food_type === 2} />
              <Label style={{ fontWeight: 'bolder' }}
                for="is_egg"
                className='form-check-label'>
                <Egg /> Egg
              </Label>
            </div>

            <div className='form-check form-check-inline d-flex align-items-center'>
              <Input type='radio' name="food_type"
                style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                id="is_non_veg"
                onChange={handleCheckChange}
                value={0}
                checked={data.food_type === 0} />
              <Label style={{ fontWeight: 'bolder' }}
                for="is_non_veg"
                className='form-check-label'>
                <NonVeg2 /> Non-veg
              </Label>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='restaurant-name'>
              Base Price(₹)  <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input type='number' name='base_price' value={data.base_price || ''} onChange={(e) => setData({ ...data, base_price: e.target.value })} placeholder='' />
          </Col>

          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='type'>
              GST Rate <span style={{ color: 'red' }}>*</span>
            </Label>
            <Select
              styles={ReactSelectStyle}
              // closeMenuOnSelect
              components={animatedComponents}
              options={[
                { label: '0%', value: 0 },
                { label: '5%', value: 5 },
                { label: '12%', value: 12 },
                { label: '18%', value: 18 }
              ]}
              value={[
                { label: '0%', value: 0 },
                { label: '5%', value: 5 },
                { label: '12%', value: 12 },
                { label: '18%', value: 18 }
              ].find(d => d.value === data.gst_rate) || {}}
              onChange={(e) => setData({ ...data, gst_rate: e.value })}
              className='react-select'
            />
          </Col>

          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='type'>
              Measuring Unit
            </Label>
            <Select
              styles={ReactSelectStyle}
              // closeMenuOnSelect
              components={animatedComponents}
              value={[
                { label: 'litre', value: 'litre' },
                { label: 'cms', value: 'cms' },
                { label: 'pieces', value: 'pieces' },
                { label: 'grams', value: 'grams' },
                { label: 'kg(s)', value: 'kg(s)' },
                { label: 'ounces', value: 'ounces' },
                { label: 'Serves', value: 'Serves' },
                { label: 'Slice(s)', value: 'Slice(s)' },
                { label: 'Scoop(s)', value: 'Scoop(s)' },
                { label: 'inches', value: 'inches' },
                { label: 'ml', value: 'ml' }
              ].find(() => true) || {}}
              options={[
                { label: 'litre', value: 'litre' },
                { label: 'cms', value: 'cms' },
                { label: 'pieces', value: 'pieces' },
                { label: 'grams', value: 'grams' },
                { label: 'kg(s)', value: 'kg(s)' },
                { label: 'ounces', value: 'ounces' },
                { label: 'Serves', value: 'Serves' },
                { label: 'Slice(s)', value: 'Slice(s)' },
                { label: 'Scoop(s)', value: 'Scoop(s)' },
                { label: 'inches', value: 'inches' },
                { label: 'ml', value: 'ml' }
              ]}
              onChange={(e) => setData({ ...data, measuring_unit: e.value })}
              className='react-select'
            />
          </Col>

        </Row>
        <Row>
          <Col md='12' className='mt-2 mb-2'>
            Spice Level <span style={{ color: 'red' }}>*</span>
          </Col>
          <Col md='12' className="d-flex align-items-center gap-1" style={{ flexWrap: 'wrap' }} >

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='radio' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={0} name='spice_level' id='spice_none'
                onChange={handleCheckChange}
                checked={data.spice_level === 0} />
              <Label style={{ fontWeight: 'bolder' }}
                for='spice_none'
                className='form-check-label'>
                None
              </Label>
            </div>

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='radio' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={1} name='spice_level' id='medium-spicy'
                onChange={handleCheckChange}
                checked={data.spice_level === 1} />
              <Label style={{ fontWeight: 'bolder' }}
                for='medium-spicy'
                className='form-check-label'>
                Medium Spicy
              </Label>
            </div>

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='radio' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={2} name='spice_level' id='mild-spicy'
                onChange={handleCheckChange}
                checked={data.spice_level === 2} />
              <Label style={{ fontWeight: 'bolder' }}
                for='mild-spicy'
                className='form-check-label'>
                Mild Spicy
              </Label>
            </div>

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='radio' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={3} name='spice_level' id='very-spicy'
                onChange={handleCheckChange}
                checked={data.spice_level === 3} />
              <Label style={{ fontWeight: 'bolder' }}
                for='very-spicy'
                className='form-check-label'>
                Very Spicy
              </Label>
            </div>


          </Col>
        </Row>
        <Row>
          <Col md='12' className='mt-2 mb-2'>
            Tags
          </Col>
          <Col className="d-flex" style={{ flexWrap: 'wrap' }} >
            <div className={data.is_takeaway ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_takeaway: !data.is_takeaway })}>
              Takeaway
            </div>
            <div className={data.is_exclusive ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_exclusive: !data.is_exclusive })}>
              Exclusive offer
            </div>
            <div className={data.is_recommended ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_recommended: !data.is_recommended })}>
              Recommended
            </div>
            <div className={data.is_signature ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_signature: !data.is_signature })}>
              Signature
            </div>
            <div className={data.is_seasonal ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_seasonal: !data.is_seasonal })}>
              Seasonal
            </div>
            <div className={data.is_new ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_new: !data.is_new })}>
              New
            </div>
            <div className={data.is_chef_special ? "pill-active" : "pill"} onClick={() => setData({ ...data, is_chef_special: !data.is_chef_special })}>
              Chef’s special
            </div>
          </Col>
        </Row>

        <Row>
          <Col md='12' className='mt-2 mb-2'>
            <small>
              Do you want to make these option compulsory for user?
            </small>
          </Col>
          <Col md='12' className="d-flex align-items-center gap-1" style={{ flexWrap: 'wrap' }}>

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='checkbox' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={0} name='is_addon_compulsory' id='is_addon_compulsory'
                onChange={(e) => setData({ ...data, is_addon_compulsory: e.target.checked })}
                checked={data.is_addon_compulsory || 0} />
              <Label style={{ fontWeight: 'bolder' }}
                for='is_addon_compulsory'
                className='form-check-label'>
                Is Addon Compulsory
              </Label>
            </div>

            <div className='form-check form-check-inline p-0 d-flex align-items-center'>
              <Input type='checkbox' style={{ cursor: 'pointer', margin: 'auto 0', marginRight: '0.5rem' }}
                value={0} name='is_variation_compulsory' id='is_variation_compulsory'
                onChange={(e) => setData({ ...data, is_variation_compulsory: e.target.checked })}
                checked={data.is_variation_compulsory || 0} />
              <Label style={{ fontWeight: 'bolder' }}
                for='is_variation_compulsory'
                className='form-check-label'>
                Is Variation compulsory
              </Label>
            </div>


          </Col>
        </Row>
        <Row >
          <Col md='12' className='mt-2 mb-2'>
            <span>
              Addons
            </span>
          </Col>


          {/* =====  Addons == */}
          {
            addonData.map((item, index) => {
              return (
                <div key={index}>
                  <Row className='justify-content-between align-items-center'>
                    <Col md="1">
                      <div className='form-check form-switch'>
                        <Label className='form-label' style={{ visibility: "hidden", opacity: 0 }}>status
                        </Label>
                        <Input type='switch' name='customSwitch'
                          checked={addonData[index].addon_status}
                          onChange={(e) => setAddonData((prev) => {
                            const val = [...prev]
                            val[index] = { ...val[index], addon_status: e.target.checked }
                            return val
                          })} />
                      </div>
                    </Col>
                    <Col md={5} className='mb-md-0 mb-1'>
                      <Label className='form-label'>
                        Select Addon <span style={{ color: 'red' }}>*</span>
                      </Label>

                      <Select
                        styles={ReactSelectStyle}
                        // closeMenuOnSelect
                        options={addonOption}
                        value={addonOption.find(d => d.value === addonData[index].addon_name) || {}}
                        onChange={(e) => setAddonData((prev) => {
                          const val = [...prev]
                          val[index] = { ...val[index], addon_name: e.value }
                          return val
                        })}
                        className='react-select'
                        required={true}
                      />
                    </Col>
                    <Col md={5} className='mb-md-0 mb-1'>
                      <Label className='form-label' >
                        Price <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <input
                        placeholder='$32'
                        type="number"
                        min={0}
                        value={addonData[index].addon_price}
                        // defaultValue={addonData[index].addon_price || 0}
                        onChange={(e) => handleAddonPriceChange(e, index)}
                        className='form-control'
                      />
                    </Col>
                    <Col md={1}>

                      <Label className='form-label' style={{ visibility: "hidden", opacity: 0 }}>delete
                      </Label>
                      <Trash2 color="red" size={20} style={{ cursor: "pointer" }} onClick={() => {
                        setAddonData((prev) => {
                          const dataList = [...prev]
                          dataList.splice(index, 1)
                          return dataList
                        })
                      }} />
                    </Col>
                  </Row>
                </div>
              )
            })
          }

          <Row className='mt-1'>
            <Col md="10" >
              <Row>
                <Col md='10' sm='12' style={{ display: 'flex', justifyContent: 'center' }} >
                  <Col style={{ border: "dashed 2px #E0E3E7", padding: '0.5rem', borderRadius: '12px' }}>
                    <Button color='#0057FF' size='sm'
                      style={{ background: '#F3F3F3', marginRight: '0.5rem' }}
                      onClick={() => setAddonData((prev) => [...prev, { addon_id: "", addon_price: "", addon_status: false }])}>
                      <Plus size={24} color='brown' fill="#0057FF"
                        style={{ fontWeight: '700' }}
                        className='me-25'></Plus>
                    </Button>
                    <span className='align-middle'>Add New Addon  </span>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>

        {/* =====  Variants == */}
        <Row className="mt-2">
          <Col md='12' className='mt-2 mb-2'>
            <span>
              Variant
            </span>
          </Col>
          {
            variantData.map((item, index) => {
              return (
                <div key={index}>
                  <Row className='justify-content-between align-items-center'>
                    <Col md="1">
                      <div className='form-check form-switch'>
                        <Label className='form-label' style={{ visibility: "hidden", opacity: 0 }}>status
                        </Label>
                        <Input type='switch' name='customSwitch'
                          checked={variantData[index].variant_status}
                          onChange={(e) => setVariantData((prev) => {
                            const val = [...prev]
                            val[index] = { ...val[index], variant_status: e.target.checked }
                            return val
                          })} />
                      </div>
                    </Col>
                    <Col md={5} className='mb-md-0 mb-1'>
                      <Label className='form-label'>
                        Variant Name <span style={{ color: 'red' }}>*</span>
                      </Label>

                      <Input
                        // closeMenuOnSelect
                        // options={addonOption}
                        value={variantData[index].variant_name}
                        onChange={(e) => setVariantData((prev) => {
                          const val = [...prev]
                          val[index] = { ...val[index], variant_name: e.target.value }
                          return val
                        })}
                        className='react-select'
                        required={true}
                      />
                    </Col>
                    <Col md={5} className='mb-md-0 mb-1'>
                      <Label className='form-label' >
                        Price <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <input
                        placeholder='$32'
                        type="number"
                        value={variantData[index].variant_price}
                        onChange={(e) => {
                          const val = [...variantData]
                          val[index] = { ...val[index], variant_price: e.target.value }
                          setVariantData(val)
                        }}
                        className='form-control'
                      />
                    </Col>
                    <Col md={1}>

                      <Label className='form-label' style={{ visibility: "hidden", opacity: 0 }}>delete
                      </Label>
                      <Trash2 color="red" size={20} style={{ cursor: "pointer" }} onClick={() => {
                        setVariantData((prev) => {
                          const dataList = [...prev]
                          dataList.splice(index, 1)
                          return dataList
                        })
                      }} />
                    </Col>
                  </Row>
                </div>
              )
            })
          }

          <Row className='mt-1'>
            <Col md="10" >
              <Row>
                <Col md='10' sm='12' style={{ display: 'flex', justifyContent: 'center' }} >
                  <Col style={{ border: "dashed 2px #E0E3E7", padding: '0.5rem', borderRadius: '12px' }}>
                    <Button color='#0057FF' size='sm'
                      style={{ background: '#F3F3F3', marginRight: '0.5rem' }}
                      onClick={() => setVariantData((prev) => [...prev, { variant_id: "", variant_price: "", variant_status: false }])}
                    >
                      <Plus size={24} color='brown' fill="#0057FF"
                        style={{ fontWeight: '700' }}
                        className='me-25'></Plus>
                    </Button>
                    <span className='align-middle'>Add New Variant  </span>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>


        </Row>

        {/* // =============== */}


        <Row className="d-flex p-1">
          <div style={{ fontWeight: 'bold' }}>Active</div>
          <div className='form-check form-switch ms-1'>
            <Input type='switch' checked={data.status} name='customSwitch' onChange={(e) => setData({ ...data, status: e.target.checked + 0 })} id='exampleCustomSwitch' />
          </div>
        </Row>
        <Col className="mb-3" sm='12'>
          <div className='d-flex' style={{ marginTop: '10px' }}>

            <Button outline className='me-1' color='secondary' type='cancel' onClick={() => navigate('/restaurant-menu')}>
              Cancel
            </Button>
            <Button className='me-1 eat-btn' type='input'
              disabled={saving}
              onClick={() => {
                handleSubmit()
              }}
            >
              Save {saving && <Spinner size='sm' />}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default EditMenu
