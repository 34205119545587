import { ArrowDown, ArrowLeft, ChevronDown, File } from "react-feather"
import React, { useState, useEffect } from 'react'
import { handleApiError, instance } from "../../utility/Utils"
import {
  Row,
  Col,
  Input,
  Dropdown,
  Card,
  CardBody,
  Form,
  Button,
  Label,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  Spinner
} from 'reactstrap'

// import defaultAvatar from "@src/assets/images/portrait/small/Admin.png"
// ** Styles
import '@styles/react/pages/page-authentication.scss'
import { useNavigate, useParams } from "react-router-dom"
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import toast from "react-hot-toast"
import PdfInput from "../../UI/PdfInput"
import IconInput from "../../UI/IconInput"
import { ReactSelectStyle } from "../../utility/ReactSelectStyle/SelectStyle"

const EditEmployee = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [cafeOption, setCafeOptions] = useState([])
  const [uploading3, setuploading3] = useState(false)
  const [data, setData] = useState({
    user_role_id: "",
    first_name: "",
    last_name: "",
    cafe_list_id: "",
    mobile_number: "",
    email: "",
    photo_proof_id_url: "",
    address_proof_id_url: "",
    profile_pic_image_id: "",
    status: 0
  })
  // const [avtar, setAvtar] = useState()
  const [basicModal, setBasicModal] = useState(false)

  const animatedComponents = makeAnimated()

  const roles = [
    // {label:'Super admin', value:1},
    { label: 'Restaurant Owner', value: 2 },
    { label: 'Kitchen', value: 3 },
    { label: 'Captian', value: 4 }
    // {label:'Customer', value:5}
  ]

  const inputChengeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const newInstance = instance()
    toast.dismiss()
    setuploading3(true)
    try {
      await newInstance.put(`/api/user/admin/update-employee/${id}`, data)
      toast.success('Employee Data Updated')
      navigate('/list-empolyees')
    } catch (error) {
      handleApiError(error)
      // toast.dismiss()
      // error.response.data ? toast.error(error.response.data.msg) : toast.error(error.message)
      console.warn(error)
    } finally {
      setuploading3(false)
    }
  }

  useEffect(() => {
    const loadCafe = async () => {
      const newInstance = instance()
      try {
        const k = await newInstance.get(`/api/user/admin/get-employee/${id}`)
        setData(k.data.data)
        const j = await newInstance.get("api/user/admin/cafe-list/get/all")
        const sortedData = j.data.data
        sortedData.sort((a, b) => {
          const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
          const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        setCafeOptions(sortedData.map((e) => {
          return {
            label: e.cafe_name,
            value: e.id
          }
        }))
      } catch (error) {
        handleApiError(error)
      }
    }
    loadCafe()
  }, [])


  return (
    <>
      <div>
        <Modal className='modal-dialog-centered  modal-lg' isOpen={basicModal} toggle={() => setBasicModal(!basicModal)}>
          <ModalHeader toggle={() => setBasicModal(!basicModal)}> <p className="truncate" style={{ width: '' }}>{document}</p></ModalHeader>
          <iframe style={{ width: '100%', height: '100vh' }} src={`https://docs.google.com/gview?url=${document}&embedded=true`} />
        </Modal>
      </div>

      <div>
        <h1 className="mb-2 datatable-title-head">  <ArrowLeft className="icon-hover" onClick={() => navigate(-1)} /> Edit </h1>
      </div>
      <Card className="mt-3 " style={{boxShadow:"none"}}>

        <CardBody style={{padding:"0"}}>
          <Form style={{ display: 'flex' }} onSubmit={handleSubmit} >
            <IconInput data={{ imgId: data.profile_pic_image_id }} onChange={(e) => setData({ ...data, profile_pic_image_id: e })} />

            <Row>
              <Col md='6' sm='12' className='mb-1'>
                <Label className='form-label' for='first_name'>
                  First Name <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input required type='text' value={data.first_name} name='first_name' id='first_name' onChange={inputChengeHandler} placeholder='First Name' />
              </Col>
              <Col md='6' sm='12' className='mb-1'>
                <Label className='form-label' for='last_name'>
                  Last Name
                </Label>
                <Input type='text' value={data.last_name} name='last_name' id='last_name' onChange={inputChengeHandler} placeholder='Last Name' />
              </Col>
              <Col md='6' sm='12' className='mb-1'>
                <Label className='form-label' for='restaurant-name'>
                  Restaurant Name <span style={{ color: 'red' }}>*</span>
                </Label>
                <Select
                  styles={ReactSelectStyle}
                  closeMenuOnSelect
                  // defaultValue = {cafeOption.find((e) => e.value ===  1)}
                  components={animatedComponents}
                  value={cafeOption[cafeOption.indexOf(cafeOption.find((e) => e.value === data.cafe_list_id))]}
                  options={cafeOption}
                  onChange={(e) => setData({ ...data, cafe_list_id: e.value })}
                  className='react-select'
                />
                {/* <Input type='text' name='restaurantName' id='restaurant-name' placeholder='Restaurant Name' /> */}
              </Col>
              <Col md='6' sm='12' className='mb-1'>
                <Label className='form-label' for='role'>
                  Employee Role <span style={{ color: 'red' }}>*</span>
                </Label>
                <Select
                  styles={ReactSelectStyle}
                  closeMenuOnSelect
                  // defaultValue = {cafeOption.find((e) => e.value ===  1)}
                  components={animatedComponents}
                  value={roles[roles.indexOf(roles.find((e) => e.value === data.user_role_id))]}
                  options={roles}
                  onChange={(e) => setData({ ...data, user_role_id: e.value })}
                  className='react-select'
                />
              </Col>
              <Col md='6' sm='12' className='mb-1'>
                <Label className='form-label' for='Email'>
                  Employee Email <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input required type='email' value={data.email}  title='invalid email input' name='email' id='Email' placeholder='Email' onChange={inputChengeHandler} />
              </Col>

              <Col md='6' sm='12' className='mb-1'>
                <Label className='form-label' for='mobile_number'>
                  Employee Phone <span style={{ color: 'red' }}>*</span>
                </Label>
                <InputGroup>
                  <InputGroupText>
                    +91
                  </InputGroupText>
                  <Input required type='tel' pattern="[0-9]{10}" title="Invalid Mobile Number." value={data.mobile_number} name='mobile_number' id='mobile_number' onChange={inputChengeHandler} placeholder='0000000000' />
                </InputGroup>
              </Col>

              <Label>Photo Proof ID (PDF only)</Label>
              <Row>
                <Col md='10' sm='10' className='mb-1'>
                  <PdfInput data={{ url: data.photo_proof_id_url }} onChange={(e) => setData({ ...data, photo_proof_id_url: e })} />
                </Col>

              </Row>

              <Label>Address Proof ID (PDF only)</Label>
              <Row>
                <Col md='10' sm='10' className='mb-1'>
                  <PdfInput data={{ url: data.address_proof_id_url }} onChange={(e) => setData({ ...data, address_proof_id_url: e })} />

                </Col>

              </Row>

              <Col md='12' className="d-flex p-1 align-items-center mb-3">
                <div className='eat-status'>Status</div>
                <div className='form-check form-switch ms-1'>
                  <Input type='switch' name='status' id='statu'
                    onChange={(e) => setData({ ...data, status: 0 + e.target.checked })}
                    checked={data.status || 0} />
                </div>
              </Col>

              <Col sm='12' className="mt-1">
                <div className='d-flex'>

                  <Button outline className='me-1' color='secondary' onClick={() => navigate('/list-empolyees')} type='cancel'>
                    Cancel
                  </Button>
                  <Button style={{ display: 'flex', flexDirection: 'row' }} className='me-1 eat-btn' type='submit' disabled={uploading3 || !data.cafe_list_id || !data.user_role_id}>
                    Save &nbsp;{uploading3 && <Spinner size='sm' />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}

export default EditEmployee
