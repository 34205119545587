import ReactPaginate from "react-paginate"
import { Input } from "reactstrap"
import Select from 'react-select'
import { ReactSelectStyle } from "../ReactSelectStyle/SelectStyle"

export const customStyles = (propStyle) => {

  return (
    {
      tableWrapper: {
        style: {
          height: "100%",
          // overflow: "hidden",
          ...propStyle?.tableWrapper
        }
      },
    rows: {
      style: {
        whiteSpace:'pre-line',
        paddingLeft:'1rem',
        ...propStyle?.rows
      }
  },
  headRow: {
    style: {
      paddingLeft:'1rem'
    }
  },
  subHeader: {
    style: {
      padding:'0'
    }
  },
  headCells: {
      style: {
          justifyContent: 'center',
          fontFamily: 'Inter',
          fontWeight: '600',
          fontSize: '0.75rem',
          lineHeight: '0.9375rem',
          letterSpacing: '1px',
          color: '#768DA9',
          whiteSpace:'pre-line',
          textTransform:'uppercase',
          textAlign: 'center',
          ...propStyle?.headCells
          // whiteSpace:'wrap'
      }
  },
  cells: {
      style: {
          justifyContent:'center',
          fontFamily: 'Inter',
          fontWeight: '500',
          fontSize: '0.875rem',
          // paddingLeft : '10px',
          paddingTop:'0.5',
          paddingBottom:'0.5',
          lineHeight: '1.3125rem',
          color: '#1A264E',
          textAlign:'center',
          userSelect:'none',
          ...propStyle?.cells
          // padding:'0'
      }
  }
    }
  )
  
}

export const CustomPagination = ({numberOfData, pageData, handlePerPage, handlePagination}) => {

  return (
    <div className='table-bottom' >
    <div style={{flexGrow:'1', padding:'1rem'}}>
      SHOWING {((pageData?.currentPage - 1) * pageData.rowsPerPage) + 1 } TO {((pageData?.currentPage - 1) * pageData.rowsPerPage) + numberOfData} of {pageData?.totalNumberOfPage} ENTRIES
      </div>
    <div style={{display:'flex', alignItems:'center'}}>
              <p style={{margin: '0', padding:'1rem'}}>Select no.</p>
            <div className=' align-items-center me-1 '>
              <Select
              styles={ReactSelectStyle}
              isSearchable={false}
              menuPlacement="top"
              // closeMenuOnSelect
              value={{label:`${pageData.rowsPerPage}`, value:pageData.rowsPerPage}}
              options={[
                {label:'10', value:10},
                {label:'25', value:25},
                {label:'50', value:50}
              ]}
              onChange={(e) => handlePerPage({
                target:{
                  value:e.value
                }
              })}
              className='react-select'
            />
              {/* <Input
                type='select'
                id='rows-per-page'
                value={pageData.rowsPerPage}
                onChange={handlePerPage}
                className='form-control ms-1 cursor-pointer custom-select'
                >
                <option value='10' >10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
              </Input> */}
            </div>
            
    </div>
              <ReactPaginate
                  nextLabel=''
                  breakLabel='...'
                  previousLabel=''
                  pageCount={pageData?.count || 1}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  activeClassName='active'
                  breakClassName='page-item'
                  pageClassName={'page-item'}
                  breakLinkClassName='page-link'
                  nextLinkClassName={'page-link'}
                  pageLinkClassName={'page-link'}
                  nextClassName={'page-item next'}
                  previousLinkClassName={'page-link'}
                  previousClassName={'page-item prev'}
                  onPageChange={page => handlePagination(page)}
                  forcePage={pageData?.currentPage !== 0 ? pageData?.currentPage - 1 : 0}
                  containerClassName={'pagination react-paginate justify-content-end p-1'}
                />
    </div>
  )
}
