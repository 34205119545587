import { useState } from "react"
import { ChevronDown, Download, Upload, X } from "react-feather"
import toast from "react-hot-toast"
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, UncontrolledButtonDropdown, Label } from "reactstrap"
import { exportToExcel, importExcel, handleApiError, instance } from "../utility/Utils"

import importLogo from "@src/assets/images/portrait/import-logo.png"
import { useDropzone } from 'react-dropzone'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { saveAs } from 'file-saver'


const ExportImportButton = (props) => {

  const [isModalOpen, setisModalOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [cafeOption, setCafeOptions] = useState([])
  const animatedComponents = makeAnimated()
  const [id, setid] = useState(null)
  const newInstance = instance()

  const getCafeList = async () => {

    try {
      const j = await newInstance.get('/api/user/admin/cafe-list/get/all')
      //  const optionAry = []
      setCafeOptions(
        j.data.data.map((e) => {
          return ({
            label: e.cafe_name,
            value: e.id
          })
        })
      )

    } catch (err) {
      handleApiError(err)
    }
  }


  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      //  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :'.xlxs', "application/vnd.ms-excel" : '.xls'
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ['.xlxs', ".xls"],
      "application/vnd.ms-excel": ['.xlxs', ".xls"]

    },
    noClick: true,
    onDrop: async (acceptedFiles) => {
      const excelRecord = [...files]
      if (false) {
        console.log(excelRecord)
      }
      if (props.url === '/api/admin/import-menu-files/' && !id) {
        toast.error('please select restaurant for import')
      } else {
        acceptedFiles.map(async (file) => {

          try {

            await importExcel(file, `${props.url}${id || ''}`)

            setFiles((files) => [acceptedFiles, ...files])

          } catch (error) {
            handleApiError(error)
          } finally {
            setisModalOpen(false)
          }
        })
      }
    }
  })
  const exportHandler = () => {
    if (importLogo) {

    }
    if ((props.data.length >= 1) && (props.data !== undefined)) {
      exportToExcel(props.data, props.fileName)
    } else {
      toast.error('there should data for export')
    }
  }
  const sampleSaver = async () => {
    if (props.sampleUrl) {
      try {
        await newInstance.get(props.sampleUrl, { responseType: 'arraybuffer' })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            saveAs(blob, `${props.sampleUrl && props.sampleUrl.split('-').pop()}.xlsx`)
          })
      } catch (err) {
        console.warn(err)
        handleApiError(err)
      }
    }
  }

  // console.log(props.url)
  return (<>
    <Modal fade={true} className='import-model-wrapper' isOpen={isModalOpen} toggle={() => {
      return (
        setid(''),
        setisModalOpen(!isModalOpen)
      )
    }
    }>
      <div className='import-model-mainarea'>
        <div className="import-model-head">

          <h2>Operations</h2>
          <i onClick={() => {
            return (
              setid(''),
              setisModalOpen(!isModalOpen)
            )
          }
          }><X /></i>

        </div>

        <div className='import-model-body'>
          <h2>Import Data</h2>
          <p>Download a <span style={{ color: '#F2473F', textDecoration: "underline", cursor: 'pointer' }} onClick={sampleSaver}
          >Sample xls file</span> and compare it to your to import file to
            ensure you have the perfact for the import
          </p>
          {props.url === '/api/admin/import-menu-files/' && <div style={{ width: '100%', textAlign: 'left' }}>
            <Label>Restaurant</Label>
            <Select
              styles={{
                control: (baseStyles) => {
                  return (
                    {
                      ...baseStyles,
                      borderRadius: '12px',
                      width: '100%'
                    })
                }
              }}
              defaultValue={{ label: "Ora cafe", value: 0 }}
              closeMenuOnSelect
              components={animatedComponents}
              options={cafeOption}
              value={cafeOption.filter((e) => e.value === id)}
              // onChange={(e) => setid(e.value)}
              onChange={(e) => {
                return (
                  setid(e.value)
                )
              }
              }
              className='react-select'
              onFocus={getCafeList}
            /></div>}
        </div>
        <Card className="import-model-dragarea">

          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <img src={importLogo} />
              <p>Drag and Drop your file here</p>
              <h3>OR</h3>
              <Button className="eat-btn" style={{ borderRadius: '16px' }} onClick={() => {
                if (props.url === '/api/admin/import-menu-files/' && !id) {
                  toast.error('please select restaurant for import')
                } else {
                  document.getElementById('input').click()
                }
                // console.log('s') 
              }}>Browse</Button>
            </div>
          </div>
        </Card>
      </div>

    </Modal>
    {/* <i className="export-file-logo"><ExportFileLogo/></i> */}
    <Input id='input' value='' style={{ display: 'none' }} type='file' onChange={async (e) => {
      if (e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.target.files[0].type === "application/vnd.ms-excel") {
        await importExcel(e.target.files[0], `${props.url}${id || ''}`)
        setisModalOpen(false)
      } else {
        toast.error('select excel file only')
      }
    }} accept='.xlsx,.xls' >
    </Input>

    <UncontrolledButtonDropdown>
      <DropdownToggle color='sucess' className="import-export-dropdown" caret outline  >
        <span className='align-middle ms-50'>Export/Import </span>
        <ChevronDown size={15} />
      </DropdownToggle>
      <DropdownMenu>
        {props.isImport &&
          <DropdownItem className='w-100' onClick={() => {
            setisModalOpen(true)
          }}>
            <Download size={15} />
            <span className='align-middle ms-50'>Import</span>

          </DropdownItem>}
        <DropdownItem className='w-100' onClick={exportHandler}>
          <Upload size={15} />
          <span className='align-middle ms-50'>Export</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>

  </>)

}

export default ExportImportButton