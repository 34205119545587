import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, MoreVertical, Search } from 'react-feather'

import { getImageCloudFlare } from "../../../utility/Utils"


import Avatar from "@components/avatar"

// import ReactPaginate from 'react-paginate'
// import { useNavigate } from 'react-router-dom'
import ExportImportButton from '../../../UI/ExportImport'
import { Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Label, Col, Spinner } from 'reactstrap'
import SearchInput from '../../../UI/SearchInput'
import { CustomPagination, customStyles } from '../../../utility/datatable-style/dataTableStyle'
import AddUniversalCategoryModal from '../../../UI/Models/AddUniversalCategoryModal'
import EditUniversalCategoryModal from '../../../UI/Models/editUniversalCategoryModal'
import { useDispatch, useSelector } from 'react-redux'
import { GetUniversalCategory, changeSearchString } from '../../../redux/pagination/menu/universalCategory'


const MenuCategory = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.universalCategory)
  const [timeOutId, setTimeoutId] = useState(null)

  const [modalData, setModalData] = useState({
    modal: '',
    payload: {}
  })

  const columns = [
    {
      name: 'S.NO.',
      selector: row => (((state.pageData.currentPage - 1) * state.pageData.rowsPerPage) + state.list.indexOf(row)) + 1,
      maxWidth: '10.5rem',
      minWidth: '63px'
    },
    {
      name: 'Category UID',
      selector: row => <span style={{ whiteSpace: 'break-spaces' }}>{row.uid ? `${row.uid}` : 'Creating...'}</span>,
      minWidth: '8rem',
      maxWidth: '12rem'
    },
    {
      name: 'Image',
      selector: row => {
        return (row.image_id ? <Avatar
          img={getImageCloudFlare(row.image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention)}
          imgHeight="40"
          imgWidth="40"
        // status="online"
        /> : <></>)
      },
      minWidth: '7rem',
      maxWidth: '8rem'
    },
    {
      name: 'Category Name',
      selector: row => row.category_name,
      minWidth: '16rem'
    },
    {
      name: 'NO. OF FOOD ITEMS',
      selector: row => row.total_items,
      minWidth: '11rem',
      maxWidth: "13rem"
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.status === 1) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Active
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Inactive
          </div>
        }
      },
      minWidth: "8rem",
      maxWidth: "9rem",
      allowOverflow: true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        return (
          <div className='d-flex'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1' tag='span'>
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag='a' onClick={() => setModalData({
                  modal: 'editUniversalCategory',
                  payload: {
                    id: row.id,
                    category_name: row.category_name,
                    image_id: row.image_id,
                    status: row.status
                  }
                })} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'> Edit</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      },
      maxWidth: "2.5rem",
      minWidth: "100px"
    }

  ]


  const getCategoryList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    clearTimeout(timeOutId)
    dispatch(GetUniversalCategory({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr
    }))
  }
  const refreshCategoryList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    clearTimeout(timeOutId)
    dispatch(GetUniversalCategory({
      isLoading: false,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr
    }))
  }


  useEffect(() => {
    getCategoryList()
  }, [])

  // ** Table
  return (
    <div className='custum-table-fix' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
      <AddUniversalCategoryModal isOpen={modalData.modal === "addUniversalCategory"} toggel={() => setModalData({
        modal: "",
        payload: {}
      })}
        onSuccess={refreshCategoryList} />
      <EditUniversalCategoryModal isOpen={modalData.modal === "editUniversalCategory"} payload={modalData?.payload} toggel={() => setModalData({
        modal: "",
        payload: {}
      })}
        onSuccess={refreshCategoryList} />

      <DataTable
        // title='Live Order'
        pagination
        paginationServer
        progressComponent={<Spinner color="primary"
          style={{
            height: '3rem',
            width: '3rem'
          }} />}
        progressPending={state.isLoading}
        sortIcon={<ChevronDown />}
        subHeader={true}
        fixedHeader={true}
        customStyles={customStyles()}
        subHeaderComponent={
          <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
            <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
              <h1 className='datatable-title-head'>Universal Category</h1>
              {/* <Input /> */}
            </div>
            <div className='search-input'>
              <Input type='text' value={state.searchStr} onChange={(e) => {
                dispatch(changeSearchString({ str: e.target.value }))
                clearTimeout(timeOutId)
                const timeoutIdLocal = setTimeout(() => {
                  dispatch(GetUniversalCategory({
                    isLoading: true,
                    isRefreshing: true,
                    pageNo: 1,
                    rows: state.pageData.rowsPerPage,
                    str: e.target.value
                  }))
                }, 500)
                setTimeoutId(timeoutIdLocal)
              }} placeholder='Search' />
              <span className='search-icon'><Search /></span>
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }} >

              <ExportImportButton isImport={true} data={state.list} fileName='menu_category_list' sampleUrl="/download-excel-menu" url={`/api/admin/import-menu-files/`} />

              <Button className='eat-btn' onClick={() => setModalData({
                modal: 'addUniversalCategory',
                payload: ''
              })}
              >Add Category</Button>
            </div>
          </div>
        }
        paginationDefaultPage={state.pageData.currentPage}
        paginationComponent={() => <CustomPagination
          pageData={state.pageData}
          numberOfData={state.list.length}
          handlePerPage={(e) => getCategoryList(e.target.value, 1)}
          handlePagination={(page) => getCategoryList(state.pageData.rowsPerPage, page.selected + 1)}  />}
        responsive={true}
        paginationPerPage={state.pageData.rowsPerPage}
        columns={columns}     // filter data for column
        data={state.list}       //takes data
        highlightOnHover
      />
    </div>
  )

}

export default MenuCategory
