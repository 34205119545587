
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'

import { ArrowLeft, ChevronDown } from 'react-feather'
// import QR from './../../assets/images/pages/QR.png'
import { NavLink, useParams } from 'react-router-dom'

import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { formatDateTimeFigma, handleApiError, instanceV1 } from '../../../utility/Utils'
import { Canciled, Ordered, Preparing, ReadyToServe, Refresh, SentToKitchen, Served } from '../../../@core/assets/fonts/feather/icons'
import toast from 'react-hot-toast'
import { customStyles } from '../../../utility/datatable-style/dataTableStyle'

const OrderHistoryDetails = () => {
  const { id } = useParams()
  const [data, setData] = useState([])
  const [details, setDetails] = useState([])
  const [payAmmount, setPayAmount] = useState(0)
  const [paying, setPaying] = useState(false)
  const [ModalData, setModalData] = useState({
    modal: '',
    payload: {}
  })

  const columns = [
    {
      name: 'S.No.',
      selector: (row) => details.indexOf(row) + 1,
      sortable: true,
      maxWidth: '100px',
      minWidth: '100px'
    },
    {
      name: 'UID',
      selector: row => <div style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{row.uid || "Generating..."}</div>,
      minWidth: '150px'
    },
    {
      name: 'Menu Item Name',
      selector: row => row.order_item_combo_name || row.order_item_name || '',
      minWidth: '200px'
    },
    {
      name: 'Price',
      selector: (row) => `₹ ${row.order_item_price + (row.addon_list || []).reduce((past, current) => {
        return past + (current.order_addon_price || 0)
      }, 0) || 0}`,
      width: '150px'
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.status === 1) {
          return <div style={{ background: '#EAFFFA', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            <Ordered /> Ordered
          </div>
        } else if (row.status === 2) {
          return <div style={{ background: 'rgba(255, 185, 86, 0.1)', color: '#FFB956', border: '0.5px solid #FFB956', ...style }} >
            <SentToKitchen /> Sent To Kitchen
          </div>
        } else if (row.status === 3) {
          return <div style={{ background: 'rgba(255, 86, 86, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            <Preparing /> Preparing
          </div>
        } else if (row.status === 4) {
          return <div style={{ background: '#E3F6FF', color: '#44C4FD', border: '0.5px solid #44C4FD', ...style }} >
            <ReadyToServe /> Ready To Serve
          </div>
        } else if (row.status === 5) {
          return <div style={{ background: '#E3F6FF', color: '#44C4FD', border: '0.5px solid #44C4FD', ...style }} >
            <Served /> Served
          </div>
        } else if (row.status === 0) {
          return <div style={{ background: 'rgba(255, 86, 86, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            <Canciled /> Cancelled
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            {row.status}
          </div>
        }
      },
      width: '150px'
    },
    // {
    //   name: 'QTY',
    //   selector: () => "Not aplicable",
    //   width: '200px'
    // },
    {
      name: 'Served By ',
      selector: (row) => `${(row.served_by || '')}`,
      width: '200px'
    },
    {
      name: 'Start Date/Time',
      selector: (row) => (row.book_date_time ? formatDateTimeFigma(row.book_date_time) : ''),
      width: '200px'
    },
    {
      name: 'Served Date/ Time',
      selector: (row) => (row.serve_date_time ? formatDateTimeFigma(row.serve_date_time) : ''),
      width: '200px'
    }

  ]
  const ExpandableTable = ({ data }) => {
    return (
      <div className='expandable-content p-2'>
        {data.status === 0 ? <p>
          <span className='fw-bold'>Cancel feedback :- </span> {data.cancel_feedback}
        </p> : <></>}
        <p>
          <span className='fw-bold'>Cooking instructions :- </span> {data.cooking_instruction}
        </p>
        {data.order_item_variant_name ? <p>
          <span className='fw-bold'>Variant Name :-  {data.order_item_variant_name} </span>
        </p> : <></>}
        {data.addon_list.length ? <p>
          <span className='fw-bold'>Addon Name :- </span> {data.addon_list.map((e, i) => <span key={i}>  {`${e.order_addon_name} = ₹ ${e.order_addon_price}`} </span>)}
        </p> : <></>}
      </div>
    )
  }
  const handleToggel = () => {
    setModalData({
      modal: "",
      payload: {}
    })
  }

  const loadHistoryById = async () => {
    const newInstance = instanceV1()
    try {
      const j = await newInstance.get(`/order-history/${id}`)
      setData(j?.data?.order_list[0])
      setDetails(j?.data?.order_list[0]?.cafe_order_item)
    } catch (error) {
      handleApiError(error)
    }
  }
  useEffect(() => {
    loadHistoryById()
  }, [])

  return (
    <>
      <Modal size='sm' isOpen={ModalData.modal === "payDue"} centered toggle={handleToggel}>
        <ModalHeader toggle={handleToggel}  ></ModalHeader>
        <ModalBody>
          <h3 className='header700-1 mb-1'>Update Due Amount</h3>

          <Row>
            <Col md='12' sm='12' className='mb-2'>
              <Label className='form-label' for='due-paying-ammount'>
                Enter Paid Amount <span style={{ color: "red" }} >*</span>
              </Label>
              <Input type='number' max={ModalData.payload.due_amount} name='due-paying-ammount' id='due-paying-ammount'
                value={payAmmount}
                onChange={(e) => {
                  setPayAmount(e.target.value)
                }}
                placeholder='Pay Due' autoFocus={true} required />
            </Col>
          </Row>
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <Button className='me-2' outline color="primary" onClick={handleToggel} >
              Cancel
            </Button>
            <Button className="eat-btn" onClick={
              async () => {
                const newInstance = instanceV1()
                try {
                  setPaying(true)
                  await newInstance.put("/complete-due", {
                    cafe_order_list_id: id,
                    amount: payAmmount
                  })
                  toast.success("Payment Data updated successfully")
                  handleToggel()
                  loadHistoryById()
                } catch (error) {
                  handleApiError(error)
                } finally {
                  setPaying(false)
                }
              }
            }
              disabled={paying} >
              Update {paying && <Spinner size='sm' />}
            </Button>
          </div>

        </ModalBody>
      </Modal>
      <h2 className='mb-2' > <NavLink style={{ color: '#292D32' }} to="/order-history"><ArrowLeft /></NavLink> Orders History Details</h2>
      <Row  >
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' >
            UID
          </Label>
          <Input type='text' name='uid' value={data.uid || 'Generating...'} title='uid' onChange={() => { }} placeholder='UID' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label'>
            Table ID
          </Label>
          <Input type='text' name='table-id' value={data.cafe_table_id || 'Generating...'} onChange={() => { }} placeholder='Table ID' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='costumer-name'>
            Customer Name
          </Label>
          <Input type='text' name='costumer-name' value={`${data.first_name || ''} ${data.last_name || ''}`} placeholder='Customer Name' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='cafe-name'>
            Restaurant Name
          </Label>
          <Input type='text' name='cafe-name' defaultValue={data.cafe_name || ''} placeholder='Restaurant Name' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='billing-ammount'>
            Billing Amount
          </Label>
          <Input type='text' name='billing-ammount' value={`₹  ${data.total_amount || 0}`} placeholder='Billing Amount' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='payment-mode'>
            Payment Method
          </Label>
          <Input type='text' name='payment-mode' defaultValue={data.payment_mode === 1 ? 'Cash' : data.payment_mode === 2 ? 'UPI' : data.payment_mode === 3 ? 'Credit/Debit Card' : data.payment_mode === 4 ? 'Wallet' : 'Others'} placeholder='Payment Method' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='discount-amount'>
            Discount (If any)
          </Label>
          <Input type='text' name='discount-amount' value={`₹ ${data.discount_amount || 0}`} placeholder='Discount' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='order-start-date-time'>
            Order Started at
          </Label>
          <Input type='text' name='order-start-date-time' defaultValue={formatDateTimeFigma(data.start_date_time) || ''} placeholder='Order Start Date/Time' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='odrder-end-date-time'>
            Order Completed at
          </Label>
          <Input type='text' name='odrder-end-date-time' defaultValue={formatDateTimeFigma(data.end_date_time) || ''} placeholder='Order Completed Date/Time' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for="approved-by" >
            Approved By
          </Label>
          <Input type='text' name='approved-by' value={`${data.approved_by || ""}`} onChange={() => { }} placeholder='Approved By' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          {/* <Label className='form-label' for='discount-amount'>
            Due Amount
          </Label> */}
          <div className='form-label' style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
            <div>Due Amount</div>
            {!(data.payment_status === 1) && false && <div tag="button" style={{ color: " #832A0E", fontSize: "0.75rem", fontWeight: "600", cursor: 'pointer' }} onClick={() => {
              setModalData({
                modal: 'payDue',
                payload: data
              })
              setPayAmount(data.due_amount)
            }}>Edit</div>}
          </div>
          <Input type='text' name='discount-amount' value={`₹ ${data.due_amount || 0}`} placeholder='due' disabled />
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='payment-status'>
            Payment Status
          </Label>
          <div name='payment-status' className='form-control disabled-div' style={{ padding: '0.371rem 0.5rem' }}>
            {
              (data.payment_status === 1) ? <div style={{ color: "#01a879", padding: '0.2rem 1rem', borderRadius: '20px', display: "flex", gap: "0.2rem", alignItems: "center" }} >
                <div style={{ borderRadius: "50%", background: "#01a879", width: '7px', height: "7px" }}></div> Paid
              </div> : (data.payment_status === 0) ? <div style={{ color: "#FF3030", padding: '0.2rem 1rem', borderRadius: '20px', display: "flex", gap: "0.2rem", alignItems: "center" }} >
                <div style={{ borderRadius: "50%", background: "#FF3030", width: '7px', height: "7px" }}></div> Unpaid
              </div> : (data.payment_status === 2) ? <div style={{ color: "#FFB956", padding: '0.2rem 1rem', borderRadius: '20px', display: "flex", gap: "0.2rem", alignItems: "center" }} >
                <div style={{ borderRadius: "50%", background: "#FFB956", width: '7px', height: "7px" }}></div> Partial Paid
              </div> : <div style={{ color: "#01a879", padding: '0.2rem 1rem', borderRadius: '20px', display: "flex", gap: "0.2rem", alignItems: "center" }} >
                <div style={{ borderRadius: "50%", background: "", width: '7px', height: "7px" }}></div> Payment Status
              </div>
            }
          </div>
        </Col>
        <Col md='3' sm='6' className='mb-1'>
          <Label className='form-label' for='order-status'>
            Status
          </Label>
          <div name='order-status' className='form-control disabled-div' style={{ padding: '0.371rem 1rem' }}>
            {data.order_status === 2 ? <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', padding: '0.2rem 1rem', borderRadius: '20px', width: 'max-content' }} >
              Complete
            </div> : data.order_status === 0 ? <div style={{ background: 'rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', padding: '0.2rem 1rem', borderRadius: '20px', width: 'max-content' }} >
              Cancelled
            </div> : data.order_status === 3 ? <div style={{ background: 'rgba(255, 185, 86, 0.10)', color: '#FFB956', border: '0.5px solid #FFB956', padding: '0.2rem 1rem', borderRadius: '20px', width: 'max-content' }} >
              Partially Completed
            </div> : <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', padding: '0.2rem 1rem', borderRadius: '20px', width: 'max-content' }} >
              Payment Mode
            </div>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <DataTable
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ExpandableTable}
          customStyles={customStyles()}
          sortIcon={<ChevronDown />}
          responsive={true}
          columns={columns}     // filter data for column
          data={details}       //takes data
          highlightOnHover
        />
      </Row>
    </>
  )

}

export default OrderHistoryDetails

