import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, MoreVertical, Search } from 'react-feather'

import { formatDateTimeFigma, useQuery } from "../../../utility/Utils"

import { useNavigate } from 'react-router-dom'
import {
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Spinner
} from 'reactstrap'
import ExportImportButton from '../../../UI/ExportImport'
import { CustomPagination, customStyles } from '../../../utility/datatable-style/dataTableStyle'
import { useDispatch, useSelector } from 'react-redux'
import { GetOrderHistory, changeSearchString } from '../../../redux/pagination/orderHistory'


const OrderHistory = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.orderHistory)
  const [searchDebounceId, setSearchDebounceId] = useState(null)

  const columns = [
    {
      name: 'S.NO.',
      selector: row => (((state.pageData.currentPage - 1) * state.pageData.rowsPerPage) + state.list.indexOf(row)) + 1,
      maxWidth: '7.5rem',
      minWidth: '63px'
    },
    {
      name: 'Order History UID',
      selector: row => <div style={{ textAlign: 'center', whiteSpace: 'break-spaces', margin: 'auto 3px' }}>#{row.uid}</div>,
      minWidth: '11rem',
      maxWidth: '14rem'
    },
    {
      name: 'CUSTMORE NAME',
      selector: row => `${row.first_name || ''} ${row.last_name || ''}`,
      minWidth: '13rem',
      maxWidth: "17rem"
    },
    {
      name: 'RESTAURANT NAME',
      selector: (row) => row.cafe_name,
      minWidth: '13rem',
      maxWidth: "17rem"
    },
    {
      name: 'BILLING AMOUNT',
      selector: (row) => ` ₹ ${row.total_amount || 0}`,
      minWidth: '10rem',
      maxWidth: '12rem'
    },
    {
      name: 'DATE TIME',
      selector: (row) => formatDateTimeFigma(row.end_date_time),
      width: '13rem',
      maxWidth: "17rem"
    },
    {
      name: 'PAYMENT METHOD',
      selector: (row) => { return row.payment_mode === 1 ? 'Cash' : row.payment_mode === 2 ? 'UPI' : row.payment_mode === 3 ? 'Credit/Debit Card' : row.payment_mode === 4 ? 'Wallet' : 'Others' },
      width: '160px'
    },
    {
      name: 'Payment Status',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px', display: "flex", gap: "0.2rem", alignItems: "center" }
        if (row.payment_status === 1) {
          return <div style={{ color: "#01a879", ...style }} >
            <div style={{ borderRadius: "50%", background: "#01a879", width: '7px', height: "7px" }}></div> Paid
          </div>
        } else if (row.payment_status === 0) {
          return <div style={{ color: "#FF3030", ...style }} >
            <div style={{ borderRadius: "50%", background: "#FF3030", width: '7px', height: "7px" }}></div> Unpaid
          </div>
        } else if (row.payment_status === 2) {
          return <div style={{ color: "#FFB956", ...style }} >
            <div style={{ borderRadius: "50%", background: "#FFB956", width: '7px', height: "7px" }}></div> Partial Paid
          </div>
        } else {
          return <div style={{ color: "#01a879", ...style }} >
            <div style={{ borderRadius: "50%", background: "", width: '7px', height: "7px" }}></div> {row.payment_status}
          </div>
        }
      },
      maxWidth: '13rem',
      minWidth: '10rem'
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.order_status === 2) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Complete
          </div>
        } else if (row.order_status === 0) {
          return <div style={{ background: 'rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Cancelled
          </div>
        } else if (row.order_status === 3) {
          return <div style={{ background: 'rgba(255, 185, 86, 0.10)', color: '#FFB956', border: '0.5px solid #FFB956', ...style }} >
            Partially Completed
          </div>
        } else {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            {row.order_status}
          </div>
        }
      },
      maxWidth: '15rem',
      minWidth: '13rem',
      allowOverflow: true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        return (
          <div className='d-flex'>
            <div className='d-flex'>
              <UncontrolledDropdown>
                <DropdownToggle className='pe-1' tag='span'>
                  <MoreVertical size={15} />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag='a' onClick={() => navigate(`/order-history/details/${row.id}`)} className='w-100' >
                    <Circle size={8} /><span className='align-middle ms-50'>View Details</span>
                  </DropdownItem>
                  <DropdownItem tag='a' onClick={() => navigate(`/order-history/invoice/${row.id}`)} className='w-100' >
                    <Circle size={8} /><span className='align-middle ms-50'> View Receipt</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        )
      },
      maxWidth:'2.5rem',
      minWidth:'100px'

    }

  ]


  const getOrderHistoryList = (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    dispatch(GetOrderHistory({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr,
      userId: query.get('id')
    }))
  }
  useEffect(() => {
    getOrderHistoryList()
  }, [])

  // ** Table
  return (
    <>
      <div  className='custum-table-fix data-table-custum-container' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>

        <DataTable
          pagination
          paginationServer
          sortIcon={<ChevronDown />}
          subHeader={true}
          customStyles={customStyles()}
          progressComponent={<Spinner color="primary"
            style={{
              height: '3rem',
              width: '3rem'
            }} />}
          progressPending={state.isLoading}
          fixedHeader={true}
          subHeaderComponent={
            <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
              <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
                <h1 className='datatable-title-head'>Orders History</h1>
              </div>
              <div className='search-input' style={{ width: 'auto' }}>
                <Input value={state.searchStr} onChange={(e) => {
                  clearTimeout(searchDebounceId)
                  const timer = setTimeout(() => {
                    dispatch(GetOrderHistory({
                      isLoading: true,
                      isRefreshing: true,
                      pageNo: 1,
                      rows: state.pageData.rowsPerPage,
                      str: e.target.value, //state.searchStr,
                      userId: query.get('id')
                    }))
                  }, 500)
                  setSearchDebounceId(timer)
                  dispatch(changeSearchString({ str: e.target.value }))
                }
                } />
                <span className='search-icon'><Search /></span>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px', alignItems: 'center' }} >
                <ExportImportButton isImport={true} data={state.list} fileName='Order_history' />
                {/* <Button className='eat-btn' onClick={ () => {} } >Customer Name <ChevronDown size={15} /> </Button> */}
              </div>
            </div>
          }
          paginationDefaultPage={state.pageData.currentPage}
          paginationComponent={() => <CustomPagination
            pageData={state.pageData}
            numberOfData={state.list.length}
            handlePerPage={(e) => getOrderHistoryList(parseInt(e.target.value), 1)}
            handlePagination={(page) => getOrderHistoryList(state.pageData.rowsPerPage, page.selected + 1)} />}
          responsive={true}
          paginationPerPage={state.pageData.rowsPerPage}
          columns={columns}     // filter data for column
          data={state.list}       //takes data
          highlightOnHover
        />
      </div>
    </>
  )

}

export default OrderHistory
