// ** React Imports
import { useState, Fragment, useEffect } from 'react'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody, Button, ListGroup, ListGroupItem, Row, Col } from 'reactstrap'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { FileText, File, Trash2 } from 'react-feather'
import {  instance, getImageCloudFlare, handleApiError } from '../../../utility/Utils'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

const ImageGallery = () => {
  // ** State
  const {id} = useParams()
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false)

  
  const getCafeById = async() => {
    const newInstance = instance()
    try {
      const j = await newInstance.get(`/api/user/admin/restaurant-all-info/${id}`
      )
      setFiles(j.data.gallery.filter(i => i.is_featured === 0))
    } catch (err) {
      handleApiError(err)
     }
}
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
      accept: {
        'image/*': ['.png', '.jpg']
      },
    onDrop: (acceptedFiles) => {
      let imgRecord = [...files]
      if (acceptedFiles[0] && (acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/png")) {
      
        const newInstance = instance()
        acceptedFiles.map(async(file) => {
         
         try {
           setUploading(true)
           const j = await newInstance.post('/api/user/admin/uploadImage', {file}, {
             headers: {
               "Content-Type": "multipart/form-data"
             }
           })
           const k = await newInstance.post(`/api/user/admin/restaurant/featured-image/${id}`, {
               cafe_image_name: file.name.slice(0, 30),
               server_image_id: j.data.id,
               is_featured: 0
           })
           imgRecord = [...imgRecord, k.data.data]
           setFiles([...imgRecord])
           toast.success('Image uploaded successfully')
           setUploading(false)
         } catch (error) {
           handleApiError(error)
           setUploading(false)
         }
       })
      } else {
        toast.error('please select jpg or png format only')
      }
      
    // ])
      // getCafeById()
    }
  })
  
  const handleRemoveFile = async(file) => {
    const newInstance = instance()
    try {
      await newInstance.delete(`/api/user/admin/restaurant/featured-image/${file.id}`)
      const uploadedFiles = files
      const filtered = uploadedFiles.filter(i => i.id !== file.id)
      setFiles([...filtered])
    } catch (error) {
      handleApiError(error)
    }
  }


  const renderFilePreview = file => {
    return (<div className='file-preview me-1' style={{height:'100px', aspectRatio:' 16 / 9 ', textAlign: 'center', borderRadius:'5px', position:'relative', background:'#80808038', overflow:'hidden'}}>
              <img className='rounded' alt={file.name} src={getImageCloudFlare(file.server_image_id, process.env.REACT_APP_CLOUD_IMAGE_Medium_Extention)}  height='100' />
              <Button color='white' outline style={{ position:'absolute', top:'0', right:'0'}} size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                  <Trash2  color='white' size='14' />
              </Button>
            </div>)
  }

  const fileList = files.map((file, index) => (
    <div key={`${file.name}-${index}`} style={{width: 'min-content', width:'fit-content', border:'none'}} className='d-flex align-items-center justify-content-between mb-1' >
          {renderFilePreview(file) }
    </div>
  ))

  useEffect(() => {
  getCafeById()
  }, [])

  return (
    <>
    <Row>
        <Col md='8' className='mt-2 mb-3'>
                <Card style={{background:'#E7ECF0', border:'none', boxShadow:'none', maxHeight:'300px', aspectRatio: ' 16 / 9 '}}>
                  <CardBody >
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <div  style={{height:'300px'}} className='d-flex align-items-center justify-content-center flex-column'>
                      <File className={uploading ? "refreshing" : ''} size={34} />
                        <p className='text-secondary m-2'>
                          Upload File
                        </p>
                      </div>
                    </div>

                  </CardBody>
                </Card>
                {files.length ? (
                      <Fragment>
                        <div  className='my-2 d-flex' style={{flexFlow:'wrap'}}>{fileList}</div>
                      </Fragment>
                    ) : null}
            </Col>
        </Row>
    </>
  )
}

export default ImageGallery
