// import { Refresh, SentToKitchen, Ordered, Preparing, ReadyToServe } from "../../@core/assets/fonts/feather/icons"
import { Card, Spinner, Modal, ModalHeader, Input, Form, Button, CardHeader, CardBody } from 'reactstrap'
import { AvtarCustum } from './CustumAvatar'
import { useState, useEffect } from 'react'
import { getImageCloudFlare, handleApiError, instanceV1 } from '../../utility/Utils'
// import { AvtarCustum } from "../../Component/AvtarCustum"

import Swal from "sweetalert2"

const AddUniversalCategoryModal = (props) => {
  const { isOpen = false, payload = {}, toggel = () => { }, onSuccess = () => { } } = props
  const [data, setData] = useState({
    image_id: "",
    category_name: "",
    status: ""
  })

  const [saving, setSaving] = useState(false)

  const handleToggel = () => {
    toggel()
    setData({})
  }

  const handleSubmit = async () => {
    const newInstance = instanceV1()
    try {
      setSaving(true)
      await newInstance.post("/universal-category", data)
      Swal.fire({
        title: 'Great !',
        text: 'Universal Category Added Successfully',
        icon: 'success',
        color: '#01A879',
        // confirmButtonText:'<button class="eats-btn"> ok </button>',
        confirmButtonColor: 'orange',
        confirmButtonText: 'Close',
        buttonsStyling: false,
        iconColor: '#01A879',
        customClass: {
          title: 'title-sweetalert',
          text: 'title-sweetalert',
          confirmButton: 'eat-btn-sweetalert'
        }
      }
      )
      onSuccess()
      handleToggel()
    } catch (error) {
      handleApiError()
    } finally {
      setSaving(false)
    }
  }


  useEffect(() => {
    setData({ ...data, ...payload })
  }, [isOpen])


  return (
    <Modal isOpen={isOpen} centered toggle={handleToggel} >

      <ModalHeader toggle={handleToggel}  ></ModalHeader>
      <div style={{ margin: 'auto', padding: '10px' }} >
        <h1 style={{ color: 'black', margin: '0' }} >Add Universal Category</h1>

        <AvtarCustum src={data.image_id ? getImageCloudFlare(data.image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention) : ""}
          handleChange={(imageId) => setData({ ...data, image_id: imageId })}
        />

        <label> Menu Category Name <span style={{ color: 'red' }}>*</span></label>
        <Input onChange={(e) => setData({ ...data, category_name: e.target.value })} />
        <div className="d-flex eat-status" style={{ margin: '20px 0' }}>
          <div >Active</div>
          <div className='form-check form-switch ms-1'>
            <Input type='switch' name='status'  onChange={(e) => setData({ ...data, status: 0 + e.target.checked })} checked={data.status || 0} />
          </div>
        </div>

        <div style={{ marginTop: '15px' }}>
          <Button className='me-2' outline color="primary" onClick={handleToggel} >
            Cancel
          </Button>
          <Button className="eat-btn"
            onClick={handleSubmit}
            disabled={saving || !data.category_name}
          >
            Save {saving && <Spinner size='sm' />}
          </Button>
        </div>
      </div>


    </Modal>
  )
}

export default AddUniversalCategoryModal
