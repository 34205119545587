import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, Edit3, MoreVertical, Search } from 'react-feather'

import { getImageCloudFlare, handleApiError, instanceV1 } from "../../../utility/Utils"
import { Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Spinner } from 'reactstrap'
import Avatar from "@components/avatar"
import { CustomPagination, customStyles } from '../../../utility/datatable-style/dataTableStyle'
import AddCombo from './AddCombo'
import EditCombo from './EditCombo'
import { useDispatch, useSelector } from 'react-redux'
import { GetMenuCombo, changeSearchString } from '../../../redux/pagination/menu/menuCombo'
import toast from 'react-hot-toast'


const EditableDiv = ({ comboId, price, cafeListId }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [text, setText] = useState(price)
    const [updating, setUpdating] = useState(false)
  
    const handleBlur = async () => {
      setIsEditing(false)
      const newInstance = instanceV1()
      if (text !== price) {
  
        if (!/^[1-9]+[0-9]*$/.test(text)) {
          setText(price)
          toast.error("Entered invalid price")
        } else if (text >= 9999) {
          setText(price)
          toast.error("Combo price can not exceed 9999")
        } else {
          try {
            setUpdating(true)
            await newInstance.put(`/cafe-combos-price/${comboId}`, {
              price: text,
              cafe_list_id: cafeListId
            })
            toast.success("Combo price updated successfullly")
            console.log(text)
          } catch (error) {
            setText(price)
            handleApiError(error)
          } finally {
            setUpdating(false)
          }
        }
      }
  
    }
  
    const handleIconClick = () => {
      setIsEditing(true)
    }
  
    const handleTextChange = (e) => {
      const val = e.target.value
      if (/^[1-9]+[0-9]*$/.test(val) || val === "") setText(val)
  
    }
  
    return (
      <div
        style={{ display: "flex", alignItems: "center", padding: "0 5px", position:"relative", maxWidth:"100%" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span> ₹ </span>&nbsp;
        {!updating ? (isEditing ? (
          <form onSubmit={(event) => {
            event.preventDefault()
            handleBlur()
          }}>
            <Input value={text} onChange={handleTextChange} style={{padding:"0.1rem"}} onBlur={handleBlur} autoFocus />
          </form>
        ) : (
          <div>
            <span style={{whiteSpace:"break-spaces"}}>{text}</span>&nbsp;
            {isHovered && <Edit3 size={14} style={{ cursor: "pointer", zIndex:"2" }} onClick={handleIconClick} />}
          </div>
        )) : <Spinner size='sm' color='primary' />}
      </div>
    )
  }


const Combo = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.menuCombo)
    const [timeOutId, setTimeoutId] = useState(null)
    const [modalData, setModalData] = useState({
        modal: '',
        payload: {}
    })
    const columns = [
        {
            name: 'S.NO.',
            selector: row => (((state.pageData.currentPage - 1) * state.pageData.rowsPerPage) + state.list.indexOf(row)) + 1,
            maxWidth: '10.5rem',
            minWidth: '63px'
        },
        {
            name: 'UID',
            selector: row => <span style={{ whiteSpace: 'break-spaces' }}>{row.uid ? `${row.uid}` : 'Creating...'}</span>,
            minWidth: '12rem',
            maxWidth: '15rem'
        },
        {
            name: 'Image',
            selector: row => {
                return (row.image_id ? <Avatar
                    img={getImageCloudFlare(row.image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention)}
                    imgHeight="40"
                    imgWidth="40"
                // status="online"
                /> : <></>)
            },
            minWidth: '7rem',
            maxWidth: '8rem'
        },
        {
            name: 'Combo Name',
            selector: row => row.name,
            minWidth: '15rem'
        },
        {
            name: 'Price',
            selector: row => <EditableDiv price={row.price || 0} comboId={row.cafe_menu_combo_id} cafeListId={row.cafe_list_id}  />,
            minWidth: '9rem',
            maxWidth: "12rem"
        },
        {
            name: 'STATUS',
            selector: (row) => {
                const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
                if (row.status === 1) {
                    return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
                        Active
                    </div>
                } else {
                    return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
                        Inactive
                    </div>
                }
            },
            maxWidth: '17rem',
            minWidth: '10rem',
            allowOverflow: true
        },
        {
            name: 'ACTION',
            allowOverflow: true,
            selector: (row) => {
                return (
                    <div className='d-flex'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pe-1' tag='span'>
                                <MoreVertical size={15} />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem tag='a' onClick={() => setModalData({
                                    modal: 'editCombo',
                                    payload: row
                                })}
                                    className='w-100' >
                                    <Circle size={8} /><span className='align-middle ms-50'> Edit</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            },
            maxWidth: '2.5rem',
            minWidth: '100px'
        }

    ]


    const getComboList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
        clearTimeout(timeOutId)
        dispatch(GetMenuCombo({
            isLoading: true,
            isRefreshing: true,
            pageNo: page,
            rows: row,
            str: state.searchStr
        }))
    }

    const RefreshComboList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
        clearTimeout(timeOutId)
        dispatch(GetMenuCombo({
            isLoading: false,
            isRefreshing: true,
            pageNo: page,
            rows: row,
            str: state.searchStr
        }))
    }

    useEffect(() => {
        getComboList()
    }, [])

    // ** Table
    return (
        <div className='custum-table-fix' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
            <AddCombo isOpen={modalData.modal === "addCombo"} toggel={() => setModalData({
                modal: "",
                payload: {}
            })}
                onSuccess={RefreshComboList} />

            {modalData.modal === "editCombo" && <EditCombo isOpen={modalData.modal === "editCombo"} payload={modalData.payload} toggel={() => setModalData({
                modal: "",
                payload: {}
            })}
                onSuccess={RefreshComboList} />}

            <DataTable
                // title='Live Order'
                pagination
                paginationServer progressComponent={<Spinner color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }} />}
                progressPending={state.isLoading}
                sortIcon={<ChevronDown />}
                subHeader={true}
                customStyles={customStyles()}
                fixedHeader={true}
                subHeaderComponent={
                    <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
                        <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
                            <h1 className='datatable-title-head'>Menu Combo</h1>
                            {/* <Input /> */}
                        </div>
                        <div className='search-input'>
                            <Input type='text' value={state.searchStr} onChange={(e) => {
                                dispatch(changeSearchString({ str: e.target.value }))
                                clearTimeout(timeOutId)
                                const timeoutIdLocal = setTimeout(() => {
                                    dispatch(GetMenuCombo({
                                        isLoading: true,
                                        isRefreshing: true,
                                        pageNo: 1,
                                        rows: state.pageData.rowsPerPage,
                                        str: e.target.value
                                    }))
                                }, 500)
                                setTimeoutId(timeoutIdLocal)
                            }} placeholder='Search' />
                            <span className='search-icon'><Search /></span>
                        </div>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }} >
                            {/* <ExportImportButton isImport={true} data={data} fileName='menu_addons_list' sampleUrl="/download-excel-menu" url={`/api/admin/import-menu-files/`} /> */}
                            <Button className='eat-btn' onClick={() => setModalData({
                                modal: 'addCombo',
                                payload: ''
                            })} >Create Combo</Button>
                        </div>
                    </div>
                }
                paginationDefaultPage={state.pageData.currentPage}
                paginationComponent={() => <CustomPagination
                    pageData={state.pageData}
                    numberOfData={state.list.length}
                    handlePerPage={(e) => getComboList(e.target.value, 1)}
                    handlePagination={(page) => getComboList(state.pageData.rowsPerPage, page.selected + 1)} />}
                responsive={true}
                paginationPerPage={state.pageData.rowsPerPage}
                columns={columns}     // filter data for column
                data={state.list}       //takes data
                highlightOnHover
            />
        </div>
    )

}

export default Combo
