// import { Refresh, SentToKitchen, Ordered, Preparing, ReadyToServe } from "../../@core/assets/fonts/feather/icons"
import { Card, Spinner, Modal, ModalHeader, Input, Form, Button, CardHeader, CardBody, Label, Row, Col, ModalBody } from 'reactstrap'
import { AvtarCustum } from '../../../UI/Models/CustumAvatar'
import { useState, useEffect } from 'react'
import { getImageCloudFlare, handleApiError, instance, instanceV1 } from '../../../utility/Utils'
// import { AvtarCustum } from "../../Component/AvtarCustum"

import Swal from "sweetalert2"

import Select from 'react-select'
import toast from 'react-hot-toast'
import { ReactSelectStyle } from '../../../utility/ReactSelectStyle/SelectStyle'

const AddCombo = (props) => {
    const { isOpen = false, payload = {}, toggel = () => { }, onSuccess = () => { } } = props
    const [data, setData] = useState({
        cafe_list_id: "",
        status: "",
        price: "",
        combo_name: "",
        image_id: "",
        cafe_menu_id: ""
    })

    const [selectedItems, setSelectedItems] = useState([])

    const [saving, setSaving] = useState(false)
    const [cafeOption, setCafeOptions] = useState([])
    const [itemOption, setItemOption] = useState([])

    const handleToggel = () => {
        toggel()
        setData({})
    }

    const handleSubmit = async () => {
        setSaving(true)
        if (!data.cafe_list_id || !selectedItems.length || !data.combo_name || !data.price) {
            toast.error("Please Fill All required fields")
            setSaving(false)
            return
        }
        const newInstance = instanceV1()
        try {
            setSaving(true)
            await newInstance.post("/cafe-combo", {
                cafe_list_id: data.cafe_list_id,
                status: data.status,
                price: data.price,
                combo_name: data.combo_name,
                image_id: data.image_id,
                items: selectedItems.map((e) => {
                    return {
                        cafe_menu_id: e.value
                    }
                })
            })
            Swal.fire({
                title: 'Great !',
                text: 'Combo Added Successfully',
                icon: 'success',
                color: '#01A879',
                // confirmButtonText:'<button class="eats-btn"> ok </button>',
                confirmButtonColor: 'orange',
                confirmButtonText: 'Close',
                buttonsStyling: false,
                iconColor: '#01A879',
                customClass: {
                    title: 'title-sweetalert',
                    text: 'title-sweetalert',
                    confirmButton: 'eat-btn-sweetalert'
                }
            }
            )
            onSuccess()
            handleToggel()
        } catch (error) {
            handleApiError(error)
        } finally {
            setSaving(false)
        }
    }
    const loadCafe = async () => {
        const newInstance = instance()
        try {
            const j = await newInstance.get("api/user/admin/cafe-list/get/all")
            const sortedData = j.data.data
            sortedData.sort((a, b) => {
                const nameA = a.cafe_name.toUpperCase() // ignore upper and lowercase
                const nameB = b.cafe_name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
            setCafeOptions(sortedData.map((e) => {
                return {
                    label: e.cafe_name,
                    value: e.id
                }
            }))
        } catch (error) {
            handleApiError(error)
        }
    }
    const loadItem = async (cafe_id) => {
        const newInstance = instanceV1()
        try {
            const j = await newInstance.get(`/search-menu-item?cafe_list_id=${cafe_id}`)
            const sortedData = j.data.data
            sortedData.sort((a, b) => {
                const nameA = a.item_name.toUpperCase() // ignore upper and lowercase
                const nameB = b.item_name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
            setItemOption(sortedData.map((e) => {
                return {
                    label: e.item_name,
                    value: e.cafe_menu_item_id
                }
            }))
        } catch (error) {
            handleApiError(error)
        }
    }

    useEffect(() => {
        setData({ ...data, ...payload })
        setSelectedItems([])
    }, [isOpen])

    useEffect(() => {
        loadCafe()
    }, [])


    return (
        <Modal isOpen={isOpen} centered size="lg" toggle={handleToggel} >

            <ModalHeader toggle={handleToggel}  ></ModalHeader>
            <ModalBody>
                <div style={{ margin: 'auto', padding: '10px' }} >
                    <h1 style={{ color: 'black', margin: '0' }} >Add Menu Combo</h1>

                    <AvtarCustum src={data.image_id ? getImageCloudFlare(data.image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention) : ""}
                        handleChange={(imageId) => setData({ ...data, image_id: imageId })}
                    />
                    <Row>
                        <Col md='6' sm='12' className='mb-1'>
                            <Label className='form-label' htmlFor='restaurant-name'>
                                Restaurant Name <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Select
                                styles={ReactSelectStyle}
                                closeMenuOnSelect
                                value={cafeOption.find(d => d.value === data.cafe_list_id) || {}}
                                options={cafeOption}
                                className='react-select'
                                onChange={(e) => {
                                    setData({ ...data, cafe_list_id: e.value })
                                    loadItem(e.value)
                                }}
                            />
                        </Col>

                        <Col md='6' sm='12' className='mb-1'>
                            <Label className='form-label' htmlFor='type'>
                                Menu Item  <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Select
                                styles={ReactSelectStyle}
                                closeMenuOnSelect
                                isMulti={true}
                                value={[...selectedItems]}
                                options={itemOption}
                                onChange={(e) => {
                                    setSelectedItems(e)
                                }
                                    // setData({ ...data, cafe_menu_id: e.value })
                                }
                                className='react-select'
                            />
                        </Col>
                        <Col md='6' sm='12' className='mb-1'>

                            <Label> Combo Name <span style={{ color: 'red' }}>*</span></Label>
                            <Input onChange={(e) => setData({ ...data, combo_name: e.target.value })}
                                value={data.combo_name} />
                        </Col>

                        <Col md='6' sm='12' className='mb-1'>
                            <Label className='form-label' htmlFor='price'>
                                Price  <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Input type='number' name='price' value={data.price || ''}
                                onChange={(e) => setData({ ...data, price: e.target.value })} placeholder='' />
                        </Col>
                    </Row>
                    <Row>

                        <div className="d-flex eat-status" style={{ margin: '20px 0' }}>
                            <div >Active</div>
                            <div className='form-check form-switch ms-1'>
                                <Input type='switch' name='status' onChange={(e) => setData({ ...data, status: 0 + e.target.checked })} checked={data.status || 0} />
                            </div>
                        </div>
                    </Row>

                    <div style={{ marginTop: '15px' }}>
                        <Button className='me-2' outline color="primary" onClick={handleToggel} >
                            Cancel
                        </Button>
                        <Button className="eat-btn"
                            onClick={handleSubmit}
                            disabled={saving}
                        >
                            Save {saving && <Spinner size='sm' />}
                        </Button>
                    </div>
                </div>
            </ModalBody>

        </Modal>
    )
}

export default AddCombo
