import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, Edit3, MoreVertical, Search } from 'react-feather'

import { handleApiError, instance, instanceV1 } from "../../../utility/Utils"

import { useNavigate } from 'react-router-dom'
import { YCheck, UnCheck } from '../../../@core/assets/fonts/feather/icons'
import { Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, UncontrolledTooltip, Spinner } from 'reactstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import ExportImportButton from '../../../UI/ExportImport'
import { CustomPagination, customStyles } from '../../../utility/datatable-style/dataTableStyle'
import { ReactSelectStyle } from '../../../utility/ReactSelectStyle/SelectStyle'
import { useDispatch, useSelector } from 'react-redux'
import { GetRestaurantMenu, changeSearchString } from '../../../redux/pagination/menu/restaurantMenu'
import toast from 'react-hot-toast'

const EditableDiv = ({ item_id, price, cafeListId }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState(price)
  const [updating, setUpdating] = useState(false)

  const handleBlur = async () => {
    setIsEditing(false)
    const newInstance = instanceV1()
    if (text !== price) {

      if (!/^[1-9]+[0-9]*$/.test(text)) {
        setText(price)
        toast.error("Entered invalid price")
      } else {
        try {
          setUpdating(true)
          await newInstance.put(`/cafe-menu-item-price/${item_id}`, {
            price: text,
            cafe_list_id: cafeListId
          })
          toast.success("Item price updated successfullly")
          console.log(text)
        } catch (error) {
          setText(price)
          handleApiError(error)
        } finally {
          setUpdating(false)
        }
      }
    }

  }

  const handleIconClick = () => {
    setIsEditing(true)
  }

  const handleTextChange = (e) => {
    const val = e.target.value
    if (/^[1-9]+[0-9]*$/.test(val) || val === "") setText(val)

  }

  return (
    <div
      style={{ display: "flex", alignItems: "center", padding: "0 5px", position:"relative", maxWidth:"100%" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span> ₹ </span>&nbsp;
      {!updating ? (isEditing ? (
        <form onSubmit={(event) => {
          event.preventDefault()
          handleBlur()
        }}>
          <Input value={text} onChange={handleTextChange} style={{padding:"0.1rem"}} onBlur={handleBlur} autoFocus />
        </form>
      ) : (
        <div>
          <span style={{whiteSpace:"break-spaces"}}>{text}</span>&nbsp;
          {isHovered && <Edit3 size={14} style={{ cursor: "pointer", zIndex:"2" }} onClick={handleIconClick} />}
        </div>
      )) : <Spinner size='sm' color='primary' />}
    </div>
  )
}
const MenuAddMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.restaurantMenu)
  const [timeOutId, setTimeoutId] = useState(null)
  const [cafeOption, setCafeOptions] = useState([])
  const animatedComponents = makeAnimated()

  const columns = [
    {
      name: 'S.NO.',
      selector: row => (((state.pageData.currentPage - 1) * state.pageData.rowsPerPage) + state.list.indexOf(row)) + 1,
      maxWidth: '10.5rem',
      minWidth: '63px'
    },
    {
      name: 'UID',
      selector: row => <span style={{ whiteSpace: 'break-spaces' }}>{row.uid ? `${row.uid}` : 'Creating...'}</span>,
      minWidth: '14rem',
      maxWidth: '18rem'
    },
    {
      name: 'RESTAURANT NAME',
      selector: row => row.cafe_name,
      minWidth: "13rem"
    },
    {
      name: 'MENU CATEGORY',
      selector: (row) => row.category_name,
      minWidth: "15rem"
    },
    {
      name: 'MENU ITEN NAME',
      selector: (row) => row.item_name,
      minWidth: "15rem"
    },
    // {
    //   name: 'Addons',
    //   selector: () => 'missing' 
    // },
    {
      name: 'ADDON',
      selector: (row) => {
        if (row.addon_list.length) {
          return <span id={`gsbhjb${row.cafe_menu_item_id}`}>
            <YCheck />
            <UncontrolledTooltip placement='top' target={`gsbhjb${row.cafe_menu_item_id}`}>

              {/* <p  style={{color:'#1A264E'}}>{row.Addon_name}</p> */}
              <ul style={{ color: '#1A264E', listStyle: 'none', padding: '2px' }}>
                {row.addon_list.map((e, i) => { return <li key={i} style={{ color: '#1A264E', listStyle: 'none' }}> {e.addon_name}</li> })}
              </ul>
            </UncontrolledTooltip>
          </span>
        } else {
          return (
            <UnCheck />
          )
        }
      },
      minWidth: "8rem",
      maxWidth: "11rem",
      allowOverflow: true
    },
    {
      name: 'RATING',
      selector: row => row.average_rating || 0,
      minWidth: '7rem',
      maxWidth: '9rem'
    },
    {
      name: 'PRICE',
      selector: row => <EditableDiv price={row.base_price || 0} item_id={row.cafe_menu_item_id} cafeListId={row.cafe_list_id}  />,
      minWidth: '7rem',
      maxWidth: '10rem'
    },
    {
      name: 'VARIANT',
      selector: (row) => <span id={`variant${row.cafe_menu_item_id}`}>
        {row.menu_variant.length}
        <UncontrolledTooltip placement='top' target={`variant${row.cafe_menu_item_id}`}>
          <ul>
            {row.menu_variant?.map((e, i) => { return <li key={i} style={{ color: '#1A264E' }}>{e.variant_name}</li> })}
          </ul>
        </UncontrolledTooltip>
      </span>,
      minWidth: "7rem",
      maxWidth: "8rem"
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.status === 1) {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            Active
          </div>
        } else {
          return <div style={{ background: ' rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Inactive
          </div>
        }
      },
      maxWidth: '13rem',
      minWidth: '9rem',
      allowOverflow: true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        const navigate = useNavigate()
        return (
          <div className='d-flex'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1' tag='span'>
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag='a' onClick={() => navigate(`/restaurant-menu/edit/${row.cafe_menu_item_id}`)} className='w-100' >
                  <Circle size={8} /><span className='align-middle ms-50'> Edit</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      },
      maxWidth: '2.5rem',
      minWidth: '100px'
    }

  ]

  const getMenuItemsList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage, cafeData = state.cafeData) => {
    clearTimeout(timeOutId)
    dispatch(GetRestaurantMenu({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr,
      cafeData
    }))
  }

  const getCafeList = async () => {

    const newInstance = instance()
    try {
      const j = await newInstance.get('/api/user/admin/cafe-list/get/all')
      setCafeOptions([
        { label: 'ALL', value: null, className: 'select-option1' },
        ...j.data.data.map((e) => {
          return ({
            label: e.cafe_name,
            value: e.id,
            className: 'select-option1'
          })
        })
      ])
    } catch (err) {
      handleApiError(err)
    }
  }


  useEffect(() => {
    getMenuItemsList()
  }, [])

  // ** Table
  return (
    <div className='custum-table-fix' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
      <DataTable
        // title='Live Order'
        pagination
        paginationServer
        progressComponent={<Spinner color="primary"
          style={{
            height: '3rem',
            width: '3rem'
          }} />}
        progressPending={state?.isLoading}
        sortIcon={<ChevronDown />}
        subHeader={true}
        customStyles={customStyles()}
        fixedHeader={true}
        subHeaderComponent={
          <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', zIndex: '10' }}>
            <div className='d-flex' style={{ gap: '1em', padding: '10px', alignItems: 'center' }}>
              <h1 className='datatable-title-head'>Restaurant Menu</h1>
              {/* <Input /> */}
            </div>
            <div className='search-input'>
              <Input type='text' value={state.searchStr} onChange={(e) => {
                dispatch(changeSearchString({ str: e.target.value }))
                clearTimeout(timeOutId)
                const timeoutIdLocal = setTimeout(() => {
                  dispatch(GetRestaurantMenu({
                    isLoading: true,
                    isRefreshing: true,
                    pageNo: 1,
                    rows: state.pageData.rowsPerPage,
                    str: e.target.value,
                    cafeListId: state.cafeListId
                  }))
                }, 500)
                setTimeoutId(timeoutIdLocal)
              }} placeholder='Search' />
              <span className='search-icon'><Search /></span>
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }} >
              <ExportImportButton isImport={true} data={state.list} fileName='menu_list' sampleUrl="/download-excel-cafe-menu-item" url={`/api/admin/import-cafe-menu-items`} />
              <Select
                styles={ReactSelectStyle}
                isClearable={true}
                value={{value:state.cafeData.id, label:state.cafeData.name}}
                closeMenuOnSelect
                components={animatedComponents}
                options={cafeOption}
                onChange={(e) => getMenuItemsList(state.pageData.rowsPerPage, 1, e?.value ? {id:e.value, name:e.label} : {})
                }
                className='react-select'
                onFocus={getCafeList}
              />

              <Button className='eat-btn' onClick={() => navigate('/restaurant-menu/add')} > Add Item </Button>
            </div>
          </div>
        }
        paginationDefaultPage={state.pageData.currentPage}
        paginationComponent={() => <CustomPagination
          pageData={state.pageData}
          numberOfData={state.list.length}
          handlePerPage={(e) => getMenuItemsList(e.target.value, 1)}
          handlePagination={(page) => getMenuItemsList(state.pageData.rowsPerPage, page.selected + 1, state.cafeListId)} />}
        responsive={true}
        paginationPerPage={state.pageData.rowsPerPage}
        columns={columns}     // filter data for column
        data={state.list}       //takes data
        highlightOnHover
      />
    </div>
  )

}

export default MenuAddMenu
