import {
  Row,
  Col,
  Input,
  Form,
  Modal,
  ModalHeader,
  Button,
  Label,
  InputGroup,
  InputGroupText,
  Spinner
} from 'reactstrap'

// ** Styles
import '@styles/react/pages/page-authentication.scss'
import React, { useState, useEffect } from 'react'

import { handleApiError, instance } from '../../../utility/Utils'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import PdfInput from '../../../UI/PdfInput'

const EditDocuments = () => {
  const [data, setData] = useState({
    fssai_certificate_url: "",
    fssai_licence_number: "",
    gst_certificate_url: "",
    gst_number: ""
  })
  const [basicModal, setBasicModal] = useState(false)
  const [saving, setsaving] = useState(false)
  const {id} = useParams()

    const getCafeById = async() => {
        const newInstance = instance()
        try {
          const j = await newInstance.get(`/api/user/admin/restaurant-all-info/${id}`
          )
          if (j.data.message[0] && j.data.message[0].fssai_certificate_url !== null) {
            // setDocument(j.data.message[0].fssai_certificate_url)
            setData({
              fssai_certificate_url: j.data.message[0].fssai_certificate_url ? j.data.message[0].fssai_certificate_url : '',
              fssai_licence_number: j.data.message[0].fssai_licence_number ? j.data.message[0].fssai_licence_number : '',
              gst_certificate_url: j.data.message[0].gst_certificate_url ? j.data.message[0].gst_certificate_url : '',
              gst_number: j.data.message[0].gst_number ? j.data.message[0].gst_number : ''
            })
          }
        } catch (err) {
          handleApiError(err)
         }
    }

const handleSubmit = async(event) => {
  event.preventDefault()
  const newInstance = instance()
  setsaving(true)
  try {
   const j =  await newInstance.post(`/api/user/admin/restaurant-edit-document/${id}`,
    data)
    toast.success(j.data.msg)
  } catch (err) {
    // if (err.response.data.msg) {
      // toast.error(err.response.data.msg)
    // }
    
    handleApiError(err)
   } finally {
    setsaving(false)
   }
}

const inputChengeHandler = (e) => {
  setData({...data, [e.target.name]:e.target.value})
}

useEffect(() => { 
  getCafeById() 
}, [])

  return (
    <>
        
    <div>
      </div>
        <Form style={{display:'flex'}} onSubmit={handleSubmit} >
          
            <Modal className='modal-dialog-centered  modal-lg' isOpen={basicModal} toggle={() => setBasicModal(!basicModal)}>
              <ModalHeader toggle={() => setBasicModal(!basicModal)}></ModalHeader>
              <iframe  style={{width:'100%', height:'100vh'}}  src={`https://docs.google.com/gview?url=${document}&embedded=true`}/>
            </Modal>
          <Row className='mt-2'>
                <Label>FSSAI Certificate (PDF only)</Label>
            <Row>
                <Col md='6' sm='10' className='mb-1'>
                <PdfInput  data={{url:data.fssai_certificate_url}} onChange = {(e) => setData({...data, fssai_certificate_url:e}) }  />
                </Col>
            </Row>

            <Row className='mt-2'>
              
              <Label>FSSAI Number</Label>
              <Col md='6' sm='10' className='mb-1'>
            <Input type='text' pattern='[0-9]{14}' title="Invalid FSSAI Number." name='fssai_licence_number' value={data.fssai_licence_number} onChange={inputChengeHandler} />
            </Col>
            </Row>
            <Row className='mt-2'>
            <Label>GST Certificate (PDF only)</Label>
            <Col md='6' sm='10' className='mb-1'>
            <PdfInput data={{url:data.gst_certificate_url}} onChange = {(e) => setData({...data, gst_certificate_url:e}) }  />
            </Col>
            </Row>
            <Row className='mt-2'>
            <Col md='6' sm='10' className='mb-1'>
            <Label>GSTIN Number</Label>
            <Input name='gst_number' pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" title="Invalid GST Number." value={data.gst_number} type='text' onChange={inputChengeHandler} />
            </Col>
            </Row>
           
           
            <Col sm='12'>
              <div className='d-flex'>
                <Button className='mt-2 eat-btn'  type='submit'  disabled={saving}>
                    Save {saving && <Spinner size='sm'/>}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
    </>
  )
}

export default EditDocuments
