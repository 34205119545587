// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"


// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'
import { isObjEmpty } from "@utils"

import EditEmployee from "../../views/Employees/EditEmpolyee"
import MenuCategory from "../../views/menuItem/universalCategory/UniversalCategoryTable"
import CreateCategory from "../../views/menuItem/universalCategory/CreateCategory"
import EditMenuCategory from "../../views/menuItem/universalCategory/EditMenuCategory"
import MenuAddMenu from "../../views/menuItem/addMenu/MenuAddMenu"
import EditAddMenu from "../../views/menuItem/addMenu/EditAddMenu"
import AddMenu from "../../views/menuItem/addMenu/AddMenu"
import GernalInfo from "../../views/Resturents/editResturent/GernalInfo"
import TimeAndPrice from "../../views/Resturents/editResturent/TimeAndPrice"
import AddressInfo from "../../views/Resturents/editResturent/AddressInfo"
import EditSocialInfo from "../../views/Resturents/editResturent/EditSocialInfo"
import FeaturedImage from "../../views/Resturents/editResturent/FeaturedImage"
import ImageGallery from "../../views/Resturents/editResturent/ImageGallery"
import EditDocuments from "../../views/Resturents/editResturent/editDocuments"
import QRManagement from "../../views/QRManagement/QRManagement"
import CreateQR from "../../views/QRManagement/CreateQR"
import OrderHistory from "../../views/orders/orderHistory/OrderHistory"
import OrderHistoryDetails from "../../views/orders/orderHistory/OrderHistoryDetails"
import CustomerTable from "../../views/customer/CustomerTable"
import AddUserProfile from "../../views/customer/AddUserProfile"
import Reviews from "../../views/reviews/Reviews"
import EditReviews from "../../views/reviews/EditReviews"
import BannerAds from "../../views/ads/BannarAds"
import UploadAds from "../../views/ads/UploadAds"
import EditAds from "../../views/ads/EditAds"
import OrderInvoice from "../../views/orders/orderHistory/OrderInvoice"
import EditUserProfile from "../../views/customer/EditUserProfile"
import QRCodes from "../../views/QRManagement/QRCodes"
import AddTable from "../../views/QRManagement/tableManagement/AddTable"
import EditTable from "../../views/QRManagement/tableManagement/EditTable"
import Combo from "../../views/menuItem/Combo/ComboTable"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "Eats"

// ** Default Route
const DefaultRoute = "/dashboard"

const Login = lazy(() => import("../../views/Auth/Login"))
const ForgotPassword = lazy(() => import("../../views/Auth/ForgotPassword"))
const EmailSent = lazy(() => import("../../views/Auth/EmailSent"))
const PasswordResetSuccess = lazy(() => import("../../views/Auth/PasswordResetSuccess"))
const ResetPassword = lazy(() => import("../../views/Auth/ResetPassword"))
const NotAuthorized = lazy(() => import("../../views/NotAuthorized"))
const Dashboard = lazy(() => import("../../views/Dashboard"))
const Setting = lazy(() => import("../../views/Dashboard/settings/Setting"))
const Error = lazy(() => import("../../views/Error"))
const Restaurants = lazy(() => import("../../views/Resturents/Restaurants"))
const AddResturent = lazy(() => import("../../views/Resturents/AddResturent"))

const LiveOrders  = lazy(() => import("../../views/orders/liveOrders/LiveOrders"))
const EditOrders  = lazy(() => import("../../views/orders/liveOrders/EditOrders"))

const EditResturent = lazy(() => import("../../views/Resturents/editResturent/EditResturent"))
const ViewProfile = lazy(() => import("../../views/Resturents/ViewProfile"))
const EmployeesTableView = lazy(() => import("../../views/Employees/TableView"))
const AddEmployee = lazy(() => import("../../views/Employees/AddEmployee"))
const UniversalItemTable =  lazy(() => import("../../views/menuItem/universalItem/Table"))

const getHomeRoute = () => {
  
  const user = JSON.parse(localStorage.getItem("user"))
  if (user) {
    return DefaultRoute
  } else {
    return '/login'
  }
}
// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={getHomeRoute()} />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/reset-password",
    element: <ResetPassword title={"Reset Password"} />,
    meta: {
       layout: "blank",
       publicRoute: true
    }
 },
 {
    path: "/onboard",
    element: <ResetPassword title={"Create Password"} />,
    meta: {
       layout: "blank",
       publicRoute: true
    }
 },
  {
    path: "/email-sent",
    element: <EmailSent />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: "/password-reset-success",
    element: <PasswordResetSuccess />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: "/not-authrize",
    element: <NotAuthorized />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/dashboard",
    element: <Dashboard/>
  },
  {
    path: "/dashboard/setting",
    element: <Setting/>
  },
  {
    path: "/list-restaurants",
    element: <Restaurants/>
  },
  {
    path: "/list-restaurants/add",
    element: <AddResturent/>
  },
  {
    path: "/list-restaurants/edit",
    element: <EditResturent/>,
    children: [
      { path: '/list-restaurants/edit',
       element: <Navigate to='/list-restaurants/edit/gernal-info/:id'/>
      },
      { path: '/list-restaurants/edit/gernal-info/:id',
       element: <GernalInfo/>
      },
      { path: '/list-restaurants/edit/time-price/:id',
       element: <TimeAndPrice/>
      },
      { path: '/list-restaurants/edit/address-info/:id',
       element: <AddressInfo/>
      },
      { path: '/list-restaurants/edit/social-info/:id',
       element: <EditSocialInfo/>
      },
      { path: '/list-restaurants/edit/featured-image/:id',
       element: <FeaturedImage/>
      },
      { path: '/list-restaurants/edit/image-gallery/:id',
       element: <ImageGallery/>
      },
      { path: '/list-restaurants/edit/document/:id',
       element: <EditDocuments/>
      }
    ]
  },
  {
    path: "/list-restaurants/view-profile/:id",
    element: <ViewProfile/>
  },
  {
    path: "/list-banner",
    element: <BannerAds/>
  },
  {
    path: "/list-banner/upload",
    element: <UploadAds/>
  },
  {
    path: "/list-banner/edit/:id",
    element: <EditAds/>
  },
  {
    path: "/list-empolyees",
    element:<EmployeesTableView/>
  },
  {
    path: "/list-empolyees/add",
    element:<AddEmployee/>
  },
  {
    path: "/list-empolyees/edit/:id",
    element: <EditEmployee/>
  },
  {
    path: "/live-orders",
    element: <LiveOrders/>
  },
  {
    path: "/live-orders/edit/:id",
    element: <EditOrders/>
  },
  {
    path: "/order-history",
    element: <OrderHistory/>
  },
  {
    path: "/order-history/invoice/:id",
    element: <OrderInvoice/>
  },
  {
    path: "/order-history/details/:id",
    element: <OrderHistoryDetails/>
  },
  {
    path: "/list-customers",
    element:<CustomerTable/>
  },
  {
    path: "/list-customers/add",
    element:<AddUserProfile/>
  },
  {
    path: "/list-customers/edit-profile/:id",
    element:<EditUserProfile/>
  },
  {
    path: "/universal-category",
    element:<MenuCategory/>
  },
  {
    path: "/universal-category/create",
    element:<CreateCategory/>
  },
  {
    path: "/universal-category/edit/:id",
    element:<EditMenuCategory/>
  },
  {
    path: "/universal-item",
    element:<UniversalItemTable/>
  },
  // {
  //   path: "/universal-item/create",
  //   element:<CreateMenuItem/>
  // },
  // {
  //   path: "/universal-item/edit/:id",
  //   element:<EditMenuItem/>
  // },
  {
    path: "/restaurant-combos",
    element:<Combo/>
  },
  {
    path: "/restaurant-menu",
    element:<MenuAddMenu/>
  },
  {
    path: "/restaurant-menu/add",
    element:<AddMenu/>
  },
  {
    path: "/restaurant-menu/edit/:id",
    element:<EditAddMenu/>
  },
  {
    path: "list-reviews",
    element: <Reviews/>
  },
  {
    path: "list-reviews/edit/:id",
    element: <EditReviews/>
  },
  {
    path: "generate-qr",
    element:<QRCodes/>
  },
  {
    path: "restaurant-qr",
    element:<QRManagement/>
  },
  {
    path: "restaurant-qr/create",
    element:<CreateQR/>
  },
  {
    path: "restaurant-qr/edit/:id",
    element:<EditTable/>
  },
  {
    path: "restaurant-qr/add",
    element:<AddTable/>
  },
  {
    path: "/*",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute
        // const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
