
// Imports

import { useRef, useState, useEffect } from 'react'

import {
  Row,
  Col,
  Input,
  Button,
  Label,
  Spinner,
  Form
} from 'reactstrap'

import { GoogleMap, Marker, useJsApiLoader, Autocomplete, StandaloneSearchBox, LoadScript } from '@react-google-maps/api'

import { handleApiError, instance } from '../../../utility/Utils'
import { useParams } from 'react-router-dom'
import { City, State } from 'country-state-city'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import toast from 'react-hot-toast'

// End of Imports
const mapData = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  libraries: ['places']
}

const AddressInfo = () => {
  const [mounted, setMounted] = useState(true)
  const [saving, setsaving] = useState(false)
  const { id } = useParams()
  const { isLoaded } = useJsApiLoader(mapData)
  const [state, setState] = useState({})
  const [defaultPlace, setDefaultPlace] = useState()
  const [data, setData] = useState({
    country: 'India',
    pin_code: '',
    longitude: 0,
    latitude: 0
  })
  const [dataLoaded, setDataLoaded] = useState(false)
  const inputRef = useRef()

  const animatedComponents = makeAnimated()
  const mapOption = {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false
  }
  const mapCenter = { lat: data.latitude || 0, lng: data.longitude || 0 }
  // getting cafe list
  const getCafeById = async () => {
    const newInstance = instance()
    try {
      const j = await newInstance.get(`/api/user/admin/restaurant-all-info/${id}`
      )
      if (j.data.message[0]) {
        setData({ ...j.data.message[0], country: 'India' })
        setDataLoaded(true)
      }
    } catch (err) {
      handleApiError(err)
    }
  }

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces()
    if (place) {
      setData({
        ...data,
        longitude: place.geometry.location.lng() || 0,
        latitude: place.geometry.location.lat() || 0
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newInstance = instance()
    setsaving(true)
    try {
      const j = await newInstance.post(`/api/user/admin/restaurant-edit-address-information/${id}`, {
        address_1: data.address_1,
        address_2: data.address_2,
        city: data.city,
        state: data.state,
        country: 'India',
        pin_code: data.pin_code,
        latitude: data.latitude,
        longitude: data.longitude
      })
      toast.success(j.data.msg)
    } catch (error) {
      handleApiError(error)
    } finally {
      setsaving(false)
    }
  }

  useEffect(() => {
    setMounted(true)
    getCafeById()
    setState(State.getStatesOfCountry('IN').map((e) => {
      return {
        label: e.name,
        value: e.name,
        state_code: e.isoCode,
        country_code: e.countryCode
      }
    }))
    return () => setMounted(false)
  }, [])

  useEffect(() => {
    const def = () => {
      const defaultPlace = new window.google.maps.LatLng(data.latitude, data.longitude)
      const defaultGeocoder = new window.google.maps.Geocoder()
      defaultGeocoder.geocode({ location: defaultPlace }, (results, status) => {
        if (status === 'OK' && results[0]) {
          
          setDefaultPlace(results[0].formatted_address)
          const postal = results[0].address_components.find((e) => e.types.includes("postal_code"))
          if (postal) {
            setData({...data, pin_code:postal.long_name})
          } else {
            setData({...data, pin_code:""})
          }
          // setData({...data, kkkkk:"jdhfdg"})
        } else {
          setDefaultPlace("")
        }
      })
    }
    if (isLoaded && dataLoaded) {
      def()
    }
  }, [isLoaded, data.latitude, data.latitude])

  return (
    mounted && <>

      <Form onSubmit={handleSubmit}>
        <Row className='mt-2'>
          <Col md='10'>
            <Row>
              <Col md='6' xs='12' className='mb-1'>
                <Label className='form-label' for='address1'>
                  Address 1
                </Label>
                <Input type='text' name='address1' id='address1' value={data.address_1 || ''}
                  onChange={(e) => setData({ ...data, address_1: e.target.value })} placeholder='Enter Here' />
              </Col>
              <Col md='6' xs='12' className='mb-1'>
                <Label className='form-label' for='address2'>
                  Address 2
                </Label>
                <Input type='text' name='address2' id='address2' value={data.address_2 || ''}
                  onChange={(e) => setData({ ...data, address_2: e.target.value })} placeholder='Enter' />
              </Col>
              <Col className='mb-1' md='6' sm='12'>
                <Label className='form-label'>Country</Label>
                <Select
                  placeholder='India'
                  // value={data.country}
                  value='IN'
                  isDisabled
                />
              </Col>
              <Col className='mb-1' md='6' sm='12'>
                <Label className='form-label'>State</Label>
                <Select
                  isSearchable
                  closeMenuOnSelect
                  placeholder={data.state || 'Select'}
                  // defaultValue = {cafeOption.find((e) => e.value ===  1)}
                  components={animatedComponents}
                  options={state}
                  onChange={(e) => {
                    setData({ ...data, state: e.value, city: '' })
                  }}
                  className='react-select'
                  isDisabled={!data.country}
                // isDisabled={true}
                />
              </Col>

              <Col className='mb-1' md='6' sm='12'>
                <Label className='form-label'>City<span style={{ color: 'red' }}>*</span></Label>
                <Select
                  value={!!data.city && { value: data.city, label: data.city }}
                  isSearchable
                  closeMenuOnSelect
                  placeholder={data.city || 'Select'}
                  components={animatedComponents}
                  required
                  options={
                    data.state ? State.getAllStates().find((e) => e.name === data.state) ? City.getCitiesOfState('IN',
                      State.getAllStates().find((e) => e.name === data.state).isoCode).map((c) => {
                        return {
                          label: c.name,
                          value: c.name
                        }
                      }) : [] : []
                  }
                  onChange={(e) => {
                    setData({ ...data, city: e.value })
                  }}
                  className='react-select'
                  isDisabled={!data.state}
                // isDisabled={true}
                />
              </Col>


              <Col md='6' xs='12' className='mb-1'>
                <Label className='form-label' for='nameMulti'>
                  Postal Code
                </Label>
                <Input type='number' name='pin_code' value={data.pin_code || ''}
                  onChange={(e) => setData({ ...data, pin_code: e.target.value })}
                  id='nameMulti' placeholder='Enter' />
              </Col>
              <Col md='12' xs='12' className='mb-1'>
                <Label className='form-label' for='namulti'>
                  Location on Google map
                </Label>
                {isLoaded && <StandaloneSearchBox
                  onLoad={(selectedValue) => {
                    inputRef.current = selectedValue
                  }}
                  onPlacesChanged={handlePlaceChanged}
                  options={{ types: ['locality', 'political', "postal_code"] }}
                >
                  <input
                    type="text"
                    value={defaultPlace || ""}
                    onChange={(e) => setDefaultPlace(e.target.value)}
                    className="form-control"
                    placeholder="Enter Location"
                  />
                </StandaloneSearchBox>}
              </Col>

              <Col>
                {isLoaded && <GoogleMap
                  center={mapCenter}
                  zoom={10}
                  mapContainerStyle={{ width: '100%', height: '250px', border: '0', borderRadius: '10px' }}
                  options={mapOption}
                  onClick={(e) => setData({
                    ...data,
                    longitude: e.latLng.lng(),
                    latitude: e.latLng.lat()
                  })}

                >
                  <Marker draggable={true}
                    onDragEnd={(e) => setData({
                      ...data,
                      longitude: e.latLng.lng(),
                      latitude: e.latLng.lat()
                    })}
                    // animation={[DROP]}
                    position={{ lat: data.latitude || 0, lng: data.longitude || 0 }} />
                </GoogleMap>
                }
              </Col>
            </Row>

          </Col>
        </Row>
        <Row className='mt-1 mb-2'>
          <Col md='12' className='mt-3  mb-3'>
            <Button className='me-1 eat-btn' type='submit' disabled={saving || !data.city} >
              Save {saving && <Spinner size='sm' />}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AddressInfo

