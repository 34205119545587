export const ReactSelectStyle = {
    option: (base, { isFocused, isSelected }) => {
        return {
            ...base,
            background: isFocused ? "linear-gradient(166.45deg, #f2473f85 -14.38%, #ff6b34a1 105%)" : isSelected ? "linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)" : "white",
            color: isFocused || isSelected ? "white" : "black"
        }
    },
    multiValue: (base) => {
        return { ...base, maxWidth: 'max-content', borderRadius: '10px', background: 'linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)', "& div": { color: 'white' } }
    },
    multiValueRemove: (base) => {
        return { ...base, maxWidth: 'max-content', borderRadius: '50%', color: 'green ' }
    },
    multiValueContainer: (base) => {
        return { ...base, maxWidth: 'max-content', color: 'white' }
    },
    valueContainer: (base) => {
        return {
            ...base,

            '& div': {
                maxWidth: 'fit-content'
            }
        }
    }
}