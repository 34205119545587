import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ChevronDown, Circle, MoreVertical, Search } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Label, Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { CustomPagination, customStyles } from '../../utility/datatable-style/dataTableStyle'
import { formatDateNormal, handleApiError, instance, useQuery } from '../../utility/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { GetReviewData } from '../../redux/pagination/review'


const Reviews = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.review)
  // const [timeOutId, setTimeoutId] = useState(null)
  const query = useQuery()
  const [modalData, setModalData] = useState([])
  const [basicModal, setBasicModal] = useState(false)

  const getModalData = async (review_id) => {
    const newInstance = instance()
    try {
      const j = await newInstance.get(`/order-review/${review_id}`)
      setModalData(j.data)
      setBasicModal(true)
    } catch (error) {
      handleApiError(error)
    }
  }

  const columns = [
    {
      name: 'S.NO.',
      selector: (row, rowIndex) => ((((state.pageData?.currentPage - 1) * state.pageData.rowsPerPage) + rowIndex) + 1),
      maxWidth: '6.5rem',
      minWidth: '4rem'
    },
    {
      name: 'Review UID',
      selector: (row) => <div style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{row.order_review_uid
      }</div>,
      minWidth: '12rem',
      maxWidth: '15rem'
    },
    {
      name: 'RESTAURANT NAME',
      selector: (row) => row.cafe_name,
      minWidth: '13rem',
      maxWidth: '18rem'
    },
    {
      name: 'CUSTOMER NAME',
      selector: row => `${row.first_name} ${row.last_name}`,
      minWidth: '12rem',
      maxWidth: '18rem'
    },
    {
      name: 'EXPERIENCE RATING',
      selector: (row) => row.overall_rate || 0,
      minWidth: '9rem',
      maxWidth: '13rem'
    },
    {
      name: 'Review Date',
      selector: (row) => formatDateNormal(row.updated_at),
      width: '150px'
    },
    {
      name: 'STATUS',
      selector: (row) => {
        const style = { padding: '0.2rem 1rem', borderRadius: '20px' }
        if (row.is_featured === 0) {
          return <div style={{ background: '#E7ECF0', color: '#A1B3C9', border: '0.5px solid #A1B3C9', ...style }} >
            Not Fatured
          </div>
        } else if (row.is_featured === 1) {
          return <div style={{ background: 'rgba(255, 48, 48, 0.1)', color: '#FF3030', border: '0.5px solid #FF3030', ...style }} >
            Featured
          </div>
        } else {
          return <div style={{ background: 'rgba(1, 168, 121, 0.1)', color: '#01A879', border: '0.5px solid #01A879', ...style }} >
            {row.status}
          </div>
        }
      },
      width: '150px',
      allowOverflow: true
    },
    {
      name: 'ACTION',
      allowOverflow: true,
      selector: (row) => {
        return (
          <div className='d-flex'>
            <div className='d-flex'>
              <UncontrolledDropdown>
                <DropdownToggle className='pe-1' tag='span'>
                  <MoreVertical size={15} />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag='a' onClick={() => getModalData(row.order_review_id)} className='w-100' >
                    <Circle size={8} /><span className='align-middle ms-50'>View Reviews</span>
                  </DropdownItem>
                  <DropdownItem tag='a' onClick={() => navigate(`edit/${row.order_review_id}`)} className='w-100' >
                    <Circle size={8} /><span className='align-middle ms-50'> Edit Reviews</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        )
      },
      maxWidth: '2.5rem',
      minWidth: '100px',
      // fixed: "left",
      // style: {
      //   position: 'sticky',
      //   right: '0',
      //   background: 'white'
      // },
      allowOverflow: true
    }

  ]

  const columns2 = [
    {
      name: 'UID',
      selector: () => <div style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{modalData.getReviewData[0].order_review_uid || ''}</div>,
      width: '200px'
    },
    {
      name: 'FOOD ITEM',
      selector: (row) => row.order_item_combo_name || row.order_item_name || "",
      minWidth: '200px'
    },
    {
      name: 'PRICE',
      selector: (row) => row.order_item_price,
      width: '100px'
    },
    {
      name: 'RATING',
      selector: (row) => row.item_rating || '',
      width: '100px'
    }
  ]

  const getOrderReviewList = async (row = state.pageData.rowsPerPage, page = state.pageData.currentPage) => {
    dispatch(GetReviewData({
      isLoading: true,
      isRefreshing: true,
      pageNo: page,
      rows: row,
      str: state.searchStr,
      userId: query.get('id')
    }))
  }

  useEffect(() => {
    getOrderReviewList()
  }, [])

  // ** Table
  return (
    <>
      <Modal className='modal-dialog-centered modal-lg ' isOpen={basicModal} toggle={() => setBasicModal(!basicModal)}>
        <ModalHeader toggle={() => setBasicModal(!basicModal)}></ModalHeader>
        <ModalBody>
          <h2 className='m-1 datatable-title-head' >View Review Details</h2>
          <Row style={{ margin: 'auto' }} >

            <Col md='10'>
              <Row>
                <Col md='6' sm='6' className='mb-1'>
                  <Label className='form-label' for='nameMulti'>
                    S. No.
                  </Label>
                  {/* replace review_id with order_uid  */}
                  <Input type='text' placeholder={basicModal ? modalData.getReviewData[0].order_review_uid ? modalData.getReviewData[0].order_review_uid : '' : ''} name='name' id='nameMulti' disabled />
                </Col>
                <Col md='6' sm='6' className='mb-1'>
                  <Label className='form-label' for='nameMulti'>
                    Restaurant Name
                  </Label>
                  <Input type='text' placeholder={basicModal ? modalData.getReviewData[0].cafe_name : ''} name='name' id='nameMulti' disabled />
                </Col>

                <Col md='6' sm='6' className='mb-1'>
                  <Label className='form-label' for='nameMulti'>
                    Customer Name
                  </Label>
                  <Input type='text' placeholder={basicModal ? `${modalData.getReviewData[0].first_name} ${modalData.getReviewData[0].last_name}` : ''} name='name' id='nameMulti' disabled />
                </Col>
                <Col md='6' sm='6' className='mb-1'>
                  <Label className='form-label' for='modal_review_rating'>
                    Experience Rating
                  </Label>
                  <Input type='text' placeholder={basicModal ? modalData.getReviewData[0].overall_rate ? modalData.getReviewData[0].overall_rate : '' : ''} name='overall_rate' id='modal_review_rating' disabled />
                </Col>
                <Col md='6' sm='6' className='mb-1'>
                  <Label className='form-label' for='nameMulti'>
                    Date
                  </Label>
                  <Input type='text' placeholder={basicModal ? formatDateNormal(modalData.getReviewData[0].updated_at) : ''} name='name' id='nameMulti' disabled />
                </Col>
                <Col md='6' sm='6' className='mb-1'>
                  <Label className='form-label' for='nameMulti'>
                    Status
                  </Label>
                  <Input type='text' placeholder={basicModal ? modalData.getReviewData[0].is_featured ? 'Featured' : 'Not Featured' : ''} name='name' id='nameMulti' disabled />
                </Col>
                <Col md='12' sm='12' className='mb-1'>
                  <Label className='form-label' for='review-description'>
                    Review Description
                  </Label>
                  <Input type='textarea' placeholder={basicModal ? modalData.getReviewData[0].review_detail ? modalData.getReviewData[0].review_detail : "" : ''} name='name' id='review-description' disabled />
                </Col>
              </Row>
            </Col>
            <Row>
              <DataTable
                sortIcon={<ChevronDown />}
                responsive={true}
                columns={columns2}     // filter data for column
                data={modalData.getRatingData}       //takes Modaldata
                highlightOnHover
              />
            </Row>
          </Row>
        </ModalBody>
      </Modal>
      <div className='custum-table-fix' style={{ height: 'calc( 100vh - 113px)', display: 'grid', gridTemplateRows: "auto 1fr auto" }}>
        <DataTable
          // title='Live Order'
          pagination
          paginationServer
          progressComponent={<Spinner color="primary"
            style={{
              height: '3rem',
              width: '3rem'
            }} />}
          progressPending={state.isLoading}
          sortIcon={<ChevronDown />}
          subHeader={true}
          customStyles={customStyles()}
          subHeaderComponent={
            <div className='d-flex w-100 ' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
              <div className='d-flex' style={{ gap: '1em', padding: '10px', paddingLeft: '0px', alignItems: 'center' }}>
                <h1 className='datatable-title-head'>List Reviews</h1>
              </div>
              {/* <div className='search-input'>
                <Input type='text' value={state.searchStr} onChange={(e) => {
                  dispatch(changeSearchString({ str: e.target.value }))
                  clearTimeout(timeOutId)
                  const timeoutIdLocal = setTimeout(() => {
                    dispatch(GetReviewData({
                      isLoading: true,
                      isRefreshing: true,
                      pageNo: 1,
                      rows: state.pageData.rowsPerPage,
                      str: e.target.value
                    }))
                  }, 500)
                  setTimeoutId(timeoutIdLocal)
                }} placeholder='Search' />
                <span className='search-icon'><Search /></span>
              </div> */}
            </div>
          }
          paginationDefaultPage={state.pageData.currentPage}
          // paginationComponent={CustomPagination}
          paginationComponent={() => <CustomPagination
            pageData={state.pageData}
            numberOfData={state.list.length}
            handlePerPage={(e) => getOrderReviewList(e.target.value, 1)}
            handlePagination={(page) => getOrderReviewList(state.pageData.rowsPerPage, page.selected + 1)} />}

          responsive={true}
          paginationPerPage={state.pageData.rowsPerPage}
          columns={columns}     // filter data for column
          data={state.list}       //takes data
          highlightOnHover
        />
      </div>
    </>
  )

}

export default Reviews
