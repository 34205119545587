

import { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import { ArrowLeft, ChevronDown, Circle, MoreVertical, Search } from 'react-feather'
import toast from 'react-hot-toast'
// import QR from './../../assets/images/pages/QR.png'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import {
  Row,
  Col,
  Input,
  Dropdown,
  Card,
  CardBody,
  Form,
  Button,
  Label,
  InputGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { handleApiError, instance } from '../../utility/Utils'
import { ReactSelectStyle } from '../../utility/ReactSelectStyle/SelectStyle'


const EditReviews = () => {
  const { id } = useParams() // this id is review id 
  const navigate = useNavigate()
  const [data, setData] = useState({
    order_review_id: '',
    order_review_uid: '',
    cafe_name: "",
    order_list_id: null,
    experience_rating: null,
    user_customer_id: null,
    cafe_list_id: null,
    review_detail: '',
    is_featured: 0,
    first_name: "",
    last_name: ""
  })
  const [saving, setsaving] = useState(false)
  const [tableData, setTableData] = useState([])

  const animatedComponents = makeAnimated()
  const statusOption = [
    { value: 0, label: 'Not Featured' },
    { value: 1, label: 'Featured' }
  ]

  const columns = [
    {
      name: 'UID',
      selector: () => <div style={{ whiteSpace: 'break-spaces', margin: 'auto 3px' }}>{data.order_review_uid || ''}</div>,
      width: '200px'
    },
    {
      name: 'Food Item',
      selector: (row) => row.order_item_combo_name || row.order_item_name || "",
      minWidth: '200px'
    },
    {
      name: 'Price',
      selector: (row) => row.order_item_price,
      width: '100px'
    },
    {
      name: 'Rating',
      selector: (row) => row.item_rating || '',
      width: '100px'
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newInstance = instance()
    try {
      setsaving(true)
      await newInstance.put(`/edit-review/${id}`, {
        experience_rating: data.experience_rating,
        review_detail: data.review_detail,
        is_featured: data.is_featured
      })
      toast.success('Order review edited successfully')
      navigate('/list-reviews')
    } catch (error) {
      handleApiError(error)
    } finally {
      setsaving(false)
    }
  }

  useEffect(() => {
    const getReviewData = async () => {
      const newInstance = instance()
      try {
        const j = await newInstance.get(`/order-review/${id}`)
        setData(j.data.getReviewData[0])
        setTableData(j.data.getRatingData)
      } catch (error) {
        handleApiError(error)
      }
    }
    getReviewData()
  }, [])

  // ** Table
  return (
    <Form onSubmit={handleSubmit}>
      <Col md='9'>
        <h2 className='mb-2 datatable-title-head' ><NavLink style={{ color: '#292D32' }} to="/list-reviews"><ArrowLeft /></NavLink>   Edit Review</h2>
        <Row >

          {/* <Col md='6' sm='6' className='mb-1'>
            <Label className='form-label' for='nameMulti'>
              S. NO.
            </Label>
            <Input type='text' defaultValue={data.order_review_uid || ''} name='name' id='nameMulti' disabled />
          </Col> */}

          <Col md='6' sm='6' className='mb-1'>
            <Label className='form-label' for='cafe_name'>
              RESTAURANT NAME
            </Label>
            <Input type='text' defaultValue={data.cafe_name || ''} name='cafe_name' id='cafe_name' disabled />
          </Col>
          <Col md='6' sm='6' className='mb-1'>
            <Label className='form-label' for='costumer_name'>
              CUSTOMER NAME
            </Label>
            <Input type='text' value={`${data.first_name || ""}  ${data.last_name || ""}`} name='costumer_name' id='costumer_name' disabled />
          </Col>
          <Col md='6' sm='6' className='mb-1'>
            <Label className='form-label' for='rating'>
              EXPERIENCE RATING
            </Label>
            <Input type='text' pattern='[1-5]{1}' title='enter between 1 to 5' value={data.overall_rate || 0}
              onChange={(e) => setData({ ...data, overall_rate: e.target.value })}
              name='rating' id='rating' placeholder='Experience Rating' />
          </Col>
          <Col md='6' sm='6' className='mb-1'>
            <Label className='form-label' for='nameMulti'>
              DATE
            </Label>
            <Input type='text' value={new Date(data.updated_at).toLocaleDateString() || ''} onChange={() => {}} name='name' id='nameMulti' disabled />
          </Col>
          <Col md='6' sm='12' className='mb-1'>
            <Label className='form-label' for='type'>
              STATUS
            </Label>
            <Select
              styles={ReactSelectStyle}
              closeMenuOnSelect
              // placeholder={'Select'}
              value={statusOption.find((e) => e.value === data.is_featured)}
              components={animatedComponents}
              options={statusOption}
              onChange={(e) => {
                setData({ ...data, is_featured: e.value })
              }}
              className='react-select'
            />
          </Col>
          <Col md='12' sm='12' className='mb-1'>
            <Label className='form-label' for='review-description'>
              REVIEW DESCRIPTION
            </Label>
            <Input type='textarea' defaultValue={data.review_detail} name='name'
              onChange={(e) => setData({ ...data, review_detail: e.target.value })}
              id='review-description' placeholder='Review Description' />
          </Col>
        </Row>
        <Row>
          <DataTable
            sortIcon={<ChevronDown />}
            responsive={true}
            columns={columns}     // filter data for column
            data={tableData}       //takes data
            highlightOnHover
          />
        </Row>
        <Col md='12' sm='12'>
          <div className='d-flex m-2'>
            <NavLink to='/list-reviews'><Button outline className='me-1' color='secondary' type='cancel'>
              Cancel
            </Button></NavLink>
            <Button className='me-1 eat-btn' type='input'
              disabled={saving}>
              Save {saving && <Spinner size='sm' />}
            </Button>
          </div>
        </Col>
      </Col>
    </Form>
  )

}

export default EditReviews
